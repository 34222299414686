/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

export default function CustomInput(props: any) {
  const [error, setError] = useState(false);

  const setText = (text: string) => {
    props.onChangeText(text);
  };

  return (
    <div className="w-full my-4">
      <div className="my-2">
        {props.title && (
          <p className="mb-1 md:text-base text-sm">{props.title}</p>
        )}
        <input
          className={`${
            props.style ? props.style : "md:h-12 h-10"
          } rounded-lg md:text-base text-sm bg-white px-2 outline-none border border-solid border-gray-200 w-full`}
          type={props.type ? props.type : "text"}
          name={props.name}
          id="custom"
          value={props.value}
          onChange={(e) => setText(e.target.value)}
          placeholder={props.placeholder}
        />
      </div>
      {error && <div className="text-red-500">{props.errorMessage}</div>}
    </div>
  );
}
