import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ClaimType(props: any) {
  const navigate = useNavigate();
  const { persist, setPersist } = props;
  const [type, setType] = useState<any>();

  function _onProceed() {
    setPersist({ ...persist, type });
    props.setTab(1);
  }
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="lg:w-[40%] md:w-[70%] w-[90%] flex flex-col rounded-lg">
        <div className="">
          <h2 className="font-semibold text-sm">Select the type of claim</h2>
        </div>
        <div className="flex flex-col">
          {["Hospitalized", "Death"].map((v, idx) => (
            <div
              key={idx}
              onClick={() => setType(v)}
              className={`${
                type === v
                  ? "border-2 border-blue-900 border-solid"
                  : "border border-solid border-gray-400"
              } flex items-center p-4 rounded-lg my-2`}
            >
              <p>{v}</p>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <Button
            onClick={_onProceed}
            type="primary"
            size="large"
            color="#0081D4"
            htmlType="submit"
            className="bg-[#0081D4] w-full"
          >
            Continue
          </Button>

          <Button
            size="large"
            onClick={() => navigate(-1)}
            className="w-full my-3"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
