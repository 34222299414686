/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PresViewCard from "../../components/cards/pres-view-card";
import IncomingList from "../../components/cards/incoming-list";
import DataProvider from "../../services";
import PresList from "../../components/cards/pres-list";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";
import Loader from "../../components/cards/loader";
import Empty from "../../components/elements/empty";
import img from "../../assets/images/poweredbymtn.png";

export default function Prescription() {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const results = await DataProvider.getData(currentPage, pageSize);
      setData(results.data.prescriptions);
      setTotalPages(results.data.totalPages);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData]);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="md:p-4 p-2">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 bg-white">
        <PresViewCard />
        <IncomingList loading={loading} data={data} />
      </div>
      <div className="lg:my-5 bg-white flex flex-col justify-center items-center w-full">
        <div className="py-4">
          {loading && (data === null || (data && data.length < 1)) ? (
            <div className="mt-4 flex justify-evenly flex-wrap">
              <Loader num={3} />
              <Loader num={3} />
              <Loader num={3} />
            </div>
          ) : (
            <>
              {data !== null && data.length > 0 ? (
                <PresList loading={loading} data={data} />
              ) : (
                <Empty
                  imageStyle="md:h-44 h-24"
                  titleStyle="md:text-lg text-sm"
                  desStyle="md:text-base"
                  title="Prescriptions"
                  buttons={[
                    {
                      name: "Refresh",
                      event: () => fetchData(),
                    },
                  ]}
                  description="You don’t have any prescription order. Click the upload prescription button above to place an order."
                />
              )}
            </>
          )}
        </div>
        <div className="w-full flex justify-center mb-5">
          <Pagination
            current={currentPage}
            onChange={onChange}
            total={totalPages}
          />
        </div>

        <div className="flex justify-center">
          <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
        </div>
      </div>
    </div>
  );
}
