/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import EmailInput from "../form/email-input";
import PasswordInput from "../form/password-input";
import { Button, Radio, Spin } from "antd";
import { Link } from "react-router-dom";
import CustomInput from "../form/custom-input";
import { LoadingOutlined } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import PhoneNumberInput from "../form/phone-number";

export default function UserRegister(props: any) {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center mt-4">
      <div className="w-[80%] flex flex-col justify-center items-center">
        <h1 className="md:text-3xl text-xl font-bold leading-10">
          Create Your Account
        </h1>
        <p className="text-sm text-gray-500">
          Create an account to get started today.
        </p>
      </div>

      <div className="w-[80%] mt-4">
        <div className="w-full">
          <p className="mb-1 md:text-base text-sm">Create account with:</p>
          <Radio.Group
            // disabled={true}
            onChange={(e) => props.handleSignupMethodChange(e.target.value)}
            value={props.signupMethod}
            className="flex items-center"
          >
            <Radio value="email" id="email" className="">
              Email
            </Radio>
            <Radio value="phone" id="phone" className="">
              Phone Number
            </Radio>
          </Radio.Group>
        </div>

        {props.signupMethod === "email" && (
          <div className="w-full">
            <CustomInput
              title="Full name"
              placeholder="Enter your full name"
              value={props.name}
              onChangeText={props.setName}
            />
            <EmailInput
              email={props.email}
              setEmail={props.setEmail}
              placeholder="Enter your email address."
            />
            <PasswordInput
              required
              password={props.password}
              setPassword={props.setPassword}
              placeholder="*****************"
            />

            <CustomInput
              title="Referral Code"
              placeholder="Enter  referral code"
              value={props.referralCode}
              onChangeText={props.setReferralCode}
            />
            <Button
              type="primary"
              size="large"
              onClick={props.onSubmit}
              className="bg-[#1A94EC] w-full md:text-base text-sm"
            >
              {props.loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ color: Colors.white, fontSize: 24 }}
                      spin
                    />
                  }
                />
              ) : (
                "Sign up"
              )}
            </Button>

            <div className="w-full flex justify-center items-center mt-1">
              <p className="text-[#ADADAD] text-sm">
                Have an account?{" "}
                <Link to="/sign-in" className="text-[#1A94EC]">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        )}

        {props.signupMethod === "phone" && (
          <div className="w-full">
            <CustomInput
              title="Full name"
              placeholder="Enter your full name"
              value={props.name}
              onChangeText={props.setName}
            />
            <PhoneNumberInput
              required
              title="Phone Number"
              value={props.phoneNumber}
              onChangeText={props.setPhoneNumber}
              placeholder="0240005678"
              notes="This number will also be used for your subscription. it is advisable to use a number with MoMo."
            />
            <PasswordInput
              required
              password={props.password}
              setPassword={props.setPassword}
              placeholder="*****************"
            />

            <CustomInput
              title="Referral Code"
              placeholder="Enter  referral code"
              value={props.referralCode}
              onChangeText={props.setReferralCode}
            />
            <Button
              type="primary"
              size="large"
              onClick={props.onSubmit}
              className="bg-[#1A94EC] w-full md:text-base text-sm"
            >
              {props.loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ color: Colors.white, fontSize: 24 }}
                      spin
                    />
                  }
                />
              ) : (
                "Sign up"
              )}
            </Button>

            <div className="w-full flex justify-center items-center mt-1">
              <p className="text-[#ADADAD] text-sm">
                Have an account?{" "}
                <Link to="/sign-in" className="text-[#1A94EC]">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
