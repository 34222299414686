/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
// import { IoChatbubbles } from "react-icons/io5";
// import { MeetingType, useMeetingState } from "../../context/meeting-provider";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";

export default function JoinMeetingButton(props: any) {
  const { appt, status } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  async function onJoinVideoMeeting() {
    messageApi
      .open({
        type: "loading",
        content: "Joining meeting...",
      })
      .then(async () => {
        const { data } = await DataProvider.getDailyMeeting(appt.id);
        if (data) {
          // window.open(data.url);
          // window.location.href = data.url;
          window.location.replace(data.url);
        }
      });
  }

  async function onJoinVoiceMeeting() {
    messageApi
      .open({
        type: "loading",
        content: "Joining meeting...",
      })
      .then(async () => {
        const { data } = await DataProvider.getDailyMeeting(appt.id);
        if (data) {
          // window.location.href = data.url;
          // window.open(data.url, "_ blank");
          window.location.replace(data.url);
        }
      });
  }

  return (
    <div className="flex">
      {appt.appointmentType === "video" && (
        <Button
          disabled={status}
          onClick={onJoinVideoMeeting}
          type="primary"
          size="middle"
          style={{ backgroundColor: status ? "" : "#06509E" }}
          className="bg-[#06509E] flex items-center mb-2 mx-2 sm:w-auto w-full justify-center font-semibold"
        >
          <VideoCameraOutlined />
          Join Video Meeting
        </Button>
      )}

      {appt.appointmentType === "voice" && (
        <Button
          disabled={status}
          onClick={onJoinVoiceMeeting}
          type="primary"
          size="middle"
          style={{ backgroundColor: status ? "" : "#06509E" }}
          className="text-white flex items-center mb-2 mx-2 sm:w-auto w-full justify-center font-semibold"
        >
          <PhoneOutlined />
          Join Voice Meeting
        </Button>
      )}
      {contextHolder}
    </div>
  );
}
