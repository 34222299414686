import { Button, Upload, UploadProps, message, notification } from "antd";
import React, { useState } from "react";
import DataProvider from "../../services";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useAppStore } from "../../store";
import Colors from "../../constants/Colors";

export default function UploadPicture(props: any) {
  const { setInnerTab } = props;
  const { setUser } = useAppStore();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleChange: UploadProps["onChange"] = (info) => {};

  const uploadButton = (
    <button
      className="rounded-full flex flex-col justify-center items-center"
      style={{ border: 0, background: "none" }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const customRequest = async (options: any) => {
    const formData = new FormData();
    formData.append("file", options.file);

    try {
      setLoading(true);
      const result = await DataProvider.cloudUpload(formData);
      setImageUrl(result.data.url);

      // Handle the result and call onSuccess
      options.onSuccess(result.data, options.file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors and call onError
      options.onError(error, options.file);
    }
  };

  async function onSaveImage() {
    setLoadingx(true);
    api["info"]({
      message: "Image Upload",
      description: "Profile image updating......",
    });

    try {
      const payload = { picture: imageUrl };
      const { data } = await DataProvider.updateProfile(payload);
      setUser({ ...data });
      setLoading(false);
      api["info"]({
        message: "Profile",
        description: "Profile image updated successfully",
      });
      setInnerTab(null);
    } catch (error: any) {
      setLoading(false);
      message.error("Failed to update profile. Try again later..");
    }
  }
  return (
    <div className="w-full md:h-[70vh] h-[50vh] flex flex-col justify-start items-center">
      <p className="mt-12 mb-5 w-80 text-center">
        Click on upload button below to upload your profile image.
      </p>
      <div className="flex justify-center">
        <Upload
          name="avatar"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={customRequest}
          //   beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              className="rounded-full"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
      <div className="flex my-4">
        <Button onClick={() => setInnerTab(null)} className="">
          Cancel
        </Button>
        {imageUrl === null ? (
          <Button disabled type="primary" className="mx-4">
            Save Image
          </Button>
        ) : (
          <Button
            onClick={onSaveImage}
            type="primary"
            style={{ background: Colors.primary }}
            className="mx-4"
          >
            {loadingx ? "Saving..." : "Save Image"}
          </Button>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
