import React, { useState } from "react";
import UploadPrescription from "../../components/sections/upload-prescription";
import AdditionalDetails from "../../components/sections/additional-details";
import PresSummary from "../../components/sections/pres-summary";

export default function Ordering() {
  const [tab, setTab] = useState<number>(0);
  const [persist, setPersist] = useState<any>({});

  return (
    <div className="md:p-4 p-2">
      <div className="bg-white rounded-lg w-full h-[85vh] flex justify-center py-2">
        {tab === 0 && (
          <UploadPrescription
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
        {tab === 1 && (
          <AdditionalDetails
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
        {tab === 2 && (
          <PresSummary
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
      </div>
    </div>
  );
}
