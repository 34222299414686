import React from "react";
// import mcare from "../../assets/images/mcarehd.png";
import images from "../../assets/images/poweredbymtn.png";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
// import { FaChevronRight } from "react-icons/fa";

export default function Nav() {
  const navigate = useNavigate();
  return (
    <div className="h-16 w-full flex justify-between items-center md:px-6 px-1">
      <Link to="/" className="">
        {/* <img src={mcare} alt="mcare" className="h-6 w-24" />
        <h5 className="text-black font-semibold text-sm">Healthcare for All</h5> */}

        <div className="flex justify-center pt-3">
          <img src={images} alt="care" className="md:h-20 h-10 rounded-l-lg" />
        </div>
      </Link>
      <div className="flex justify-center items-center">
        <div className="flex">
          <Button
            size="middle"
            onClick={() => navigate("/sign-in")}
            className="mx-4"
          >
            Login
          </Button>
          <Button
            size="middle"
            onClick={() => navigate("/sign-up")}
            className="bg-[#1A94EC] text-white hover:text-[#1A94EC]"
          >
            Register
          </Button>
        </div>
      </div>
    </div>
  );
}
