import pre from "../assets/images/pre.png";
import appt from "../assets/images/appt.png";
import rec from "../assets/images/rec.png";
import diag from "../assets/images/diag.png";

const menu = [
  {
    name: "Home",
    path: "/main",
  },
  {
    name: "Appointments",
    path: "/main/appointments",
  },
  {
    name: "Prescriptions",
    path: "/main/prescriptions",
  },
  {
    name: "Diagnostics",
    path: "/main/diagnostics",
  },
];

const packages = [
  {
    id: 1,
    name: "Silver Package",
    sub: "Telemedicine",
    amount: 10,
    offer: [
      "E-consultation(GPs & Some selected specialist)",
      "E-prescription request (multiple)",
      "Free encrypted medical records",
      "Access to medical records on the app",
      "Discounted prices on medication",
      "Discounted delivery of medication",
      "Freely chat with our Chatbot (Kobikuul) for assistance",
      "24 hours customer service",
      "24 hours customer service",
      "Stress free access to healthcare",
    ],
  },

  {
    id: 2,
    name: "Gold Package ",
    sub: "Telemedicine + Health Insurance",
    amount: 161.66,
    offer: [
      // {
      //   name: "Telemedicine",
      //   list: [],
      // },
      "E-consultation(GPs & Some selected specialist)",
      "E-prescription request (multiple)",
      "Free encrypted medical records",
      "Access to medical records on the app ",
      "Discounted prices on medication",
      "Discounted delivery of medication",
      "Freely chat with our Chatbot (Kobikuul) for assistance ",
      "24 hours customer service",
      "Stress free access to healthcare",
      "Outpatient Care cover of GHS 1500 annually",
      "In patient care cover if GHS 6,500 annually",
      "Over 500 service providers nationwide",
    ],
  },
];

const services = [
  {
    name: " Upload a Prescription",
    path: "/main/prescriptions/ordering",
    icon: pre,
    description:
      "Upload your medical prescriptions and have them delivered to you.",
  },
  {
    name: "Book an Appointment",
    path: "/main/appointments/booking",
    icon: appt,
    description:
      "Conveniently schedule medical appointments and talk to a doctor.",
  },
  {
    name: "Request for Claims",
    path: "/main/claims/request",
    icon: diag,
    description: "Submit and track the status of your life insurance claims.",
  },
  // {
  //   name: "Get a Diagnostic",
  //   path: "/main/diagnostic/request",
  //   icon: diag,
  // },
  {
    name: "View Medical Records",
    path: "/main/profile/5",
    icon: rec,
    description: "Access your medical records, lab results and prescriptions .",
  },
];

const Stepx = [
  {
    title: "Create account",
  },
  {
    title: "Subscription",
  },
  {
    title: "Billing",
  },
];

const unsubscribeReasons = [
  "Cost concerns",
  "Lack of value",
  "Ineffective services",
  "Limited services",
  "Difficulty in accessing services",
  "Change in health status",
  "Unsatisfactory customer service",
  "Privacy concerns",
  "Life circumstances",
  "Preference for traditional healthcare",
  "Other (Please explain below)",
];

export { menu, packages, services, Stepx, unsubscribeReasons };
