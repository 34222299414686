/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckCircleFilled } from "@ant-design/icons";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const list = [
  { title: "Upload a picture of your prescription", id: "1" },
  { title: "Make sure the picture is clear", id: "2" },
  { title: "Doctor’s title and writing must be clear", id: "3" },
  { title: "Date of prescription", id: "4" },
  { title: "Medicine Name and Dosage", id: "5" },
  { title: "Patient’s Name", id: "6" },
];

export default function Guidelines() {
  return (
    <div className="">
      <div className="">Upload Prescription</div>
      <div className="bg-[#E0F4FF] p-2 rounded-lg my-2">
        {list.map((v, idx) => (
          <div key={idx} className="flex justify-start items-center my-1">
            <FaCheckCircle size={15} color="#00A2F8" />
            <div className="text-sm mx-2 my-1">{v.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
