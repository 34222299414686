import React, { ReactNode } from "react";
import { Spin } from "antd";

type Props = {
  children: ReactNode;
  loading: boolean;
  size?: "large" | "small" | "default" | undefined;
  tip?: string;
};

export default function LoadWrapper({ children, loading, size, tip }: Props) {
  return (
    <Spin
      size={size ? size : "large"}
      tip={tip ? tip : "Loading..."}
      className="w-full"
      spinning={loading}
    >
      {children}
    </Spin>
  );
}
