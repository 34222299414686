import React, { useState, createContext, useContext, ReactNode } from "react";
import NotificationsModal from "../components/modals/notifications-modal";
import BookingModal from "../components/modals/booking-modal";
import PrescriptionModal from "../components/modals/prescription-modal";
import LogoutModal from "../components/modals/logout-modal";
import AddressModal from "../components/modals/address-modal";

export const MODAL_TYPES = {
  NOTIFICATION_MODAL: "NOTIFICATION_MODAL",
  BOOKING_MODAL: "BOOKING_MODAL",
  PRESCRIPTION_MODAL: "PRESCRIPTION_MODAL",
  LOGOUT_MODAL: "LOGOUT_MODAL",
  ADDRESS_MODAL: "ADDRESS_MODAL",
};

const MODAL_COMPONENTS = {
  [MODAL_TYPES.NOTIFICATION_MODAL]: NotificationsModal,
  [MODAL_TYPES.BOOKING_MODAL]: BookingModal,
  [MODAL_TYPES.PRESCRIPTION_MODAL]: PrescriptionModal,
  [MODAL_TYPES.LOGOUT_MODAL]: LogoutModal,
  [MODAL_TYPES.ADDRESS_MODAL]: AddressModal,
};

type GlobalModalContextX = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

type Props = {
  children: ReactNode;
};

const initalState: GlobalModalContextX = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal = ({ children }: Props) => {
  const [store, setStore] = useState<any>();
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: any, modalProps: any) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
