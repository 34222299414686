import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CalendarFilled } from "@ant-design/icons";
import Colors from "../../constants/Colors";

export default function Cancel() {
  const navigate = useNavigate();
  const params = useParams();

  const [form] = Form.useForm();

  // const [loading, setLoading] = useState<boolean>(false);

  function _back() {
    navigate(-1);
  }

  function _onProceed() {}
  function onReschedule() {
    if (params.id) navigate(`/main/appointments/reschedule/${params.id}`);
  }

  return (
    <div className="pt-3">
      <div className="bg-white flex justify-center items-center rounded-lg w-full md:min-h-[85vh] h-full py-2 px-2 border">
        <div className="lg:w-[40%] md:w-[70%] w-[90%] flex flex-col rounded-lg">
          <div className="">
            <h2 className="font-semibold text-lg">Cancel Appointment</h2>
          </div>
          <Form form={form} name="basic" layout="vertical" autoComplete="off">
            <Form.Item
              name="cancelReason"
              label="What's the reason for canceling this appointment?"
            >
              <TextArea rows={6} placeholder="" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={onReschedule}
                size="large"
                style={{ backgroundColor: Colors.primaryBlue }}
                className="w-full flex justify-center items-center font-semibold"
              >
                <CalendarFilled /> Will like to Reschedule
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                onClick={_onProceed}
                type="primary"
                size="large"
                style={{ backgroundColor: "red" }}
                htmlType="submit"
                className=" w-full"
              >
                Cancel Appointment
              </Button>
            </Form.Item>

            <Button size="large" onClick={_back} className="w-full">
              Back
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
