const Colors = {
  primary: "#00A2F8",
  secondary: "#3498db",
  primaryBlue: "#0070C0",
  black: "#000000",
  green: "#24A659",
  grey: "#868383",
  white: "#ffffff",
  blue: "#3891DE",
  red: "#e51624",
  lightgreen: "#D7EEE0",
  lightgrey: "#cccccc",
  purple: "#800080",
  deepgray: "#494a49",
  lightg: "#cccccc",
};

export default Colors;
