import { GlobalModal } from "./context/global-modal-context";
import { AxiosInterceptor } from "./core/instance";
import { AppStateProvider } from "./context/app-state-provider";
import { router } from "./routes/routes";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { DataProvider } from "./context/data-provider";

function App() {
  return (
    <AxiosInterceptor>
      <DataProvider>
        <AppStateProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#0081D4",
              },
            }}
          >
            <GlobalModal>
              <RouterProvider router={router} />
            </GlobalModal>
          </ConfigProvider>
        </AppStateProvider>
      </DataProvider>
    </AxiosInterceptor>
  );
}

export default App;
