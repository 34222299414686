/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
// import Guidelines from "../cards/guidelines";
import { Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function HospitalInvoice(props: any) {
  const [done, setDone] = useState<boolean>(false);
  const [data, setData] = useState<any>({});

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button
      style={{ border: 0, background: "none" }}
      className="bg-gray-100 text-gray-400 rounded-full text-4xl flex justify-center items-center"
      type="button"
    >
      <CloudUploadOutlined />
    </button>
  );

  const customRequest = async (options: any) => {
    const formData = new FormData();
    formData.append("file", options.file);
    try {
      const result = await DataProvider.cloudUpload(formData);
      setData({ imageURL: result.data.url });
      props.setHospitalInvoice(result.data.url);
      setDone(true);

      // Handle the result and call onSuccess
      options.onSuccess(result.data, options.file);
    } catch (error) {
      // Handle errors and call onError
      options.onError(error, options.file);
    }
  };
  return (
    <div className="md:w-[30rem] flex flex-col justify-start items-center m-3">
      <div className="flex w-full flex-col justify-start">
        <h4 className="font-semibold">Hospital Invoice</h4>
      </div>
      <div className="border border-solid w-full my-4 border-gray-200 p-2 rounded-xl flex flex-col justify-center items-center">
        <Upload
          customRequest={customRequest}
          listType="picture"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        <div className="text-[#0081D4] text-xs my-2 md:text-base font-medium">
          Tap above to upload
        </div>

        <div className="text-[#667085] text-xs">
          SVG, PNG, JPG or GIF (max. 800x400px)
        </div>
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}
