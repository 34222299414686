import React, { useEffect, useState } from "react";

export default function PhoneNumberInput(props: any) {
  const [error, setError] = useState<boolean>(false);

  const setText = (text: string) => {
    props.onChangeText(text);
  };

  useEffect(() => {
    if (
      props.required &&
      props.value &&
      (props.value.length > 10 || props.value.length < 10)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [props.value, props.required]);

  return (
    <div className="w-full my-4">
      <div className="my-2">
        {props.noTitle ? null : (
          <p className="mb-1 md:text-base text-sm">
            {props.title ? props.title : "Phone Number"}
          </p>
        )}
        <input
          className={`${
            props.style ? props.style : "h-12 text-base"
          } rounded-lg bg-white px-2 outline-none border border-solid border-gray-200 w-full`}
          type={props.type ? props.type : "number"}
          name={props.name}
          id="custom"
          value={props.value}
          onChange={(e) => setText(e.target.value)}
          placeholder={props.placeholder}
        />
      </div>
      {props.notes && (
        <div className="text-gray-500 text-xs">{props.notes}</div>
      )}
      {error && (
        <div className="text-red-500 text-xs">
          Phone number must be 10 figures
        </div>
      )}
    </div>
  );
}
