import { Button, notification } from "antd";
import React, { useState } from "react";
import DataProvider from "../../services";
import PasswordInput from "../form/password-input";
import Firebase from "../../core/firebase";
import { useNavigate } from "react-router-dom";

export default function ResetPassword2(props: any) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // api["success"]({
  //   message: "Success",
  //   description:
  //     "Your password has been update successfully. Kindly sign in to your account.",
  //   btn: <Button type="primary">Sign in</Button>,
  //   onClick: () => navigate("/sign-in"),
  // });

  const handleChangePassword = async () => {
    if (password.length < 6) {
      return api["warning"]({
        message: "Password",
        description: " Password must be 6 or more characters.",
      });
    }
    if (password !== confirmPassword) {
      return api["warning"]({
        message: "Password",
        description: "Password does not match.",
      });
    }
    const payload = {
      email: props.email,
      token: String(props.otp),
      newPassword: password,
    };
    setLoading(true);
    try {
      await DataProvider.resetPassword(payload).then(async (res: any) => {
        await Firebase.loginWithEmail(props.email, password).then(
          async (result) => {
            await Firebase.updateUserPassword(result.user, password).then(
              async (results) => {
                setLoading(false);
                return api["success"]({
                  message: "Success",
                  description:
                    "Your password has been update successfully. Kindly sign in to your account.",
                  btn: <Button type="primary">Sign in</Button>,
                  onClick: () => navigate("/sign-in"),
                });
              }
            );
          }
        );
      });
    } catch (error: any) {
      setLoading(false);
      return api["error"]({
        message: "Failed",
        description: error.message,
      });

      //"Sorry we couldn't update your password. Try again later."
    }
  };

  return (
    <div className="w-full md:h-[70vh] h-[50vh] flex flex-col justify-start items-start">
      {props.noBack ? null : (
        <Button onClick={() => props.setInnerTab(2)} className="my-2">
          Back
        </Button>
      )}
      <div className="w-full my-2 flex flex-col justify-center items-center p-4">
        <div className="md:w-[40%] w-full">
          <h3 className="font-semibold text-lg">Reset Password</h3>
          <p className="py-2">
            Please type in your new password below. Password must be 8 or more
            characters.
          </p>

          <PasswordInput
            title="New Password"
            required
            password={password}
            setPassword={setPassword}
            placeholder="*****************"
          />

          <PasswordInput
            title="Confirm Password"
            required
            password={confirmPassword}
            setPassword={setConfirmPassword}
            placeholder="*****************"
          />

          <Button type="primary" onClick={handleChangePassword}>
            {loading ? "Saving..." : "Change password"}
          </Button>
          {props.noBack ? (
            <Button onClick={() => props.setInnerTab(1)} className="mx-2">
              Go Back
            </Button>
          ) : null}
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
