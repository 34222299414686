/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../services";
import Loader from "./loader";
import PresCard from "./pres-card";
import Empty from "../elements/empty";

export default function PresList(props: any) {
  const { loading, data } = props;

  return (
    <div className="py-4">
      <div className="flex lg:justify-start justify-center flex-wrap">
        {data.map((i: any, idx: any) => (
          <PresCard data={i} key={idx} />
        ))}
      </div>
    </div>
  );
}
