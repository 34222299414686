import React from "react";
// import mtn from "../../assets/images/mtn.png";
// import med from "../../assets/images/logo.png";
import med from "../../assets/images/pow.png";
import { Link } from "react-router-dom";
import mymtn from "../../assets/images/mtnyellow.png";
import ayoba from "../../assets/images/ayoba.png";
import medpharma from "../../assets/images/medpjram.png";

export default function Footer() {
  return (
    <footer className="bg-gray-900 w-full">
      <div className="container px-6 py-8 mx-auto">
        <div className="flex flex-col items-center text-center">
          <img
            className="w-auto h-7"
            // src="https://merakiui.com/images/full-logo.svg"
            src={med}
            alt=""
          />

          <div className="flex flex-wrap justify-center mt-6 -mx-4">
            <Link
              to="/"
              className="mx-4 text-sm text-gray-300 hover:text-blue-400"
            >
              Home
            </Link>

            <Link
              to="/faq"
              className="mx-4 text-sm text-gray-300 hover:text-blue-400"
            >
              FAQs
            </Link>

            <Link
              to="/terms"
              className="mx-4 text-sm text-gray-300 hover:text-blue-400"
            >
              Terms and Conditions
            </Link>
            {/* <Link
              to="/contact-us"
              className="mx-4 text-sm text-gray-300 hover:text-blue-400"
            >
              Contact Us
            </Link> */}
          </div>
        </div>

        <hr className="my-6 border-gray-200 md:my-10 dark:border-gray-700" />

        <div className="flex flex-col items-center sm:flex-row sm:justify-between">
          <p className="text-sm text-gray-500 dark:text-gray-300">
            © 2024 Medpharma. All rights reserved
          </p>

          <div className="flex -mx-2">
            <img src={mymtn} alt="ios" className="w-9 h-5 fill-current mx-2 " />

            <img
              src={ayoba}
              alt="ios"
              className="w-14 h-5 fill-current mx-2 "
            />

            <img
              src={medpharma}
              alt="ios"
              className="w-14 h-5 fill-current mx-2 "
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
