/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useGlobalModalContext } from "../../context/global-modal-context";
import { Modal, Form, Button } from "antd";
import DataProvider from "../../services";
// import { useNavigate } from 'react-router-dom';
// import moment from 'moment';
import Colors from "../../constants/Colors";
import { NotificationFilled } from "@ant-design/icons";
import Loader from "../cards/loader";
import empty from "../../assets/images/empty.png";

export default function NotificationsModal() {
  // const navigate = useNavigate()
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, confirmBtn, action } = modalProps || {};
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleModalToggle = () => {
    hideModal();
  };

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    await DataProvider.getNotifications().then((res) => {
      setLoading(false);
      setData(res.data);
    });
  }, []);

  //   const markNotificationAsRead = async (key: any) => {
  //     key = parseInt(key);
  //     setLoading(true);

  //     await DataProvider.markNotificationAsRead(key).then((res) => {
  //       const newData = [...data];
  //       const prevIndex = data.findIndex((item: any) => item.id === key);
  //       newData.splice(prevIndex, 1);
  //       setData(newData);
  //     });
  //     setLoading(false);
  //   };

  const handleItemAction = (item: any) => {
    if (item.prescription) {
      // navigate(`/prescription/${item.prescription.id}`)
      //   router.push(`/prescription/${item.prescription.id}`);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <div>
      <Modal
        title={title || "Notifications"}
        open={true}
        onCancel={handleModalToggle}
        okButtonProps={{ hidden: true }}
      >
        <div className="p-4">
          {loading && (data === null || (data && data.length < 1)) ? (
            <div className="mt-4 flex flex-col justify-evenly ">
              <Loader num={3} />
            </div>
          ) : (
            <>
              {data !== null && data.length > 0 ? (
                data.map((v, idx) => (
                  <div
                    key={idx}
                    className="w-full p-2 flex justify-start items-center border-b mb-2"
                  >
                    <NotificationFilled
                      color={Colors.grey}
                      className="text-xl"
                    />
                    <div className="mx-4">
                      <h4 className=" leading-4">
                        Your appointment with Dr. Frimpong will in 5 minutes.
                      </h4>
                      <p className="text-gray-400">8 hours</p>
                    </div>
                  </div>
                ))
              ) : (
                <section className="flex flex-col justify-center items-center my-4">
                  <img className="md:h-44 h-24" src={empty} alt="" />
                  <h2
                    className={`md:text-lg text-sm mt-2 font-medium text-center text-gray-800`}
                  >
                    Notifications
                  </h2>
                  <p className={`md:text-base mt-1 text-center text-gray-600`}>
                    You don’t have any notification. Click on the{" "}
                    <strong>Refresh</strong> button to reload.
                  </p>

                  <div className="flex flex-col items-center justify-center mt-4 space-y-1 md:flex-row md:space-y-0 md:space-x-1">
                    <Button className="" onClick={fetchNotifications}>
                      Refresh
                    </Button>
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
