import React, { useState } from "react";
import ClaimType from "../../components/sections/claim-type";
import ClaimDetails from "../../components/sections/claim-details";
import Uploads from "./uploads";

export default function Request() {
  const [tab, setTab] = useState<number>(0);
  const [persist, setPersist] = useState<any>({});
  return (
    <div className="md:p-4 p-2">
      <div className="bg-white rounded-lg w-full min-h-[85vh] flex justify-center items-center py-6">
        {tab === 0 && (
          <ClaimType
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
        {tab === 1 && (
          <Uploads
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
        {tab === 3 && (
          <ClaimDetails
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}
      </div>
    </div>
  );
}
