import React from "react";
import img from "../../assets/images/header1.jpg";
import ios from "../../assets/images/ios.png";
import playstore from "../../assets/images/goo.png";
import ayoba from "../../assets/images/ayoba.png";
import mymtn from "../../assets/images/mtnyellow.png";
import medpharma from "../../assets/images/medpjram.png";
// import med from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

export default function MCareHaeder() {
  return (
    <div
      className="w-full flex flex-col justify-center items-start lg:h-[90vh] h-[50vh] bg-cover bg-[#0000008d] bg-blend-overlay"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="md:ml-10 m-3">
        <h1 className="lg:text-7xl md:text-5xl text-3xl text-white font-bold">
          Your Wellness,
        </h1>
        <h1 className="lg:text-7xl md:text-5xl text-3xl text-white font-bold">
          Your Way:
        </h1>
        <p className="md:text-xl text-lg text-gray-100 mt-4 font-semibold">
          Explore Telemedicine Plus Health and Life Insurance
        </p>

        <div className="flex md:mt-10 mt-5 justify-start items-center">
          <img
            src={mymtn}
            alt="ios"
            className="cursor-pointer ml-4 lg:h-10 sm:h-6 h-4"
          />
          <img
            src={ayoba}
            alt="ios"
            className="cursor-pointer ml-4 lg:h-10 sm:h-6 h-4"
          />
          <img
            src={medpharma}
            alt="ios"
            className="cursor-pointer ml-4 lg:h-12 sm:h-6 h-4"
          />
          <div className="absolute flex lg:mt-28 md:mt-32 lg:ml-10 md:ml-10 mt-20 ml-8 top-1 right-4">
            <Link
              to="https://apps.apple.com/gh/app/medpharma-app/id1477732400"
              target="_blank"
            >
              <img
                src={ios}
                alt="ios"
                className="cursor-pointer lg:h-10 sm:h-6 h-4 w-auto"
              />
            </Link>
            <Link
              to="https://play.google.com/store/search?q=medpharma&c=apps&hl=en"
              // to="http://onelink.to/vhzcxh"
              target="_blank"
            >
              <img
                src={playstore}
                alt="ios"
                className="cursor-pointer ml-4 lg:h-10 sm:h-6 h-4"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
