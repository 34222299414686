import React from "react";
import Loader from "./loader";
import IncomingCard from "./incoming-card";
import delivery from "../../assets/images/delivery.png";

export default function IncomingList(props: any) {
  const { loading, data } = props;
  const orders =
    data !== null
      ? data.length < 1
        ? []
        : data.filter((item: any) => item.status === "processing")
      : [];

  return (
    <div className="pb-4">
      {loading && data === null ? (
        <Loader num={1} />
      ) : orders.length > 0 ? (
        <div>
          <div className="md:mb-4 mb-2 text-sm font-medium">
            Incoming Deliveries
          </div>
          {orders.map((item: any, index: number) => (
            <IncomingCard order={item} key={index} />
          ))}
        </div>
      ) : (
        <div className=" bg-white h-full flex flex-col justify-center items-center w-full">
          <div className="flex flex-col justify-center items-center">
            <img src={delivery} alt="" className="h-32" />
            <h1 className="text-gray-500">No incoming delivery...</h1>
            <p className="text-gray-500">
              Click on the upload button to place an order.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
