/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataProvider from "../../services";
import usr from "../../assets/images/user.png";
import LoaderAnimate from "../../components/elements/loader-animate";
import moment from "moment";
import { CalendarFilled } from "@ant-design/icons";
import { Button, Collapse, CollapseProps, Rate } from "antd";
import Colors from "../../constants/Colors";
import { FaChevronLeft } from "react-icons/fa6";
import Empty from "../../components/elements/empty";
import { checkAppointmentStatus } from "../../utils";
import AppointmentCountdown from "../../components/elements/appointment-countdown";
import DoctorReport from "../../components/sections/doctor-report";
import JoinMeetingButton from "../../components/elements/join-meeting-button";

export default function Details() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [appt, setAppt] = useState<any>(null);
  const [status, setStatus] = useState<boolean>(true);
  const [count, setCount] = useState<boolean>(false);
  const [targetDate, setTargetDate] = useState({
    date: "",
    startTime: "",
    endTime: "",
  });

  const onFetch = async (id: any) => {
    setLoading(true);
    try {
      const results = await DataProvider.getSingleAppointment(id);
      setAppt(results.data);
      const state = checkAppointmentStatus(
        results.data.date,
        results.data.startTime,
        results.data.endTime
      );

      if (state === "passed") {
        setStatus(true);
      } else if (state === "within-time") {
        setStatus(false);
      } else if (state === "waiting") {
        setTargetDate({
          date: results.data.date,
          startTime: results.data.startTime,
          endTime: results.data.endTime,
        });
        setCount(true);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { id } = params;
    onFetch(id);
  }, [params]);

  function onCancel() {
    navigate(`/main/appointments/cancel/${appt.id}`);
  }

  function onReschedule() {
    navigate(`/main/appointments/reschedule/${appt.id}`);
  }

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Doctor Report",
      children: appt && appt.doctor_report && (
        <DoctorReport data={appt.doctor_report} />
      ),
    },
  ];
  return (
    <div className="pt-3">
      {loading ? (
        <div className="bg-white rounded-lg w-full min-h-[85vh] grid grid-cols-1 justify-center py-2 px-2 border border-solid border-gray-200">
          <LoaderAnimate />
        </div>
      ) : !loading && appt ? (
        <div className="bg-white rounded-lg w-full md:min-h-[85vh] h-full py-2 px-2 border border-solid border-gray-200">
          <Button
            type="link"
            onClick={() => navigate(-1)}
            style={{ color: Colors.primaryBlue }}
            className="my-1 flex justify-evenly items-center"
          >
            <FaChevronLeft /> <span className="mx-1">Back</span>
          </Button>

          <div className="h-full w-full grid md:grid-cols-2 grid-cols-1 gap-5 ">
            <div className="">
              {count && (
                <div className="mb-3">
                  <AppointmentCountdown
                    setStatus={setStatus}
                    date={targetDate.date}
                    startTime={targetDate.startTime}
                    endTime={targetDate.endTime}
                    onCountdownEnd={onFetch}
                    params={params.id}
                  />
                </div>
              )}
              <div className="py-1 pb-1 pt-2 border border-solid border-gray-200 rounded-md flex flex-wrap items-center justify-start">
                <JoinMeetingButton appt={appt} status={status} />
                <Button
                  disabled={status}
                  onClick={onReschedule}
                  style={{
                    color: status ? "" : Colors.primaryBlue,
                    borderColor: status ? "" : Colors.primaryBlue,
                  }}
                  className="mx-2 mb-2 sm:w-auto w-full flex justify-center items-center"
                >
                  <CalendarFilled /> Reschedule
                </Button>
                <Button
                  disabled={status}
                  onClick={onCancel}
                  type="primary"
                  style={{ backgroundColor: status ? "" : "red" }}
                  className="mb-2 sm:w-auto w-full mx-2"
                >
                  Cancel Appointment
                </Button>
              </div>
              <div className="p-1 border border-solid border-gray-200 rounded-md flex items-start justify-between my-4">
                <div className="flex flex-col text-xs">
                  <h4 className="text-gray-600 font-semibold mb-1">
                    Appointment Schedule
                  </h4>
                  <p className=" text-gray-400 text-xs mb-1">{`${moment(
                    appt.date,
                    "DD-MM-YYYY"
                  ).format("Do MMMM, YYYY")}`}</p>
                  <div className=" text-white flex items-center h-full w-[20%] ">
                    <span className="text-xs text-gray-400">
                      {appt.startTime}
                    </span>{" "}
                    <span className="text-xs text-gray-400 mx-2">-</span>
                    <span className="text-xs text-gray-400">
                      {appt.endTime}
                    </span>
                  </div>
                </div>
              </div>

              {appt.doctor_report ? (
                <div className=" border-solid border-gray-200">
                  <Collapse expandIconPosition="end" items={items} />
                </div>
              ) : (
                <div className="p-1 border border-solid border-gray-200 text-xs rounded-md flex flex-col items-center justify-center my-4">
                  <div className="w-full flex justify-center items-center">
                    <Empty
                      title="Doctor Report"
                      imageStyle="h-16"
                      titleStyle="text-xs"
                      description="Doctor has not uploaded a report yet."
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="">
              <div className="flex items-center border border-solid border-gray-200 p-1 rounded-md">
                {appt && appt.doctor && appt.doctor.profile_image ? (
                  <div className="h-12 w-12 rounded-full mr-4">
                    <img
                      src={appt.doctor.profile_image}
                      alt=""
                      className="h-full"
                    />
                  </div>
                ) : (
                  <div className="h-12 w-12 rounded-full mr-4">
                    <img src={usr} alt="" className="h-full" />
                  </div>
                )}

                <div>
                  <div className="font-medium text-sm">{appt.doctorName}</div>
                  <div className=" text-gray-400 text-xs">
                    {/* {appt.doctor.institution} */}
                    200hrs of consultations done
                  </div>
                  <div className="text-sm my-0.5">
                    <Rate className="text-sm" allowHalf defaultValue={4.5} />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-start border border-solid border-gray-200 p-1 rounded-md my-4">
                <div className="mr-4">
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Have underlying disease?
                    </h4>
                    <p className="text-gray-400">
                      {appt.hasUnderlyingDisease ? "YES" : "NO"}
                    </p>
                  </div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Underlying diseases
                    </h4>
                    <p className="text-gray-400">
                      {appt.hasUnderlyingDisease
                        ? appt.underlyingDiseaseNotes
                        : "N/A"}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Takes Previous Medication?
                    </h4>
                    <p className="text-gray-400">
                      {appt.takesPreviousMedication ? "YES" : "NO"}
                    </p>
                  </div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">Medication</h4>
                    <p className="text-gray-400 ">
                      {appt.takesPreviousMedication
                        ? appt.upreviousMedicationNotes
                        : "N/A"}
                    </p>
                  </div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">Symptoms</h4>
                    <p className="text-gray-400">
                      {appt.symptoms ? appt.symptoms : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start border border-solid border-gray-200 p-1 rounded-md my-4">
                <h5 className="text-gray-500 font-semibold text-xs">Notes</h5>
                <p className="text-gray-400 text-xs">{appt.complaint}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg w-full min-h-[85vh] flex justify-center items-center py-2 px-2 border">
          <Empty
            imageStyle="md:h-44 h-24"
            titleStyle="md:text-lg text-sm"
            desStyle="md:text-base"
            title="No Internet"
            buttons={[
              {
                name: "Refresh",
                event: () => onFetch(params.id),
              },
            ]}
            description="Check your internet connection and refresh."
          />
        </div>
      )}
    </div>
  );
}
