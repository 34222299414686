import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../../services";
import { useAppStore } from "../../../store";
import { useParams } from "react-router-dom";
import LoadWrapper from "../../../components/elements/load-wrapper";
import Empty from "../../../components/elements/empty";

export default function MedicalRecords(props: any) {
  const { user } = useAppStore();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[] | null>(null);

  const getRecords = useCallback(
    async (params: any) => {
      try {
        setLoading(true);
        if (user) {
          const { data } = await DataProvider.getReports(params);
          setData(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [user]
  );

  useEffect(() => {
    if (params.id === "5" && user) {
      getRecords(user.id);
    }
  }, [params, getRecords, user]);

  return (
    <div className="bg-white w-full min-h-[65vh] rounded-lg border border-solid border-gray-200 py-4 md:px-4 px-2">
      {loading && (data === null || data.length < 1) ? (
        <LoadWrapper loading={loading}>
          <div className="md:h-[85vh] w-full"></div>
        </LoadWrapper>
      ) : data && data.length > 0 ? (
        <div></div>
      ) : (
        <div className="">
          <Empty
            imageStyle="md:h-44 h-24"
            titleStyle="md:text-lg text-sm"
            desStyle="md:text-base"
            title="No Medical Records"
            buttons={[
              {
                name: "Refresh",
                event: () => user && getRecords(user.id),
              },
            ]}
            description="You have no medical records on our platform."
          />
        </div>
      )}
    </div>
  );
}
