
export const firebaseSignupErrorMessage = (error : any): string => {
 let errorMessage = "An error occurred during sign-up, try again.";
    switch (error.code) {
    case "auth/email-already-in-use":
        errorMessage = "The email address is already in use.";
        break;
    case "auth/invalid-email":
        errorMessage = "Invalid email address. Please check the format.";
        break;
    case "auth/weak-password":
        errorMessage =
        "The password is too weak. Please choose a stronger one.";
        break;
    case "auth/network-request-failed":
        errorMessage =
        "Network request failed. Check your internet connection.";
        break;
    //TODO
    // Add more cases for other error codes as needed
    }
    return errorMessage;
}

export const firebaseSigninErrorMessage = (error : any): string => {
 let errorMessage = "An error occurred during sign-in, try again.";
    switch (error.code) {
    case "auth/user-not-found":
        errorMessage = "Account not found. Kindly register!";
        break;
    case "auth/wrong-password":
        errorMessage = "Invalid credentials. Please try again!";
        break;
    case "auth/network-request-failed":
        errorMessage =
        "Network request failed. Check your internet connection.";
        break;
    case "ERR_NETWORK":
        errorMessage =
        "Network request failed. Please try again!";
        break;
    //TODO
    // Add more cases for other error codes as needed
    }
    return errorMessage;
}

export const firebasePhoneNumberSigninError = (error : any): string => {
  let errorMessage = "An error occurred during sign-in, kindly try again.";
  switch (error.code) {
    case "auth/invalid-app-credential":
        errorMessage = "An unexpected server error occurred. Contact customer support.";
    break;
    case "auth/billing-not-enabled":
        errorMessage = "An unexpected error occurred. Contact customer support.";
    break;
    case "auth/internal-error":
        errorMessage = "An unexpected service error occurred. Contact customer support.";
    break;
    case "auth/invalid-phone-number":
        errorMessage = "The provided phone number is invalid. It must be a 10 digit Ghanaian number.";
    break;
    case "auth/code-expired":
        errorMessage = "The provided code is expired.";
    break;
    case "auth/too-many-requests":
        errorMessage = "The number of requests exceeds the maximum allowed. Kindly try again later.";
    break;
    case "auth/phone-number-already-exists":
        errorMessage = "The provided phone number is already in use by an existing user. Each user must have a unique phone number.";
    break;
  }
  return errorMessage;

}

export function convertToInternationalFormat(phoneNumber: string): string {
    return "233" + phoneNumber.slice(1);
}
export function convertToLocalFormat(phoneNumber: string): string {
  // Check if the number starts with "233"
  if (phoneNumber.startsWith("233")) {
    // Remove the "233" prefix
    phoneNumber = phoneNumber.slice(3);
    // Add "0" to the front
    phoneNumber = "0" + phoneNumber;
  }

  if (phoneNumber.startsWith("+233")) {
    // Remove the "233" prefix
    phoneNumber = phoneNumber.slice(4);
    // Add "0" to the front
    phoneNumber = "0" + phoneNumber;
  }
  
  return phoneNumber;
}

export function checkAppointmentStatus(date: string, startTime: string, endTime: string): string {
  const [day, month, year] = date.split("-").map(Number);
  const [startHour, startMinute] = startTime.slice(0, -2).split(":").map(Number);
  const [endHour, endMinute] = endTime.slice(0, -2).split(":").map(Number);
  const isStartPM = startTime.slice(-2).toLowerCase() === "pm";
  const isEndPM = endTime.slice(-2).toLowerCase() === "pm";
  let adjustedStartHour = startHour;
  let adjustedEndHour = endHour;

  if (isStartPM && startHour !== 12) {
    adjustedStartHour += 12;
  } else if (!isStartPM && startHour === 12) {
    adjustedStartHour = 0;
  }

  if (isEndPM && endHour !== 12) {
    adjustedEndHour += 12;
  } else if (!isEndPM && endHour === 12) {
    adjustedEndHour = 0;
  }
  
  const appointmentDateTime = new Date(year, month - 1, day, adjustedStartHour, startMinute);
  const endDateTime = new Date(year, month - 1, day, adjustedEndHour, endMinute);
  const currentDateTime = new Date();
  
  if (currentDateTime.getTime() > endDateTime.getTime()) {
    return "passed";
  } else if (currentDateTime.getTime() > appointmentDateTime.getTime() && currentDateTime.getTime() < endDateTime.getTime()) {
    return "within-time";
  } else {
    return "waiting";
  }
}

export function schedulePassed(date: string, startTime: string, endTime?: string): boolean {
  const [day, month, year] = date.split("-").map(Number);
    const [startHour, startMinute] = startTime.slice(0, -2).split(":").map(Number);
    const isStartPM = startTime.slice(-2).toLowerCase() === "pm";
    let adjustedStartHour = startHour;

    if (isStartPM && startHour !== 12) {
      adjustedStartHour += 12;
    } else if (!isStartPM && startHour === 12) {
      adjustedStartHour = 0;
    }
    
    const appointmentDateTime = new Date(year, month - 1, day, adjustedStartHour, startMinute);
    const currentDateTime = new Date();
      if (currentDateTime.getTime() > appointmentDateTime.getTime()) {
          return true
      }
      return false
}