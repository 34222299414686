import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../../services";
import _ from "lodash";
import Empty from "../../../components/elements/empty";
import AddressCard from "../../../components/cards/address-card";
import LoadWrapper from "../../../components/elements/load-wrapper";
import { useParams } from "react-router-dom";
import { Button, Pagination, PaginationProps, Modal, notification } from "antd";
import AddAddress from "./add-address";
import Colors from "../../../constants/Colors";

export default function Address(props: any) {
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [innerTab, setInnerTab] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [delivery, setDelivery] = useState<any[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [addressToDelete, setAddressToDelete] = useState<any>(null);

  const getAddress = useCallback(async () => {
    setLoading(true);
    await DataProvider.getDelivery().then(async (res) => {
      const data = _.sortBy(res.data, "label");
      setDelivery(data);
      setTotalPages(2);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (params.id === "4" && innerTab === null) {
      getAddress();
    }
  }, [params, innerTab, getAddress]);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteAddress = async (address: any) => {
    setDeleteModalVisible(true);
    setAddressToDelete(address);
  };

  const cancelDeleteAddress = () => {
    setDeleteModalVisible(false);
  };

  const confirmDeleteAddress = async () => {
    if (!addressToDelete) {
      return;
    }
    setIsDeleting(true);
    try {
      await DataProvider.deleteDelivery(addressToDelete.id);
      await getAddress();
      setDeleteModalVisible(false);
    } catch (error) {
      api["error"]({
        message: "Failed",
        description:
          "Sorry, we couldn't delete your address. Please try again.",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="bg-white w-full min-h-[65vh] rounded-lg border border-solid border-gray-200 py-4 md:px-4 px-2">
      {innerTab === null && (
        <div className="">
          {delivery && delivery.length > 0 && (
            <div className="mb-4">
              <Button
                type="primary"
                onClick={() => setInnerTab(0)}
                style={{ backgroundColor: Colors.primary }}
              >
                Add Address
              </Button>
            </div>
          )}

          {loading && (delivery === null || delivery.length < 1) ? (
            <LoadWrapper loading={loading}>
              <div className="md:h-[85vh] w-full"></div>
            </LoadWrapper>
          ) : delivery && delivery.length > 0 ? (
            <div>
              <div className="flex md:justify-start justify-center flex-wrap">
                {delivery.map((item, idx) => (
                  <AddressCard
                    item={item}
                    key={idx}
                    onDelete={() => handleDeleteAddress(item)}
                  />
                ))}
              </div>
              <div className="w-full flex justify-center my-4">
                <Pagination
                  current={currentPage}
                  onChange={onChange}
                  total={totalPages}
                />
              </div>
            </div>
          ) : (
            <div className="">
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="No Address"
                buttons={[
                  {
                    name: "Add Address",
                    type: "primary",
                    event: () => setInnerTab(0),
                  },
                  {
                    name: "Refresh",
                    event: () => getAddress(),
                  },
                ]}
                description="You have not added a delivery address yet."
              />
            </div>
          )}
        </div>
      )}

      {innerTab === 0 && <AddAddress setInnerTab={setInnerTab} />}

      {/* Delete confirmation modal */}
      <Modal
        title="Confirm Deletion"
        visible={deleteModalVisible}
        onOk={confirmDeleteAddress}
        onCancel={cancelDeleteAddress}
        confirmLoading={isDeleting}
        okText={isDeleting ? "Loading..." : "OK"} // Change OK text to Loading when isDeleting is true
      >
        <p>Are you sure you want to delete this address?</p>
      </Modal>
      {contextHolder}
    </div>
  );
}
