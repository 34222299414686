import { Button, Image, Form, Input } from "antd";
import React, { useState } from "react";
// import DataProvider from "../../services";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";

export default function ClaimDetails(props: any) {
  const navigate = useNavigate();
  const { persist } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  async function _onProceedD() {
    try {
      setLoading(true);
      const payload = {
        deathCertificate: persist.deathCertificate,
        policeReport: persist.policeReport,
        affidavits: persist.affidavits,
        nationalId: persist.nationalId,
      };
      await DataProvider.createDeathClaim(payload);
      setLoading(false);
      navigate("/main/claims");
    } catch (error) {
      setLoading(false);
    }
  }
  async function _onProceedH() {
    const values = await form.getFieldsValue();
    try {
      setLoading(true);
      const payload = {
        hospitalInvoice: persist.hospitalInvoice,
        dischargeslip: persist.dischargeslip,
        excuseDuty: persist.dischargeslip,
        nationalId: persist.nationalId,
        notes: values.notes ? values.notes : "N/A",
      };
      await DataProvider.createHospitalizedClaim(payload);
      setLoading(false);
      navigate("/main/claims");
    } catch (error) {
      setLoading(false);
    }
  }

  const goBackHandler = () => {
    props.setTab(2); // Navigate back one step in history
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="lg:w-[30%] md:w-[70%] w-[90%] rounded-lg">
        <div className="w-full flex my-4">
          <h2 className="font-semibold text-sm">Additional Details</h2>
        </div>

        <div className="flex flex-col">
          {persist.type === "Death" && (
            <div className="flex">
              {persist.deathCertificate && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">
                    Death Certificate
                  </h2>
                  <Image
                    src={persist.deathCertificate}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
              {persist.policeReport && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">Police Report</h2>
                  <Image
                    src={persist.deathCertificate}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
              {persist.affidavits && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">Affidavits</h2>
                  <Image
                    src={persist.affidavits}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
              {persist.nationalId && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">National ID</h2>
                  <Image
                    src={persist.nationalId}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
            </div>
          )}

          {persist.type === "Hospitalized" && (
            <div className="flex">
              {persist.hospitalInvoice && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">
                    Hospital Invoice
                  </h2>
                  <Image
                    src={persist.hospitalInvoice}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
              {persist.dischargeslip && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">Discharge Slip</h2>
                  <Image
                    src={persist.dischargeslip}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
              {persist.nationalId && (
                <div className="my-2">
                  <h2 className="font-semibold text-xs mb-1">National ID</h2>
                  <Image
                    src={persist.nationalId}
                    width={70}
                    height={100}
                    alt=""
                    className="h-20 w-16"
                  />
                </div>
              )}
            </div>
          )}

          <Form
            form={form}
            name="basic"
            layout="vertical"
            autoComplete="off"
            className="mt-4"
          >
            <Form.Item name="notes" label="Additional information">
              <Input size="large" placeholder="Additional information..." />
            </Form.Item>
          </Form>
        </div>
        <div className="">
          <Button
            onClick={() =>
              persist.type === "Death" ? _onProceedD() : _onProceedH()
            }
            type="primary"
            size="large"
            color="#0081D4"
            htmlType="submit"
            className="bg-[#0081D4] w-full"
          >
            {loading ? "Saving..." : "Submit"}
          </Button>

          <Button size="large" onClick={goBackHandler} className="w-full my-3">
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
