/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from "react";
import CustomPicker from "../form/custom-picker";
import CustomInput from "../form/custom-input";
import { Button, Checkbox, Form, Select, notification } from "antd";
import DataProvider from "../../services";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import LoadWrapper from "../elements/load-wrapper";
import { LuAsterisk } from "react-icons/lu";
import Empty from "../elements/empty";

// const paymentmodes = ["Insurance", "Staff Account", "Mcare", "Cash"];
const paymentmodes = ["Mcare", "Cash"];
const appTypes = ["video", "voice"];
type NotificationType = "success" | "info" | "warning" | "error";

export default function AdditionalDetailsAppt(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [complaint, setComplaint] = useState<string | null>("");
  const [type, setType] = useState<string | null>(null);
  const [paymentmode, setPaymentmode] = useState<string | null>(null);
  const [symptoms, setSymptoms] = useState<string | null>("");
  const [hasUnderlyingDisease, setHasUnderlyingDisease] = useState<any>(null);
  const [underlyingDiseaseNotes, setUnderlyingDiseaseNotes] = useState<
    string | null
  >(null);
  const [takesPreviousMedication, setTakesPreviousMedication] =
    useState<any>(null);
  const [previousMedicationNotes, setPreviousMedicationNotes] = useState<
    string | null
  >(null);
  const [insuranceData, setInsuranceData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [mcare, setMcare] = useState<any>(null);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const getMcare = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getUserSubscription();
      if (data && data.message === "Not a subscriber!") {
        setLoading(false);
        setMcare(null);
        return;
      } else if (data && data.status !== "active") {
        setLoading(false);
        setMcare(null);
        return;
      }
      setMcare(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMcare(null);
    }
  }, []);

  useEffect(() => {
    getMcare();
  }, [getMcare]);

  const getInsurance = useCallback(async () => {
    try {
      await DataProvider.getInsurance().then(async (res: any) => {
        const data: any = _.sortBy(res.data, "provider");
        setInsuranceData(data);
      });
    } catch (_) {}
  }, []);

  useEffect(() => {
    getInsurance();
  }, [getInsurance]);

  function _onProceed() {
    if (!type || type === null) {
      return openNotificationWithIcon(
        "warning",
        "Appointment Type",
        "Select the type of appointment!"
      );
    }

    // if (!paymentmode || paymentmode === null) {
    //   return openNotificationWithIcon(
    //     "warning",
    //     "Payment method",
    //     "Select a payment method!"
    //   );
    // }

    if (!symptoms) {
      return openNotificationWithIcon(
        "warning",
        "Symptoms",
        "Kindly enter your symptoms!"
      );
    }

    if (hasUnderlyingDisease === null) {
      return openNotificationWithIcon(
        "warning",
        "Underlying Disease",
        "Kindly tick if you have an Underlying Disease!"
      );
    }

    if (takesPreviousMedication === null) {
      return openNotificationWithIcon(
        "warning",
        "Previous Medication",
        "Kindly tick if you are on any Medication!"
      );
    }

    if (paymentmode === "Mcare" && (!mcare || mcare?.status !== "active")) {
      return openNotificationWithIcon(
        "warning",
        "Mcare",
        "You are not subscribed to any package!"
      );
    }

    // if (!complaint) {
    //   return openNotificationWithIcon(
    //     "warning",
    //     "Complaint",
    //     "Kindly type in your complaint!"
    //   );
    // }

    const payload = {
      hasUnderlyingDisease: hasUnderlyingDisease === "Yes" ? true : false,
      underlyingDiseaseNotes: underlyingDiseaseNotes
        ? underlyingDiseaseNotes
        : "N/A",
      takesPreviousMedication: takesPreviousMedication === "Yes" ? true : false,
      previousMedicationNotes: previousMedicationNotes
        ? previousMedicationNotes
        : "N/A",
      symptoms,
      appointmentType: type,
      paymentmode:
        paymentmode === "Insurance"
          ? "insurance"
          : paymentmode === "Staff Account"
          ? "corporate"
          : paymentmode === "Mcare"
          ? "mcare"
          : paymentmode === "Cash"
          ? "in_app"
          : "other",
      complaint,
    };
    props.setPersist({ ...props.persist, ...payload });
    props.setTab(1);
  }

  return (
    <div className="flex justify-center w-full">
      <div className="lg:w-[40%] md:w-[60%] w-[90%]">
        <LoadWrapper
          size="small"
          tip="checking mcare package..."
          loading={loading}
        >
          {!loading && !mcare ? (
            <div className="my-8">
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Not on Mcare"
                buttons={[
                  {
                    name: "Go Back",
                    path: "/main/appointments",
                  },
                  {
                    name: "Refresh",
                    event: () => getMcare(),
                  },
                  {
                    name: "Subscribe",
                    path: "/main/profile/2",
                  },
                ]}
                description="You are not subscribed to any package."
              />
            </div>
          ) : (
            <>
              <h3 className="font-semibold text-lg text-gray-600 my-4">
                Additional information
              </h3>

              {!loading && mcare && <div className="my-3"></div>}
              <Form
                form={form}
                name="basic"
                layout="vertical"
                autoComplete="off"
              >
                <div className="mb-4">
                  <h2 className="leading-9 flex flex-row items-center">
                    How do you want to have your appointment?{""}
                    <LuAsterisk size={8} style={{ color: "red" }} />
                  </h2>
                  <div className="flex">
                    {appTypes.map((v, idx) => (
                      <div className="flex text-sm capitalize">
                        <Checkbox
                          checked={type === v}
                          onChange={(e) => e.target.checked && setType(v)}
                          className="mx-2 text-sm"
                        />
                        {v}
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="mb-4">
            <h2 className="leading-9 flex flex-row items-center">
              How do you want to pay?{" "}
              <LuAsterisk size={8} style={{ color: "red" }} />
            </h2>

            <div className="flex">
              {paymentmodes.map((v, idx) => (
                <div className="flex text-sm">
                  <Checkbox
                    checked={paymentmode === v}
                    onChange={(e) => e.target.checked && setPaymentmode(v)}
                    className="mx-2 text-sm"
                  />
                  {v}
                </div>
              ))}
            </div>
          </div> */}

                {/* {paymentmode === "Insurance" && (
            <Form.Item name="insurance" label="Insurance">
              <Select size="large" showSearch placeholder="Select a person">
                {insuranceData.map((v, index) => (
                  <Option key={index} value={v.id}>
                    <p>{v.town}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )} */}

                {/* {paymentmode === "Mcare" && (
            <>
              {loading ? (
                <LoadWrapper
                  size="small"
                  tip="checking mcare package..."
                  loading={loading}
                >
                  <div className="border-2 bg-[#F0F9FF] rounded-lg md:h-16 h-12 border-[#3498db]"></div>
                </LoadWrapper>
              ) : !loading && !mcare ? (
                <div className="">
                  <p className="text-red-500 font-medium">
                    You are not subscribed to any package.
                  </p>
                </div>
              ) : !loading && mcare ? (
                <div className="border-2 bg-[#F0F9FF] rounded-lg p-2 border-[#3498db]">
                  <h4 className="text-gray-600">{mcare.mcare.description}</h4>
                  <div className="flex">
                    {mcare.mcare.offers.map((rea: any, idx: any) => (
                      <p className="mr-1 text-gray-500 text-sm">{rea.name}</p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )} */}
              </Form>

              <div className="mb-4">
                <CustomInput
                  style="py-2"
                  title={
                    <p className="flex flex-row items-center">
                      Symptoms
                      <span>
                        <LuAsterisk size={8} style={{ color: "red" }} />
                      </span>
                    </p>
                  }
                  placeholder="Headache, cough etc.."
                  value={symptoms}
                  onChangeText={setSymptoms}
                />
                <CustomPicker
                  value={hasUnderlyingDisease}
                  onValueChange={setHasUnderlyingDisease}
                  title={
                    <p className="flex flex-row items-center">
                      Do you have any underlying disease?
                      <span>
                        <LuAsterisk size={8} style={{ color: "red" }} />
                      </span>
                    </p>
                  }
                />
                {hasUnderlyingDisease === "Yes" && (
                  <CustomInput
                    style="py-2"
                    title="Diseases"
                    placeholder="Stroke, Diabetes etc.."
                    value={underlyingDiseaseNotes}
                    onChangeText={setUnderlyingDiseaseNotes}
                  />
                )}
              </div>

              <div className="mb-4">
                <CustomPicker
                  value={takesPreviousMedication}
                  onValueChange={setTakesPreviousMedication}
                  title={
                    <p className="flex flex-row items-center">
                      Are you on any medications?
                      <span>
                        <LuAsterisk size={8} style={{ color: "red" }} />
                      </span>
                    </p>
                  }
                />
                {takesPreviousMedication === "Yes" && (
                  <CustomInput
                    style="py-2"
                    title="Medications"
                    placeholder="Xforge, etc..."
                    value={previousMedicationNotes}
                    onChangeText={setPreviousMedicationNotes}
                  />
                )}
              </div>
              <CustomInput
                style="py-2"
                title="Additional details"
                value={complaint}
                onChangeText={setComplaint}
                placeholder="Additional details, allergies, etc..."
              />

              <div className="my-5">
                <Button
                  onClick={_onProceed}
                  type="primary"
                  size="large"
                  color="#0081D4"
                  htmlType="submit"
                  className="bg-[#0081D4] w-full"
                >
                  Continue
                </Button>

                <Button
                  size="large"
                  onClick={() => navigate(-1)}
                  className="w-full my-3"
                >
                  Back
                </Button>
              </div>
            </>
          )}
        </LoadWrapper>
      </div>
      {contextHolder}
    </div>
  );
}
