import { Checkbox } from "antd";
import React from "react";

export default function CustomPicker(props: any) {
  const { title, value, onValueChange } = props;
  return (
    <div className="my-4">
      <h2 className="mb-1">{title}</h2>
      <div className="flex">
        <div className="flex items-center">
          <Checkbox
            className="h-3 w-3"
            checked={value === "Yes"}
            // value={}
            onChange={() => onValueChange("Yes")}
            style={{ color: value ? "#00A2F8" : undefined }}
          />
          <span className="mx-2">Yes</span>
        </div>
        <div className="flex items-center">
          <Checkbox
            className="h-3 w-3"
            checked={value === "No"}
            // value={}
            onChange={() => onValueChange("No")}
            style={{ color: value ? "#00A2F8" : undefined }}
          />{" "}
          <span className="mx-2">No</span>
        </div>
      </div>
    </div>
  );
}
