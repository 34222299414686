import React from "react";
import { Outlet } from "react-router-dom";
import MainLayout from "../../components/layout/main-layout";
// import DataProvider from "../../services";

export default function Main() {
  // async function checkUpcoming() {
  //   const { data } = await DataProvider.getUpcomingAppointment();
  // }
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}
