import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import mtn from "../../assets/images/mtnyellow.png";
import medpharma from "../../assets/images/md_logo.png";
import Loader from "../cards/loader";
import { packageDetails, useAppStore } from "../../store";
import images from "../../assets/images/poweredbymtn.png";

export default function SelectPlan(props: any) {
  const { packages, loading } = props;
  const { setPlan } = useAppStore();
  const navigate = useNavigate();

  async function onBuy(planx: packageDetails) {
    setPlan(planx);
    navigate(`/plan/billing/${planx.offercode}`);
  }

  return (
    <div className="h-full w-full">
      <div className="mt-4 mb-8 flex justify-center w-full px-4">
        <h1 className="text-2xl font-bold">
          Choose a <span className="text-[#1A94EC]">Plan</span> that works for
          you
        </h1>
      </div>

      {loading && packages === null ? (
        <div className="border border-solid border-gray-200 flex justify-center items-center h-full">
          <Loader num={1} />
        </div>
      ) : packages && packages.length > 0 ? (
        <div className="mb-6 place-content-center w-full flex-col justify-center">
          <div className="w-full my-4 px-4">
            <label>
              <input
                className="peer/showLabel absolute scale-0"
                type="checkbox"
              />
              <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                  <div className="flex justify-between w-full">
                    <h3 className="text-3xl font-bold">
                      {packages[1].description}
                    </h3>
                    <PlusCircleOutlined size={62} />
                  </div>
                  <div className="text-gray-500 font-medium mt-1 flex">
                    <p>GH¢{packages[1].price}/month</p>
                    <p className="flex mx-2">
                      (
                      {packages[1].offers.map((v: any, idx: any) => (
                        <span key={idx}>
                          {v.name}
                          {v.name === "Telemedicine" ? (
                            <span className="mx-2">+</span>
                          ) : null}
                        </span>
                      ))}
                      )
                    </p>
                  </div>
                </div>
                <div className="mb-2 w-full">
                  <Button
                    onClick={() => onBuy(packages[1])}
                    size="large"
                    style={{ backgroundColor: "#1A94EC", color: "white" }}
                    className="w-full text-white"
                  >
                    Buy now
                  </Button>
                  {packages[1].offers.map((itx: any, idx: any) => (
                    <div className="my-4" key={idx}>
                      <h4 className="font-semibold mb-1">{itx.name}</h4>
                      <ul>
                        {itx.list.map((i: any, idxx: any) => (
                          <li key={idxx} className="flex items-center">
                            <GiCheckMark
                              size={14}
                              className="text-[#1A94EC] mr-2"
                            />
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </span>
            </label>
          </div>
          <div className="w-full my-4 px-4">
            <label>
              <input
                className="peer/showLabel absolute scale-0"
                type="checkbox"
              />
              <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                  <div className="flex justify-between w-full">
                    <h3 className="text-3xl font-bold">
                      {packages[0].description}
                    </h3>
                    <PlusCircleOutlined size={62} />
                  </div>
                  <div className="text-gray-500 font-medium mt-1 flex">
                    <p>GH¢{packages[0].price}/month</p>
                    <p className="flex mx-2">
                      (Telemedicine + Health Insurance)
                    </p>
                  </div>
                </div>
                <div className="mb-2 w-full">
                  {packages[0].name === "Gold" ? (
                    <Button
                      onClick={() => onBuy(packages[0])}
                      size="large"
                      style={{ backgroundColor: "#1A94EC", color: "white" }}
                      className="w-full text-white"
                    >
                      Buy now
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onBuy(packages[0])}
                      size="large"
                      style={{ backgroundColor: "#1A94EC", color: "white" }}
                      className="w-full text-white"
                    >
                      Buy now
                    </Button>
                  )}
                  {packages[0].offers.map((itx: any, idx: any) => (
                    <div className="my-4" key={idx}>
                      <h4 className="font-semibold mb-1">{itx.name}</h4>
                      <ul>
                        {itx.list.map((i: any, idxx: any) => (
                          <li key={idxx} className="flex items-center">
                            <GiCheckMark
                              size={14}
                              className="text-[#1A94EC] mr-2"
                            />
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </span>
            </label>
          </div>
          {/* {packages.reverse().map((v: any, idx: any) => (
            <div className="w-full my-4 px-4" key={idx}>
              <label>
                <input
                  className="peer/showLabel absolute scale-0"
                  type="checkbox"
                />
                <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                  <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                    <div className="flex justify-between w-full">
                      <h3 className="text-3xl font-bold">{v.description}</h3>
                      <PlusCircleOutlined size={62} />
                    </div>
                    <div className="text-gray-500 font-medium mt-1">
                      <p>GH¢{v.price}/month</p>
                    </div>
                  </div>
                  <div className="mb-2 w-full">
                    {v.name === "Gold" ? (
                      <Button
                        // onClick={() => onBuy(v)}
                        size="large"
                        style={{
                          backgroundColor: Colors.deepgray,
                          color: "white",
                        }}
                        className="w-full text-white font-semibold"
                      >
                        Coming Soon
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onBuy(v)}
                        size="large"
                        style={{ backgroundColor: "#1A94EC", color: "white" }}
                        className="w-full text-white"
                      >
                        Buy now
                      </Button>
                    )}
                    {v.offers.map((itx: any, idx: any) => (
                      <div className="my-4" key={idx}>
                        <h4 className="font-semibold mb-1">{itx.name}</h4>
                        <ul>
                          {itx.list.map((i: any, idxx: any) => (
                            <li key={idxx} className="flex items-center">
                              <GiCheckMark
                                size={14}
                                className="text-[#1A94EC] mr-2"
                              />
                              {i}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </span>
              </label>
            </div>
          ))} */}
          <div className="w-full flex justify-center items-center relative z-50 bottom-0">
            {/* <img src={mtn} alt="mtn" className="h-7" /> */}
            <img src={mtn} alt="mtn" className="h-7" />
            <img src={medpharma} alt="medpharma" className="h-7 mx-7" />
            <img src={images} alt="care" className="md:h-10 h-10 " />
          </div>
        </div>
      ) : (
        <div className=""></div>
      )}
    </div>
  );
}
