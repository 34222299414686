import React from "react";

export default function IncomingCard(props: any) {
  const { order } = props;
  return (
    <div className="border border-solid border-gray-200 rounded-xl mr-3 p-2">
      <div className="flex justify-center items-center">
        {/* <img className='h-16 w-24'
        src="../../assets/images/del.png" alt=''
      /> */}
        <div className="mx-3">
          <div className="font-medium text-base">
            Incoming: Order #{order.id}
          </div>
          <div className="mr-2 text-gray-400">ETA: {order.status}</div>
          <div className="mt-1"></div>
        </div>
      </div>
    </div>
  );
}
