import React from "react";
import type { Dayjs } from "dayjs";
import { Calendar } from "antd";
import type { CalendarProps } from "antd";

const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {};

export default function CustomCalendar(props: any) {
  return (
    <div className="">
      <Calendar
        fullscreen={false}
        onChange={(date) => props.setSchedule(date)}
        onPanelChange={onPanelChange}
        style={{}}
      />
    </div>
  );
}
