import React from "react";
import img from "../../assets/images/tns.png";
import mtn from "../../assets/images/pow.png";
// import mcare from "../../assets/images/mcare.png";

export default function CareCard() {
  return (
    <div className="bg-[#06509E] md:h-80 h-68 w-full grid grid-cols-2 gap-0 justify-center rounded-lg shadow ">
      <img src={img} alt="care" className="md:h-80 h-44 rounded-l-lg" />
      <div className="text-white pl-3 ">
        <h4 className="md:text-2xl text-xl font-semibold my-2">Care Plans</h4>
        <p className="font-semibold md:text-base text-xs lg:mt-4 ">
          Get access to several healthcare products; medications, tests,
          deliveries, prescriptions, etc..
        </p>
        <div className="lg:mt-14 md:mt-16 mt-2">
          <img src={mtn} alt="care" className="md:h-16 h-8 rounded" />
        </div>
      </div>
    </div>
  );
}
