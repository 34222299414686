import React from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function ServiceCard(props: any) {
  const navigate = useNavigate();
  const { data } = props;
  return (
    <div
      onClick={() => navigate(data.path)}
      className="border border-solid border-gray-200 shadow-sm flex justify-between items-center px-4 bg-white lg:h-64 md:64 h-48 cursor-pointer hover:border-[#06509E] hover:border-1 rounded-lg"
    >
      <div>
        <div className="mb-4">
          <img src={data.icon} alt="" />
        </div>
        <div>
          <h2 className="text-base text-[#0070C0] font-semibold">
            {data.name}
          </h2>
          <p className="py-2">{data.description}</p>
        </div>
      </div>
      <div>
        <FaChevronRight color="#06509E" size={15} />
      </div>
    </div>
  );
}
