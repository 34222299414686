/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Modal, Form, Input, Select, message, Button } from "antd";
import { useGlobalModalContext } from "../../context/global-modal-context";

export default function PrescriptionModal() {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, confirmBtn, action } = modalProps || {};
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <div>
      <Modal
        title={title || "Prescription"}
        open={true}
        onCancel={handleModalToggle}
        okButtonProps={{ hidden: true }}
      ></Modal>
    </div>
  );
}
