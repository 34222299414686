import React from "react";

export default function Record() {
  return (
    <div className="bg-[#1A94EC] lg:h-32 md:h-32 h-20 w-full flex justify-evenly items-center flex-wrap">
      <div className="flex-col  flex justify-center items-center">
        <h1 className="text-white font-bold md:text-4xl text-lg">1M+</h1>
        <p className="text-white md:text-base text-xs">Medication delivered</p>
      </div>
      <div className="flex-col  flex justify-center items-center">
        <h1 className="text-white font-bold md:text-4xl text-lg">65k+</h1>
        <p className="text-white md:text-base text-xs">Patients served</p>
      </div>
      <div className="flex-col  flex justify-center items-center">
        <h1 className="text-white font-bold md:text-4xl text-lg">500+</h1>
        <p className="text-white md:text-base text-xs">Partners</p>
      </div>
      <div className="flex-col  flex justify-center items-center">
        <h1 className="text-white font-bold md:text-4xl text-lg">60k+</h1>
        <p className="text-white md:text-base text-xs">Consultations</p>
      </div>
    </div>
  );
}
