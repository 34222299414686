/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import Colors from "../../constants/Colors";
import TextArea from "antd/es/input/TextArea";
import { Button, Form, Spin, notification } from "antd";
import { unsubscribeReasons } from "../../utils/data";
import DataProvider from "../../services";
import { useNavigate } from "react-router-dom";
import Empty from "../../components/elements/empty";

// type NotificationType = "success" | "info" | "warning" | "error";

export default function Unsubscribe() {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [data, setData] = useState<any>(null);

  const checking = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await DataProvider.getUserSubscription();
      setData(data);
      if (data.message && data.message === "Not a subscriber!") {
        setLoading(false);
        api["info"]({
          message: "Mcare",
          description: "You are not on any Mcare Plan!",
        });
        return;
      }
      if (
        data.status &&
        (data.status === "pending" || data.status === "activation_failed")
      ) {
        setLoading(false);
        return api["info"]({
          message: "Mcare",
          description: "You are not on any Mcare Plan!",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return api["warning"]({
        message: "Warning",
        description:
          "Sorry couldn't verify your mcare package, kindly click on the refresh button to refresh!",
      });
    }
  }, [api]);

  useEffect(() => {
    checking();
  }, [checking]);

  function onChacked(e: React.ChangeEvent<HTMLInputElement>, params: any) {
    const checked = e.target.checked;
    if (checked) {
      setReasons([...reasons, params]);
    } else {
      const arr = reasons.filter((value) => value !== params);
      setReasons(arr);
    }
  }

  async function unsubscribe() {
    const values = await form.getFieldsValue();
    if (
      reasons.length < 1 &&
      (values.unsubscribeReason === undefined ||
        values.unsubscribeReason.length < 1)
    ) {
      return api["warning"]({
        message: "Warning",
        description: "Select one or type the reason for unsubscribing.",
      });
    }
    const payload = {
      unsubscribeReason: values.unsubscribeReason,
      unsubscribeReasonList: reasons,
    };
    try {
      setLoading(true);
      await DataProvider.unsubscribe(payload);
      setLoading(false);
      api["success"]({
        message: "Unsubscribed",
        description: "Successfully unsubscribed.",
      });
      navigate("/main/profile/2");
    } catch (error) {
      setLoading(false);
      return api["error"]({
        message: "Failed",
        description: "Unsubscription failed, try again later.",
      });
    }
  }

  return (
    <div className="md:p-4 p-2">
      <div className="bg-white border border-solid border-gray-200 rounded-lg w-full min-h-[85vh] flex justify-center pt-2 pb-4">
        {(!loading && !data) ||
        (data &&
          (data.status === "pending" ||
            data.status === "activation_failed" ||
            data.status === "cancelled")) ? (
          <div className="lg:w-[40%] md:w-[60%] w-[90%] flex flex-col justify-center items-center">
            <Empty
              imageStyle="md:h-44 h-24"
              titleStyle="md:text-lg text-sm"
              desStyle="md:text-base"
              title="Mcare"
              buttons={[
                {
                  name: "Refresh",
                  event: () => checking(),
                },
              ]}
              description="You are not on any active plan."
            />
          </div>
        ) : (
          <div className="lg:w-[40%] md:w-[60%] w-[90%] flex flex-col justify-start items-center">
            <MdCancel color={Colors.red} size={50} />
            <h3 className="font-semibold text-3xl text-gray-600 mt-2 mb-4">
              Unsubscribe
            </h3>
            <p className="mb-3">
              This action will unsubscribe you from Mcare. Before confirming,
              please select a reason for unsubscribing (optional):
            </p>

            <ul className="w-full">
              {unsubscribeReasons.map((v, idx) => (
                <li className="flex items-center my-1">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    onChange={(e) => onChacked(e, v)}
                    className="mr-2"
                  />
                  <span>{v}</span>
                </li>
              ))}
            </ul>

            <Form
              form={form}
              name="basic"
              className="w-full mt-3 "
              layout="vertical"
              autoComplete="off"
            >
              <Form.Item
                name="unsubscribeReason"
                label="Other reasons for unsubscribing (optional)"
              >
                <TextArea rows={4} placeholder="Type here" />
              </Form.Item>
            </Form>
            <p className="my-2">
              By unsubscribing, you will{" "}
              <strong className="text-[#e51624]">permanently</strong> lose
              access to your Mcare account data including Mcare benefits and
              features.
            </p>

            <p className="mt-6">
              To confirm unsubscribing, click "Unsubscribe" below.
            </p>
            <div className="w-full flex justify-center items-center mt-2">
              <Button
                onClick={unsubscribe}
                type="primary"
                className="mx-2 text-lg w-[48%]"
                style={{ backgroundColor: Colors.red, height: 50 }}
              >
                Unsubscribe
              </Button>
              <Button
                className="mx-2 text-lg w-[48%]"
                style={{
                  color: Colors.red,
                  borderColor: Colors.red,
                  height: 50,
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
        {contextHolder}
      </div>
      <Spin spinning={loading} fullscreen />
    </div>
  );
}
