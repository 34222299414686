import { Button, Checkbox, Image, message } from "antd";
import React, { useState } from "react";
import DataProvider from "../../services";
import { useNavigate } from "react-router-dom";

export default function PresSummary(props: any) {
  const navigate = useNavigate();
  const { persist } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [isRefill, setIsRefill] = useState<boolean>(false);

  async function _onProceed() {
    setLoading(true);
    const payload = {
      image: persist.image,
      notes: persist.notes,
      contact: persist.contact,
      deliveryMode: persist.deliveryMethod.id,
      newInsurance: persist.insurance ? persist.insurance : null,
      newAddress: persist.address ? persist.address.id : null,
      isRefill: isRefill ? 1 : 0,
    };
    try {
      await DataProvider.submitPrescription(payload).then((res: any) => {
        setLoading(false);
        message.success(
          "Prescription order submitted successfully. A representative from our team will reach out to you shortly. Or you can call 0202450446."
        );
        navigate("/main/prescriptions");
      });
    } catch (error) {
      setLoading(false);
      return message.warning(
        "Prescriprion order not submitted, kindly retry again..."
      );
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="lg:w-[30%] md:w-[70%] w-[90%] rounded-lg">
        <div className="w-full justify-center flex my-4">
          <h2 className="font-semibold text-lg">Summary</h2>
        </div>
        <div className="flex flex-col">
          <div className="my-2">
            <h2 className="font-semibold">Prescription</h2>
            <Image
              src={persist.image}
              width={100}
              height={150}
              alt=""
              className="h-20 w-16"
            />
          </div>
          {persist.address && (
            <div className="my-2">
              <h2 className="font-semibold">Delivery Address</h2>
              <p className="text-gray-600 sm:text-base text-sm">
                {persist.address.town}
              </p>
            </div>
          )}

          <div className="my-2">
            <h2 className="font-semibold">Delivery Method</h2>
            <div className="flex">
              <p className="text-gray-600 sm:text-base text-sm">
                {persist.deliveryMethod.title} -{" "}
              </p>
              <p className="text-gray-600 sm:text-base text-sm">
                {persist.deliveryMethod.description}
              </p>
            </div>
          </div>

          <div className="my-2">
            <h2 className="font-semibold">Phone number</h2>
            <p className="text-gray-600 sm:text-base text-sm">
              {persist.contact}
            </p>
          </div>

          <div className="my-2">
            <h2 className="font-semibold">Comment</h2>
            <p className="text-gray-600 sm:text-base text-sm">
              {persist.notes ? persist.notes : "n/a"}
            </p>
          </div>

          <div className="my-2 flex">
            <Checkbox
              onChange={(e) => setIsRefill(e.target.checked)}
              className="mr-1"
            />{" "}
            <span className="sm:text-base text-sm">
              I would like this prescription to be refilled.
            </span>
          </div>
        </div>
        <div className="my-3">
          <Button
            onClick={_onProceed}
            type="primary"
            size="large"
            color="#0081D4"
            htmlType="submit"
            className="bg-[#0081D4] w-full"
          >
            {loading ? "Loading..." : " Confirm"}
          </Button>

          <Button
            size="large"
            onClick={() => props.setTab(1)}
            className="w-full my-4"
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}
