// import React, { ReactNode, useCallback, useEffect, useState } from "react";
import CareCard from "../../components/cards/care-card";
// import Walkthrough from "../../components/cards/walkthrough";
import ServiceList from "../../components/sections/service-list";
// import DataProvider from "../../services";
// import _ from "lodash";
import SubscriptionCard from "../../components/cards/subscription-card";
// import { BookFilled, HomeFilled } from "@ant-design/icons";
import img from "../../assets/images/poweredbymtn.png";
// import { useLocation, useParams } from "react-router-dom";

// const walkthroughData: {
//   id: string;
//   icon: ReactNode;
//   type: string;
//   name: string;
//   route: string;
// }[] = [
//   {
//     id: "1",
//     icon: <HomeFilled />,
//     type: "Entypo",
//     name: "Add Address",
//     route: "/main/profile/4",
//   },
//   {
//     id: "2",
//     icon: <BookFilled />,
//     type: "FontAwesome",
//     name: "Add Insurance",
//     route: "/main/profile/3",
//   },
//   // {
//   //   id: "2",
//   //   icon: "file-alt",
//   //   type: "FontAwesome",
//   //   name: "Profile Image",
//   //   route: "/main/profile/1",
//   // },
// ];

export default function Overview() {
  // const params = useParams();
  // const location = useLocation();
  // console.log(location);
  // console.log(params);
  // const searchParams = new URLSearchParams(location.search);
  // const econ = searchParams.get("econ");
  // console.log(econ);
  // const [walkthrough, setWalkthrough] = useState<typeof walkthroughData>([]);
  // const [notification, setNotification] = useState<number>(0);

  // const checkWalkthrough = useCallback(async () => {
  //   const profile = await DataProvider.getProfile();
  //   const user = profile.data;

  //   let wtData = [...walkthrough];

  //   const local: { key: string; index: number }[] = [
  //     { key: "insurance", index: 1 },
  //     {
  //       key: "address",
  //       index: 0,
  //     },
  //   ];

  //   local.forEach((v) => {
  //     // Check if the item already exists in wtData
  //     const isItemExists = wtData.some(
  //       (item) => item.id === walkthroughData[v.index].id
  //     );

  //     if (user[v.key].length === 0 && !isItemExists) {
  //       wtData.push(walkthroughData[v.index]);
  //     }
  //   });
  //   setWalkthrough(_.uniq(wtData));
  // }, [walkthrough]);

  // const fetchNotifications = useCallback(async () => {
  //   await DataProvider.getNotifications().then((res) => {
  //     const length = res.data.length;

  //     if (length > 0) {
  //       setNotification(length);
  //     } else {
  //       setNotification(0);
  //     }
  //   });
  // }, [props.navigation]);

  // const fetchData = useCallback(async () => {
  //   // setLoading(true);
  //   checkWalkthrough();
  //   // fetchNotifications();
  //   // setLoading(false);
  // }, [checkWalkthrough]);

  // useEffect(() => {
  //   checkWalkthrough();
  // }, [checkWalkthrough]);

  return (
    <div className="p-4">
      <div className="flex ">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
          <CareCard />
          <SubscriptionCard />
          {/* {walkthrough.length > 0 && <Walkthrough data={walkthrough} />} */}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 my-5">
        <ServiceList />
      </div>
      <div className="flex justify-center">
        <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
      </div>
    </div>
  );
}
