import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CalendarFilled,
  HomeFilled,
  UserSwitchOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  LogoutOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Layout, Menu } from "antd";
import images from "../../assets/images/poweredbymtn.png";
import img from "../../assets/images/pattern_bg.png";
import image from "../../assets/images/user.png";
import { useAppStore } from "../../store";
// import { SiCkeditor4 } from "react-icons/si";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type Props = {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Sidebar({ collapsed, setCollapsed }: Props) {
  const navigate = useNavigate();
  const { Sider } = Layout;
  const { user, logOut } = useAppStore();
  const userImage = user && user.picture ? user.picture : image;
  const items: MenuItem[] = [
    getItem(<NavLink to="/main">Home</NavLink>, "1", <HomeFilled />),
    getItem(
      <NavLink to="/main/appointments">Appointments</NavLink>,
      "2",
      <CalendarFilled />
    ),
    getItem(
      <NavLink to="/main/prescriptions">Prescriptions</NavLink>,
      "3",
      <FileDoneOutlined />
    ),
    // getItem(
    //   <NavLink to="/main/infomedic">Check your symptoms</NavLink>,
    //   "7",
    //   <SiCkeditor4 />
    // ),
    getItem(
      <NavLink to="/main/claims">Claims</NavLink>,
      "4",
      <FileTextOutlined />
    ),
    getItem(
      <NavLink to="/main/profile/2">Mcare</NavLink>,
      "6",
      <SolutionOutlined size={17} />
    ),
    getItem(
      <NavLink to="/main/unsubscribe">Unsubscribe</NavLink>,
      "5",
      <UserSwitchOutlined size={17} />
    ),
  ];

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  async function logout() {
    logOut();
    navigate("/sign-in");
  }

  return (
    <div
      className="col-span-1 lg:flex lg:flex-col justify-between items-center  hidden shadow-lg relative top-0 left-0 overflow-y-auto shadow-gray-100 h-full pt-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div>
        <div
          className={`h-12 flex px-5 items-center mb-4 ${
            collapsed ? " justify-center" : " justify-between"
          }`}
        >
          <div className={`${collapsed ? "hidden" : "flex justify-center"}`}>
            <img
              src={images}
              alt="care"
              className="md:h-20 h-10 rounded-l-lg"
            />
          </div>
          <div className=" cursor-pointer text-2xl" onClick={toggleCollapsed}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </div>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width="220px"
          theme="light"
          className="h-full flex flex-col justify-between"
          style={{
            overflow: "auto",
            height: "100%",
            position: "sticky",
            top: 0,
            left: 0,
            background: " transparent",
            scrollbarGutter: "auto",
            scrollbarWidth: "thin",
            // display: "flex"
          }}
        >
          <Menu
            defaultSelectedKeys={["1"]}
            // defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
            // inlineCollapsed={collapsed}
            items={items}
            className="menu bg-transparent"
            expandIcon={collapsed ? "" : <CaretDownOutlined />}
            overflowedIndicator={<CaretDownOutlined />}
          />
        </Sider>
      </div>
      <div className="mb-12 w-full">
        {/* <div
          onClick={logout}
          className={`${
            collapsed ? "justify-center" : ""
          } w-full flex items-center py-3 px-2 mb-2 cursor-pointer hover:bg-[#e6f8ff] hover:text-[#0081D4] rounded`}
        >
          <LogoutOutlined size={20} />{" "}
          <span className={`${collapsed ? "hidden" : "ml-2"}`}>Logout</span>
        </div> */}
        <Button
          onClick={logout}
          type="link"
          size="large"
          className={`${
            collapsed ? "justify-center" : "pl-6 justify-start"
          } w-full flex items-center mb-2 text-black`}
        >
          <LogoutOutlined size={20} />
          {collapsed ? null : <span className="text-sm ml-4">Logout</span>}
        </Button>
        <div
          onClick={() => navigate("/main/profile/1")}
          className={`${
            collapsed ? "justify-center items-center" : ""
          } w-full flex bg-white py-4 px-2 cursor-pointer`}
        >
          <div className="h-10 w-10 border border-solid border-gray-200 rounded-full flex justify-center items-center">
            <img src={userImage} alt="" className="h-full rounded-full" />
          </div>
          <div
            className={`${
              collapsed
                ? "hidden"
                : "flex flex-col justify-start items-start ml-2"
            }`}
          >
            <h3 className="text-sm font-semibold">{user && user.fullname}</h3>
            <p className="text-xs text-gray-500">{user && user.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
