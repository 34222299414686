/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Button, Collapse, Select } from "antd";
import Nav from "../../components/layout/nav";
import img from "../../assets/images/nr1.jpg";
import { RightOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/sections/footer";

type ExpandIconPosition = "start" | "end";
export default function FAQ() {
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("start");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {};

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <div className="text-gray-600 font-medium">What is mCare?</div>,
      children: (
        <div className="text-gray-800">
          mCare is a subscription-based eHealth service launched by MTN in
          partnership with MedPharma and Phoenix Health Insurance, to provide
          convenient access to teleconsultation services, medication
          administration and delivery and health insurance.
        </div>
      ),
      //   extra: genExtra(),
    },
    {
      key: "2",
      label: (
        <div className="text-gray-600 font-medium">How can I access mCare?</div>
      ),
      children: (
        <div className="text-gray-800">
          You can access mCare through the Ayoba App, myMTN app, Medpharma app,
          or the Medpharma chatbot.
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="text-gray-600 font-medium">
          Is there a waiting period before I can use the health insurance
          benefits?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          There is a one-week waiting period for processing client information
          for Gold customers and their health insurance benefits. However, both
          Gold and Silver customers can immediately benefit from e-consultation
          services discounted medication, medication delivery and also health
          content.
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="text-gray-600 font-medium">
          How do I access medication delivery services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medication delivery services can only be accessed by placing an order
          on the mCare app.
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any limitations on the number of e-consultation I can have?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          The number of teleconsultations are dependent on the doctor and
          patient and are subject to fair usage policies to ensure accessibility
          and convenience for all; refer to the terms and conditions for
          details.
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div className="text-gray-600 font-medium">
          Can I purchase over-the-counter medications through mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, over-the-counter medications are available for purchase through
          mCare.
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div className="text-gray-600 font-medium">
          How are medications delivered?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medications are delivered through our designated in-house courier
          service to the address provided during the ordering process on mCare.
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any network requirements to access mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Accessing mCare requires only a compatible device, such as an Android
          smartphone, iPhone, or laptop, the app is zero-rated and may not
          require an internet connection to enjoy mCare benefits.
        </div>
      ),
    },
    {
      key: "9",
      label: (
        <div className="text-gray-600 font-medium">
          Can I use mCare if I'm not an MTN subscriber?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          mCare is accessible to the general public, including non-subscribers.
          However, all users are required to make subscription payments through
          the MTN MoMo platform.
        </div>
      ),
    },
    {
      key: "10",
      label: (
        <div className="text-gray-600 font-medium">
          Can I access mCare from outside Ghana?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          No, mCare is currently only available for residents within Ghana.
        </div>
      ),
    },
    {
      key: "11",
      label: (
        <div className="text-gray-600 font-medium">
          Is there a trial period for mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          At this time, mCare does not provide a trial period. However, we offer
          Gold customers the flexibility of bit-by-bit weekly payment options if
          they encounter difficulty making full subscription payments. Feel free
          to contact our customer support team for more information on these
          options.
        </div>
      ),
    },
    {
      key: "12",
      label: (
        <div className="text-gray-600 font-medium">
          Can I use mCare for pre-existing medical conditions?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Coverage for pre-existing medical conditions may be subject to certain
          limitations and exclusions. Feel free to contact our customer support
          team or look up our terms and conditions for more information.
        </div>
      ),
    },
    {
      key: "13",
      label: (
        <div className="text-gray-600 font-medium">
          How do I schedule a teleconsultation appointment?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To schedule a teleconsultation appointment, simply navigate to the
          mCare app and use the appointment scheduling feature on the homepage.
          It's quick and convenient, allowing you to book your teleconsultation
          with ease.
        </div>
      ),
    },
    {
      key: "14",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any age restrictions for teleconsultation services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Our services cater to patients from 0 to 59 years. For patients under
          18 years old, parental or guardian consent is required for
          e-consultations. Furthermore, parents or guardians must be present at
          all times during their child's e-consultations to ensure appropriate
          communication.
        </div>
      ),
    },
    {
      key: "15",
      label: (
        <div className="text-gray-600 font-medium">
          Can I request prescriptions through teleconsultation?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, prescriptions can be requested and provided during
          teleconsultation appointments, subject to the healthcare
          professional's assessment.
        </div>
      ),
    },
    {
      key: "16",
      label: (
        <div className="text-gray-600 font-medium">
          What happens if I need a physical examination?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          If a physical examination or tests are deemed essential following a
          teleconsultation, our healthcare experts will offer detailed guidance
          on the recommended steps, which may involve arranging an appointment
          with a physical healthcare provider. Moreover, Gold customers can
          benefit from their health insurance coverage, ensuring smooth access
          to the necessary consultations and tests.
        </div>
      ),
    },
    {
      key: "17",
      label: (
        <div className="text-gray-600 font-medium">
          Can I use mCare while travelling within Ghana?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, mCare services are accessible anywhere within Ghana.
        </div>
      ),
    },
    {
      key: "18",
      label: (
        <div className="text-gray-600 font-medium">
          How is the quality of teleconsultation services ensured?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          We maintain the quality of our teleconsultation services by engaging
          licensed and qualified healthcare professionals who strictly adhere to
          industry standards and regulations. These professionals undergo
          comprehensive training and are subject to regulation by the Medical
          and Dental Council of Ghana, ensuring the highest standards of service
          delivery and adherence to ethical practices.
        </div>
      ),
    },
    {
      key: "19",
      label: (
        <div className="text-gray-600 font-medium">
          How do I know if a healthcare provider is part of the mCare network?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To find an mCare network provider, use the provider’s list on your
          mcare app. We are adding more providers and updating regularly for
          your benefit.
        </div>
      ),
    },
    {
      key: "20",
      label: (
        <div className="text-gray-600 font-medium">
          Is there a waiting period for teleconsultation appointments?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          While booking teleconsultation appointments, you will have access to
          select your preferred time and date, this is also subject to the
          availability of the healthcare professional.
        </div>
      ),
    },
    {
      key: "21",
      label: (
        <div className="text-gray-600 font-medium">
          Can I access past teleconsultation records or medication history?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can access your past teleconsultation transcription records
          and medication history through the platform's record-keeping system.
        </div>
      ),
    },
    {
      key: "22",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any restrictions on the types of conditions covered under
          teleconsultation services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Teleconsultation services cover a wide range of non-emergency medical
          conditions; however, certain specialized conditions may require
          in-person consultations.
        </div>
      ),
    },
    // {
    //   key: "23",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Is there a dedicated service hub for walk-in client registration and
    //       enrollment?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, we have agents at vantage points where clients can walk in for
    //       registration and enrollment on mCare
    //     </div>
    //   ),
    // },
    {
      key: "24",
      label: (
        <div className="text-gray-600 font-medium">
          Is it necessary for clients to have appointments with a doctor before
          refilling medication?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          No, clients do not need to have appointments with a doctor before
          refilling medication unless there have been changes in the patient's
          condition.
        </div>
      ),
    },
    {
      key: "25",
      label: (
        <div className="text-gray-600 font-medium">
          What services are included in mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          mCare offers teleconsultation services, pharmaceutical products,
          medicines, hospitalisation, ambulance, medical emergency services, and
          health insurance options.
        </div>
      ),
    },
    {
      key: "26",
      label: (
        <div className="text-gray-600 font-medium">
          How much does mCare cost?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          The Silver subscription is GHS 10/month, providing access to
          teleconsultation and discounted drugs. The Gold subscription is GHS
          161.66/month, offering an additional health insurance coverage.
        </div>
      ),
    },
    // {
    //   key: "27",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       What are the payment options for mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Payments for mHealthCare can be made on various intervals: daily,
    //       monthly, quarterly, half-yearly, or yearly. Subscribers opting for
    //       longer payment intervals can enjoy discounts as follows: 3-month
    //       subscription pre-payment: 2.5% discount. 6-month subscription
    //       pre-payment: 5% discount. 9-month subscription pre-payment: 7.5%
    //       discount. 12-month subscription pre-payment: 10% discount.
    //     </div>
    //   ),
    // },
    {
      key: "28",
      label: (
        <div className="text-gray-600 font-medium">
          Is my subscription fee refundable?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Subscriptions are non-refundable, but you can cancel your subscription
          at any time.
        </div>
      ),
    },
    {
      key: "29",
      label: (
        <div className="text-gray-600 font-medium">
          How do I renew my subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To renew your subscription, simply navigate to the side panel on your
          home screen and click on the 'Renew Plan' button. Then, follow the
          payment prompts to complete your renewal on the payment subscription
          page.
        </div>
      ),
    },
    {
      key: "30",
      label: (
        <div className="text-gray-600 font-medium">
          Can I change my subscription plan?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can upgrade or downgrade your subscription plan at any time.
        </div>
      ),
    },
    {
      key: "31",
      label: (
        <div className="text-gray-600 font-medium">
          What happens if I miss a subscription payment?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Failure to pay will result in suspension or termination of services.
        </div>
      ),
    },
    // {
    //   key: "32",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Are there any penalties for early cancellation of my subscription?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       No, there is no penalty for cancelling a health insurance policy.
    //       However, refunds will not be provided on subscription cancellations.
    //     </div>
    //   ),
    // },
    {
      key: "33",
      label: (
        <div className="text-gray-600 font-medium">
          How much prior notice is given for subscription expiry for weekly
          subscribers?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Subscribers receive a notification 24 hours before their subscription
          expiry. If the subscription is not renewed within 24 hours after the
          deadline, the client's status will be considered inactive, resulting
          in the loss of benefits. Check the terms and conditions for more
          information.
        </div>
      ),
    },
    {
      key: "34",
      label: (
        <div className="text-gray-600 font-medium">
          When does termination occur for inactive subscriptions?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Subscriptions that remain inactive for one month will be terminated.
          Continuous notifications will be sent to clients once a week, ensuring
          they have an opportunity to renew their subscriptions and maintain
          access to benefits.
        </div>
      ),
    },
    {
      key: "35",
      label: (
        <div className="text-gray-600 font-medium">
          Will charges apply when clients make premium payments?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Premium payments come at no additional charges to the customer.
        </div>
      ),
    },
    {
      key: "36",
      label: (
        <div className="text-gray-600 font-medium">
          How often are patients' covers updated after premium payment?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Patient covers are constantly updated after premium payment, and
          clients are notified via message and email to confirm the update, they
          also get an update on the health insurance coverage they enjoy within
          the period via email and through the mCare platform.
        </div>
      ),
    },
    {
      key: "37",
      label: (
        <div className="text-gray-600 font-medium">
          What are the benefits of the Silver subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          For GHS 10 every month, the Silver subscription offers the following
          benefits: E-consultation. E-prescription. Death and accident (death)
          cover of GHS 2,000. Hospital cover of GHS 60 daily for a maximum of 30
          days. Discounted drugs. Discounted delivery of medication. 24/7
          customer service. Free encrypted medical records. Access to medical
          records on the app. Chat with Kobikuul. Free and reliable wellness and
          disease prevention content.
        </div>
      ),
    },
    {
      key: "38",
      label: (
        <div className="text-gray-600 font-medium">
          What benefits come with the Gold subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Health Insurance cover of GHC 26,000 which includes the following:
          E-consultation. E-prescription Discounted drugs. Discounted delivery
          of medication. Outpatient GHC 6,000 (GHC 250 per hospital visit,
          maximum 2 visits per month and includes: Medications - Covered within
          OPD limit per visit Refill of Chronic Medications: GH¢ 100 covered per
          month Diagnostic Services: Covered within OPD limit per visit Dental
          Cover - GH¢ 100 annual cover Lens & Frame - GH¢ 100 annual cover
          Ambulatory Services: GH¢ 100 covered within OPD limit per visit; for
          emergency cases ONLY. In-patient cover GHC 20,000 annual (GHC 1,250
          per month, activates on the 3rd day of admission with GHC 70.00 cap
          for Ward Accommodation per day). Surgeries – GHC 1,500 per year
          Maternity cover (Antenatal, Post-Natal, Delivery) – GHC 2,000 Major
          disease conditions (Cancer, Stroke, etc) 24/7 customer service. Free
          encrypted medical records. Access to medical records on the app Chat
          with Kobikuul Free and reliable wellness and disease prevention
          content. Bit-by-bit subscription payments
        </div>
      ),
    },
    // {
    //   key: "39",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       What is the coverage of the health insurance included in the Gold
    //       subscription?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       The health insurance covers outpatient and in-patient services,
    //       including consultations, procedures, medications, surgeries, emergency
    //       (road traffic accidents), ambulance services, and much more.
    //     </div>
    //   ),
    // },
    {
      key: "40",
      label: (
        <div className="text-gray-600 font-medium">
          Can I break down my Gold Package subscription of GHC 161.67 and pay a
          fraction of it on a weekly basis?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can break down your Gold Package subscription of GHC 161.67
          and pay on a weekly basis. Here's how it breaks down: From the 1st to
          13th Week: Weekly Payment: GHC 40.42 Services included: E-consultation
          E-prescription GHC 200gh cover for medication and consultation (no
          diagnostics) No in-patient benefits Discounted medication Discounted
          drugs delivery From the 14th Week: Weekly Payment: GHC 40.42 Services
          included: E-consultation E-prescription Out-Patient benefits: GHC 250
          per hospital visit, maximum 2 visits per month Medications covered
          within OPD limit per visit Refill of Chronic Medications: GH¢ 100
          covered per month Diagnostic Services covered within OPD limit per
          visit Dental Cover: GH¢ 100 annual cover Lens & Frame: GH¢ 100 annual
          cover In-patient benefits: GHC 1,250 per month, activates on the 3rd
          day of admission with GHC 70.00 cap for Ward Accommodation per day
          Ambulatory Services: GH¢ 100 covered within OPD limit per visit; for
          emergency cases ONLY. So, you can pay GHC 40.42 per week for the first
          13 weeks, and then continue paying the same amount for subsequent
          weeks to enjoy the expanded benefits starting from the 14th week.
        </div>
      ),
    },
    {
      key: "41",
      label: (
        <div className="text-gray-600 font-medium">
          How do I subscribe to mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You can subscribe to mCare through a simple registration process on
          the Ayoba app, myMTN app or through the Medpharma app and the
          Medpharma chatbot.
        </div>
      ),
    },
    {
      key: "42",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any age restrictions for subscribing to mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Subscribers must be within the age range of 0 to 59 years.
        </div>
      ),
    },
    {
      key: "43",
      label: (
        <div className="text-gray-600 font-medium">
          Can I add family members to my mCare subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Currently, we do not offer family or group plans for our mCare
          subscription. Each user would need to have their own separate
          subscription to access our services. If you have any further questions
          or need assistance, please feel free to contact our dedicated customer
          support team for more information.
        </div>
      ),
    },
    {
      key: "44",
      label: (
        <div className="text-gray-600 font-medium">
          How do I report issues or complaints with mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You can report issues or lodge complaints through the designated
          customer care channel at support@medpharma.care, the mCare Customer
          Service chat, or call 055-756-0448.
        </div>
      ),
    },
    {
      key: "45",
      label: (
        <div className="text-gray-600 font-medium">
          How can I provide feedback or suggestions for improving mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You can provide feedback or suggestions through the customer service
          and designated channels provided by mCare.
        </div>
      ),
    },
    {
      key: "46",
      label: (
        <div className="text-gray-600 font-medium">
          Is there a customer loyalty program for mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Loyalty programs or rewards may be available for active subscribers;
          refer to promotional offers for details.
        </div>
      ),
    },
    {
      key: "47",
      label: (
        <div className="text-gray-600 font-medium">
          Can I opt out of receiving marketing communications from mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can manage your communication preferences through the
          settings or preferences section of the platform.
        </div>
      ),
    },
    {
      key: "48",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any restrictions on the types of medications available for
          purchase?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medications available for purchase may be subject to regulatory
          restrictions and may vary based on availability from our partner
          pharmacy and coverage by insurance.
        </div>
      ),
    },
    {
      key: "49",
      label: (
        <div className="text-gray-600 font-medium">
          Can clients communicate with customer support through the app?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Clients can conveniently communicate with our customer support team
          via the in-app chat feature. Alternatively, they have the option to
          contact our dedicated customer service hotlines for quick and
          efficient assistance.
        </div>
      ),
    },
    {
      key: "50",
      label: (
        <div className="text-gray-600 font-medium">
          Are there any exclusions to the health insurance coverage?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, certain services and conditions such as elective cosmetic
          procedures and dental care are excluded from coverage. Kindly reach
          out to our designated customer care units for more information. Refer
          to terms and conditions for details.
        </div>
      ),
    },
    {
      key: "51",
      label: (
        <div className="text-gray-600 font-medium">
          How are claims processed under health insurance?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Claims are processed by our health insurance partner.
        </div>
      ),
    },
    {
      key: "52",
      label: (
        <div className="text-gray-600 font-medium">
          How do I know if my health insurance claim has been processed?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You will receive notifications or updates regarding the status of your
          health insurance claims through the designated communication channels.
        </div>
      ),
    },
    {
      key: "53",
      label: (
        <div className="text-gray-600 font-medium">
          How do I update my personal information or billing details?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Personal information and billing details can be updated through the
          settings or profile section of the mCare platform.
        </div>
      ),
    },
    {
      key: "54",
      label: (
        <div className="text-gray-600 font-medium">
          Is there a system in place for emergencies such as road traffic
          accidents or stroke?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, our system includes emergency protocols with designated emergency
          numbers on the health insurance cards that clients can call for
          assistance during emergencies like road traffic accidents, heart
          attacks, and other critical emergencies.
        </div>
      ),
    },
    {
      key: "55",
      label: (
        <div className="text-gray-600 font-medium">
          How are medical records managed within mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medical records are managed securely and confidentially, adhering to
          strict privacy regulations and industry standards. All medical data is
          encrypted to ensure protection against unauthorized access.
        </div>
      ),
    },
    {
      key: "56",
      label: (
        <div className="text-gray-600 font-medium">
          Does the subscription include ambulance cover?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, Gold Package holders receive ambulance services as part of their
          subscription within their monthly out-patient limits.
        </div>
      ),
    },
    {
      key: "57",
      label: (
        <div className="text-gray-600 font-medium">
          Can I access mCare on multiple devices?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can access mCare on multiple devices using your login
          credentials.
        </div>
      ),
    },
    {
      key: "58",
      label: (
        <div className="text-gray-600 font-medium">
          How do I access teleconsultation services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You can access teleconsultation services through the designated
          platforms (Ayoba, MyMTN App, or the MedPharma App), where you'll be
          connected with healthcare professionals.
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  return (
    <div className="flex  w-full flex-col justify-start items-center">
      <Nav />
      <div
        className="w-full flex justify-center items-center h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-10 flex flex-col justify-center items-center mt-20">
          <h1 className="lg:text-6xl md:text-4xl text-3xl text-white font-bold">
            Frequently Asked Questions
          </h1>
          <div className="mt-8 flex flex-row items-center ">
            <Link
              to="/"
              className="text-[#ffffff]  hover:text-gray-300 text-xl font-semibold"
            >
              Home
            </Link>
            <div className=" mx-3 ">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="" className="text-[#ffffff] text-xl font-semibold">
              FAQs
            </Link>
          </div>
        </div>
      </div>
      <div className="py-6 bg-[#E1F2FEB5] w-full flex flex-col justify-center items-center ">
        <h1 className="font-bold md:text-3xl text-xl flex justify-center">
          Frequently Asked Questions
        </h1>

        {/* <Link
          to="/main/appointments/all"
          className="flex text-lg mt-5 items-center text-[#000]"
        >
          <h4 className="text-[#1A94EC] hover:text-[#ffffff]">Home </h4>
          <h4>/ Faqs</h4>
        </Link> */}
        <div className="w-full flex flex-col justify-start items-center bg-[#E1F2FEB5] pt-8 pb-4">
          <div className="mt-8 w-[70%]">
            <Collapse
              defaultActiveKey={[]}
              onChange={onChange}
              expandIconPosition="right"
              items={items}
              className="w-full bg-white"
              bordered
              //   size="small"
            />
          </div>
          <Button
            onClick={() => navigate("/")}
            size="large"
            className="bg-white mt-8 px-10 rounded"
          >
            Go to home
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
