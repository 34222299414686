import React, { useState } from "react";
import SelectSchedule from "../../components/sections/select-schedule";
import AdditionalDetailsAppt from "../../components/sections/additional-details-appt";
import ApptSummary from "../../components/sections/appt-summary";

export default function Booking() {
  const [tab, setTab] = useState<number>(0);
  const [persist, setPersist] = useState<any>({});

  return (
    <div className="pt-3">
      <div className="bg-white border border-solid border-gray-200 rounded-lg w-full min-h-[85vh] flex justify-center py-3">
        {/* {tab === 0 && (
          <SelectService
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
            data={doctor.services}
          />
        )} */}
        {tab === 0 && (
          <AdditionalDetailsAppt
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
          />
        )}

        {tab === 1 && (
          <SelectSchedule
            tab={tab}
            setTab={setTab}
            // doctorId={doctor.id}
            persist={persist}
            setPersist={setPersist}
          />
        )}

        {tab === 2 && (
          <ApptSummary
            tab={tab}
            setTab={setTab}
            persist={persist}
            setPersist={setPersist}
            // doctor={doctor}
          />
        )}
      </div>
    </div>
  );
}
