/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Guidelines from "../cards/guidelines";
import { Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function UploadPrescription(props: any) {
  const navigate = useNavigate();
  const [image, setImage] = useState<any>();
  const [done, setDone] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const goBackHandler = () => {
    navigate(-1); // Navigate back one step in history
  };

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button
      style={{ border: 0, background: "none" }}
      className="bg-gray-100 text-gray-400 rounded-full text-4xl flex justify-center items-center"
      type="button"
    >
      <CloudUploadOutlined />
    </button>
  );

  const uploadFile = async (signedURL: any, file: string) => {
    try {
      // Read the file
      const response = await fetch(file);
      const fileBlob = await response.blob();

      // Upload the file using the signed URL
      const uploadResponse = await fetch(signedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "",
          // Add any additional headers as needed
        },
        body: fileBlob,
      });

      // Check the upload response
      if (uploadResponse.ok) {
        // console.log('File uploaded successfully');
      } else {
        // console.error('File upload failed:', uploadResponse.status, uploadResponse.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const customRequestX = async (options: any) => {
    setImage(options.file);
    try {
      const result = await DataProvider.requestS3({
        filename: options.file.name,
        type: options.file.type,
        folder: "prescriptions",
      });
      setData({ imageURL: result.data.publicURL });

      uploadFile(result.data.signedURL, options.file.name);

      options.onSuccess(result.data, options.file);
      setDone(true);
    } catch (error) {
      options.onError(error, options.file);
    }
  };

  const customRequest = async (options: any) => {
    const formData = new FormData();
    formData.append("file", options.file);

    try {
      const result = await DataProvider.cloudUpload(formData);
      setData({ imageURL: result.data.url });
      setImage(result.data.url);
      setDone(true);

      // Handle the result and call onSuccess
      options.onSuccess(result.data, options.file);
    } catch (error) {
      // Handle errors and call onError
      options.onError(error, options.file);
    }
  };

  function _onProceed() {
    props.setPersist({ ...props.persist, image });
    props.setTab(1);
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="lg:w-[40%] md:w-[70%] w-[90%] rounded-lg">
        <Guidelines />
        <div className="border my-5 border-solid border-gray-200 h-40 rounded-xl flex flex-col justify-center items-center">
          <Upload
            className=""
            customRequest={customRequest}
            listType="picture"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          {/* <input type="file" onChange={customRequest} /> */}
          <div className="text-[#0081D4] my-2 text-base font-medium">
            Tap above to upload
          </div>

          <div className="text-[#667085]">
            SVG, PNG, JPG or GIF (max. 800x400px)
          </div>
        </div>

        {!image ? (
          <div className="flex flex-col justify-center items-center my-5">
            <Button onClick={goBackHandler} size="large" className="w-full">
              Back
            </Button>
          </div>
        ) : (
          <Button
            onClick={_onProceed}
            type="primary"
            size="large"
            className={`${done ? "bg-[#00A2F8]" : "bg-[#7ED0FD]"} w-full`}
          >
            <div className="text-base text-white">
              {done ? "Proceed" : "Wait a minute..."}
            </div>
          </Button>
        )}
      </div>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}
