import React, { useCallback, useEffect, useState } from "react";
// import doc from "../../assets/images/doc1.png";
import usr from "../../assets/images/user.png";
import { Button, Rate, notification } from "antd";
import DataProvider from "../../services";
import moment from "moment";
import { PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import LoadWrapper from "../elements/load-wrapper";

type NotificationType = "success" | "info" | "warning" | "error";
export default function ApptSummary(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { persist, setPersist } = props;
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [datax, setDatax] = useState<any>(null);

  const fetch = useCallback(async () => {
    setLoadingx(true);

    const payload = {
      startTime: persist.time.startTime,
      endTime: persist.time.endTime,
    };
    try {
      const { data } = await DataProvider.matchDoctor(payload);
      setDatax(data);
      setLoadingx(false);
    } catch (error) {
      setLoadingx(false);
    }
  }, [persist.time]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  async function _onProceed() {
    const payload: any = {
      doctorId: datax.id,
      scheduleId: persist.schedule.id,
      timeFrameId: persist.time.id,
      paymentMode: persist.paymentmode,
      appointmentType: persist.appointmentType,
      payableAmount: 50,
      hasUnderlyingDisease: persist.hasUnderlyingDisease,
      underlyingDiseaseNotes: persist.underlyingDiseaseNotes,
      takesPreviousMedication: persist.takesPreviousMedication,
      previousMedicationNotes: persist.previousMedicationNotes,
      symptoms: persist.symptoms,
      paymentmode: persist.paymentmode,
      complaint: persist.complaint,
    };

    if (persist.insuranceId) {
      payload.insuranceId = persist.insuranceId;
    }
    setLoading(true);
    try {
      await DataProvider.createAppointment(payload).then((res: any) => {
        setPersist(null);
        setLoading(false);
        openNotificationWithIcon(
          "warning",
          "Success",
          "Appoinment successfully booked!"
        );
        navigate("/main/appointments");
      });
    } catch (error) {
      setLoading(false);
      return openNotificationWithIcon(
        "error",
        "Failed",
        "Appoinment booking failed, try again!"
      );
    }
  }

  return (
    <div className="flex justify-center w-full">
      <div className="lg:w-[40%] md:w-[60%] w-[90%]">
        <h3 className="font-semibold text-sm text-gray-600 my-4">
          Appointment Summary
        </h3>
        <LoadWrapper loading={loadingx}>
          <div className="flex items-center border border-gray-200 p-1 rounded-md">
            {datax && datax.profile_image ? (
              <div className="h-12 w-12 rounded-full mr-4">
                <img src={datax.profile_image} alt="" className="h-full" />
              </div>
            ) : datax && datax.user && datax.user.picture ? (
              <div className="h-12 w-12 rounded-full mr-4">
                <img src={datax.user.picture} alt="" className="h-full" />
              </div>
            ) : (
              <div className="h-12 w-12 rounded-full mr-4">
                <img src={usr} alt="" className="h-full" />
              </div>
            )}

            <div>
              <div className="font-medium text-sm">
                {datax && datax.user.fullname}
              </div>
              <div className=" text-gray-400 text-xs">
                200hrs of Consultations done.
                {/* {datax && datax.institution} */}
              </div>
              <div className="text-sm my-0.5">
                <Rate
                  className="text-sm"
                  allowHalf
                  defaultValue={4.5}
                  style={{ height: 30 }}
                />
              </div>
            </div>
          </div>
        </LoadWrapper>

        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">Date & Time</h3>
          <p className="text-gray-500">
            {moment(persist.schedule.date, "DD-MM-YYYY").format(
              "Do MMMM, YYYY"
            )}
          </p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">
            Appointment Type
          </h3>
          <p className="text-gray-500 capitalize">
            {persist.appointmentType === "video" ? (
              <VideoCameraOutlined className="text-[#06509E] mr-1" />
            ) : (
              <PhoneOutlined className="text-[#06509E] mr-1" />
            )}
            {persist.appointmentType}
          </p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">Symptoms</h3>
          <p className="text-gray-500">{persist.symptoms}</p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">
            Do you have any underlying disease?
          </h3>
          <p className="text-gray-500">
            {persist.hasUnderlyingDisease ? "Yes" : "No"}
          </p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">Diseases</h3>
          <p className="text-gray-500">{persist.underlyingDiseaseNotes}</p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">
            Are on any medications?
          </h3>
          <p className="text-gray-500">
            {persist.hasUnderlyingDisease ? "Yes" : "No"}
          </p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">
            Previous Medications
          </h3>
          <p className="text-gray-500">{persist.previousMedicationNotes}</p>
        </div>
        <div className="my-3">
          <h3 className="text-sm text-gray-700 font-medium">
            Additional details
          </h3>
          <p className="text-gray-500">{persist.complaint}</p>
        </div>
        <div className="my-5">
          <Button
            onClick={_onProceed}
            type="primary"
            size="large"
            color="#0081D4"
            htmlType="submit"
            className="bg-[#0081D4] w-full"
          >
            {loading ? "Booking..." : "Confirm"}
          </Button>

          <Button
            size="large"
            onClick={() => props.setTab(1)}
            className="w-full my-3"
          >
            Back
          </Button>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
