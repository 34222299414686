import moment from "moment";
import React from "react";
import { IoImageOutline } from "react-icons/io5";
import { Button } from "antd";

export default function PresCard(props: any) {
  const { data } = props;
  return (
    <div
      className="border border-gray-200 border-solid bg-white rounded-lg px-3 py-2 mb-4 mx-3 cursor-pointer hover:border-blue-900 hover:border-solid"
      //   onPress={() => router.push(`/prescription/${data.id}`)}
    >
      <div className="flex justify-between">
        <div className=" w-[20%] flex items-center justify-center">
          {data.image !== null ? (
            <img src={data.image} className="h-16 w-32" alt="" />
          ) : (
            <IoImageOutline size={50} className="mr-4" />
          )}
        </div>
        <div className="w-[50%]">
          <h1 className=" font-semibold">{`Order #${data.orderId}`}</h1>
          <p className=" text-gray-400">{`${moment(data.schedule_date).format(
            "Do MMM YYYY"
          )}
            `}</p>
        </div>
        <div>
          <Button>{data.status}</Button>
        </div>
      </div>
    </div>
  );
}
