import { Image } from "antd";

export default function DoctorReport(props: any) {
  const { data } = props;
  return (
    <div className="w-full bg-white">
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-full">
          <div className="w-full">
            <div className="w-full">
              {data.prescription && (
                <div className="mb-4">
                  <h3 className="font-semibold">Prescription</h3>
                  <Image
                    width={100}
                    src={data.prescription}
                    alt="prescription"
                  />
                </div>
              )}
              <table className="divide-y divide-gray-300 w-full ">
                <tbody className="bg-white divide-y divide-gray-300">
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Doctor</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.doctorName}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Diagnosis</div>
                    </td>
                    <td className="px-6 py-4 border-r border text-wrap">
                      <div className="text-sm text-gray-500">
                        {data.diagnosis ? data.diagnosis : "n/a"}
                      </div>
                    </td>
                  </tr>

                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border ">
                      <div className="text-sm text-gray-900">
                        Remarks/Findings
                      </div>
                    </td>
                    <td className="px-6 py-4 border-r border text-wrap">
                      <div className="text-sm text-gray-500">
                        {data.remarks ? data.remarks : "n/a"}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">
                        Recommendations
                      </div>
                    </td>
                    <td className="px-6 py-4 border-r border text-wrap">
                      <div className="text-sm text-gray-500 capitalize">
                        {data.recommendations ? data.recommendations : "n/a"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
