import React, { useState } from "react";
import { Radio, Button, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";
import { AxiosResponse } from "axios";

export default function Passwordoptions(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [verificationMethod, setVerificationMethod] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const {
    emailPlaceholder,
    phonePlaceholder,
    emailDomain,
    phoneSuffix,
    noBack,
    title,
    description,
    onBack,
  } = props;

  const handleVerificationMethodChange = (e: any) => {
    setVerificationMethod(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    props.setEmail(e.target.value);
  };

  const handlePhoneChange = (e: any) => {
    props.setPhoneNumber(e.target.value);
  };

  const handleGetCode = async () => {
    if (verificationMethod === "email" && props.email.length < 5) {
      return api["warning"]({
        message: "Invalid Email",
        description: "Type in a valid email address.",
      });
    }

    if (
      verificationMethod === "phone" &&
      (props.phoneNumber.length !== 10 || !/^\d+$/.test(props.phoneNumber))
    ) {
      return api["warning"]({
        message: "Invalid Phone Number",
        description: "Type in a valid phone number.",
      });
    }

    let response: AxiosResponse<any, any>;
    setLoading(true);

    try {
      switch (verificationMethod) {
        case "email":
          response = await DataProvider.forgotpassword({
            email: props.email.toLowerCase(),
          });
          break;
        case "phone":
          response = await DataProvider.forgotpassword({
            phoneNumber: props.phoneNumber,
          });
          break;
        default:
          throw new Error("Invalid verification method");
      }

      setLoading(false);

      if (response.data.message) {
        api["warning"]({
          message: "OTP Sent",
          description: response.data.message,
        });

        props.setInnerTab(1);
      }
    } catch (error) {
      setLoading(false);
      if (verificationMethod === "email") {
        api["error"]({
          message: "Password Reset",
          description: "Email is not registered. Kindly signup!",
        });
      }

      if (verificationMethod === "phone") {
        api["error"]({
          message: "Password Reset",
          description: "Phone number is not registered. Kindly signup!",
        });
      }
    }
  };

  return (
    <div className=" p-4 border border-gray-300 rounded-lg  bg-white max-w-[320px] mx-auto sm:max-w-[384px] md:max-w-[448px] lg:max-w-[512px] xl:max-w-[576px] ">
      <h3 className="text-lg font-semibold mb-2">
        {title || "Get back into your account"}
      </h3>
      <h3 className="text-lg font-semibold mb-4">
        {title || "How would you like to get your security code?"}
      </h3>
      <Radio.Group
        onChange={handleVerificationMethodChange}
        value={verificationMethod}
        className="mb-4"
      >
        <Radio value="email" className="block mb-2">
          Email {emailDomain}
          {verificationMethod === "email" && (
            <div className="mt-2">
              <p className="text-sm">
                {description ||
                  "To reset your password, please enter your email address associated with your Mcare registration to receive a verification code."}
              </p>
              <Input
                placeholder={
                  emailPlaceholder || "Enter your Mcare registered email"
                }
                value={props.email}
                onChange={handleEmailChange}
                suffix={emailDomain}
                className="mt-2"
              />
            </div>
          )}
        </Radio>
        <Radio value="phone" className="block mb-2">
          SMS {phoneSuffix}
          {verificationMethod === "phone" && (
            <div className="mt-2">
              <p className="text-sm">
                {description ||
                  "To reset your password, please enter the phone number associated with your Mcare registration to receive a verification code."}
              </p>
              <Input
                maxLength={10}
                type="number"
                placeholder={
                  phonePlaceholder || "Enter your verified Mcare phone number"
                }
                value={props.phoneNumber}
                onChange={handlePhoneChange}
                className="mt-2"
              />
            </div>
          )}
        </Radio>
      </Radio.Group>
      <div className="flex justify-between">
        {!noBack && (
          <Button onClick={onBack || (() => navigate(-1))} className="mr-2">
            Cancel
          </Button>
        )}
        <Button
          type="primary"
          onClick={handleGetCode}
          disabled={!verificationMethod || loading}
        >
          {loading ? "Checking..." : "Get code"}
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
