import React from "react";
import { FaRegCircle } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";

type Props = {
  items: any[];
  current: number;
  setCurrent?: React.Dispatch<React.SetStateAction<number>>;
};
export default function Steps(props: Props) {
  const { items, current } = props;

  return (
    <div className="flex justify-evenly items-center w-[80%]">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col justify-center  items-center">
          <p
            className={`text-sm font-semibold ${
              current === index || index < current
                ? "text-[#1A94EC]"
                : "text-[#6C6C6C]"
            }`}
          >
            {item.title}
          </p>

          {current === index || index < current ? (
            <IoMdCheckmarkCircle size={20} color="#1A94EC" className="mt-2" />
          ) : (
            <FaRegCircle size={16} color="#6C6C6C" className="mt-2" />
          )}
        </div>
      ))}
    </div>
  );
}
