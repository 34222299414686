import {
  applyActionCode,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateCurrentUser,
  updateProfile,
  sendEmailVerification,
  User,
  ActionCodeSettings,
  getIdTokenResult,
  updateEmail,
  updatePassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ApplicationVerifier,
} from "firebase/auth";
import { auth, db } from "./firebase.config";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { convertToInternationalFormat } from "../utils";

// const provider = new GoogleAuthProvider();

// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://127.0.0.1:3000");
// }

// RecaptchaVerifier instance will be used for phone number authentication
let recaptchaVerifier: RecaptchaVerifier | null = null;

const actionCodeSettings: ActionCodeSettings = {
  url: "https://medpharma.care/",
  iOS: {
    bundleId: "com.medpharma",
  },
  android: {
    packageName: "com.medpharma",
    installApp: true,
    minimumVersion: "12",
  },
  handleCodeInApp: true,
};

const Firebase = {
  // auth
   // Initialize reCAPTCHA
  initializeRecaptcha: (elementId: string) => {
    if (!recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(
        auth, // Pass the element ID where the reCAPTCHA will be rendered
        'sign-in-button',
        {
          size: "invisible", // or 'normal' for a visible captcha
          callback: (response: any) => {
            // reCAPTCHA solved - allow phone number auth
          },
        },
      );
    }
  },
  recaptchaVerifier: () => {
    return new RecaptchaVerifier(
      auth, 
      'recaptcha-container',// Pass the element ID where the reCAPTCHA will be rendered
      {
        size: "invisible", // or 'normal' for a visible captcha
        callback: (response: any) => {
          // reCAPTCHA solved - allow phone number auth
        },
      },
    )
  },
    // Phone Sign-In
  loginWithPhoneNumber: async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier) => {
    if (!recaptchaVerifier) {
      throw new Error("RecaptchaVerifier not initialized. Call initializeRecaptcha first.");
    }
    const number = "+" + convertToInternationalFormat(phoneNumber);
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, number, recaptchaVerifier);
      return confirmationResult; // Save this result to confirm the code later
    } catch (error) {
      throw error;
    }
  },

   // Confirm the verification code (from user input)
  confirmPhoneCode: async (confirmationResult: any, verificationCode: string) => {
    try {
      const result = await confirmationResult.confirm(verificationCode);
      return result.user; // This will be the authenticated user
    } catch (error) {
      // console.error("Code confirmation failed:", error);
      throw error;
    }
  },

  loginWithEmail: async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  },
  signupWithEmail: async (email: string, password: string) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  },
  sendVerificationEmail: async (user: User) => {
    return await sendEmailVerification(user, actionCodeSettings);
  },
  signOut: async () => {
    return await signOut(auth);
  },
  checkUserAuth: async (user: any) => {
    return onAuthStateChanged(auth, user);
  },
  passwordReset: async (email: string) => {
    return await sendPasswordResetEmail(auth, email);
  },
  updateUserPassword: async (user: User, newPassword: string) => {
    return await updatePassword(user, newPassword);
  },
  currentUser: async () => {
    return await auth.currentUser;
  },
  updateUserProfile: async (user: User, data: any) => {
    return await updateProfile(user, data);
  },
  updateCurrentUser: async (user: any) => {
    return await updateCurrentUser(auth, user);
  },
  updateUserEmail: async (email: string) => {
    const user: any = auth.currentUser;
    // if (!user) throw Alert.alert("Error", "No user logged in");
    return await updateEmail(user, email);
  },
  activationCode: async (code: string) => {
    return await applyActionCode(auth, code);
  },
  isTokenExpired: async (): Promise<boolean> => {
    const user = auth.currentUser;
    if (user) {
      try {
        const idTokenResult = await getIdTokenResult(user);
        const expirationTime = parseInt(idTokenResult.expirationTime, 10);
        const currentTime = Date.now() / 1000; // Convert to seconds

        // Check if the token is expired (expiration time is in seconds)
        const tokenExpired = currentTime >= expirationTime;
        return tokenExpired;
      } catch (error) {
        return false;
      }
    }
    return false; // Handle the case where there's no user
  },

  // firestore
  createNewUser: async (userData: any) => {
    const userRef = doc(db, "users", userData.uid); //create a reference
    return await setDoc(userRef, userData);
  },
  updateUser: async (userData: any) => {
    const userRef = doc(db, "users", userData.uid);
    return await updateDoc(userRef, userData);
  },
  getUser: async (id: string) => {
    const userRef = doc(db, "users", id);
    const userSnapshot = await getDoc(userRef); //get document snapshot
    return userSnapshot;
  },
};

export default Firebase;
