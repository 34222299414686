import React from "react";

export default function AddressCard(props: any) {
  const { item, onDelete } = props;

  return (
    <div className="border border-solid border-gray-200 p-2 rounded m-2 md:w-[20rem] w-full cursor-pointer">
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-bold md:text-xl text-lg">{item.label}</h3>
        {onDelete && ( // Render delete button/icon if onDelete function is provided
          <button onClick={() => onDelete(item)} className="text-red-500">
            Delete
          </button>
        )}
      </div>
      <p className="text-gray-500">{item.landmark}</p>
      <p className="text-gray-500">{item.town}</p>
    </div>
  );
}
