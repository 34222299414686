/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Button, Collapse, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

type ExpandIconPosition = "start" | "end";
export default function FAQ() {
  const navigate = useNavigate();
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("start");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {};

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <div className="text-gray-600 font-medium">What is mCare?</div>,
      children: (
        <div className="text-gray-800">
          MCare is a subscription - based eHealth service launched by MTN in
          partnership with MedPharma and Phoenix Health Insurance, to provide
          convenient access to e-consultation services,medicines administration
          and delivery, and health insurance.
        </div>
      ),
      //   extra: genExtra(),
    },
    {
      key: "2",
      label: (
        <div className="text-gray-600 font-medium">How can I access mCare?</div>
      ),
      children: (
        <div className="text-gray-800">
          You can access mCare through the Ayoba App, MTN app, Medpharma app, or
          the medpharma chatbot.
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="text-gray-600 font-medium">
          How much does mCare cost?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          The Silver subscription is GH¢10/month, providing access to
          e-consultation and discounted medication. The Gold subscription is
          GH¢166.66/month, offering additional health insurance coverage.
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="text-gray-600 font-medium">
          {" "}
          Can I access mCare from outside Ghana?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          No, mCare is currently only available for residents within Ghana.
        </div>
      ),
    },
  ];

  return (
    <div className="min-h-[40vh] w-full flex flex-col justify-start items-center bg-[#E1F2FEB5] pt-8 pb-10">
      <h1 className="font-bold md:text-3xl text-xl">
        Frequently Asked Questions
      </h1>
      <div className="mt-8 w-[70%]">
        <Collapse
          defaultActiveKey={[]}
          onChange={onChange}
          expandIconPosition="right"
          items={items}
          className="w-full bg-white"
          bordered
          //   size="small"
        />
      </div>
      <Button
        onClick={() => navigate("/faq")}
        size="large"
        className="bg-white mt-8 px-10 rounded"
      >
        View more
      </Button>
    </div>
  );
}
