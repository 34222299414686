import React from "react";
// import claim from "../../assets/images/claim.png";
import { useNavigate } from "react-router-dom";
// import Colors from "../../constants/Colors";
import DeathClaimsT from "../../components/tables/deathT";
import { Button } from "antd";
import img from "../../assets/images/poweredbymtn.png";

export default function Claims() {
  const navigate = useNavigate();
  // const [value, setValue] = useState<"death" | "hospitalized">("death");
  return (
    <div className="md:p-4 p-2 min-h-[90vh] w-full">
      <div className="bg-white rounded-lg w-full h-full border border-solid border-gray-200 flex flex-col justify-start items-start py-2">
        <div className=" p-2 w-full flex justify-start items-center">
          <Button
            type="primary"
            onClick={() => navigate("/main/claims/request")}
            style={{ backgroundColor: "#06509E" }}
          >
            Request Claims
          </Button>
        </div>

        <div className="w-full h-full">
          <DeathClaimsT />
        </div>
      </div>
      <div className="flex justify-center mt-5">
        <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
      </div>
    </div>
  );
}
