/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import image from "../../assets/images/header1.jpg";
import { Button, message } from "antd";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import EmailInput from "../form/email-input";
import PasswordInput from "../form/password-input";
import CustomInput from "../form/custom-input";
import LoadWrapper from "../elements/load-wrapper";
import DataProvider from "../../services";
import Firebase from "../../core/firebase";
import { useAppStore } from "../../store";
import UploadPicture from "./upload-picture";
import ResetPassword2 from "./reset-password2";
import Passwordotp2 from "./passwordotp2";
import ForgotPassword2 from "./forgot-password2";
import Passwordoptions from "./passwordoptions2";
import Passwordoptions2 from "./passwordoptions2";

export default function Account(props: any) {
  const { setUser } = useAppStore();
  const navigation = useNavigate();
  const [innerTab, setInnerTab] = useState<number | null>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [weight, setWeight] = useState<string>("");
  const [height, setHeight] = useState<string>("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState<any>(null);
  const [gender, setGender] = useState({ value: null });
  const [dob, setDob] = useState<any>(null);
  const [otp, setOtp] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const getProfile = useCallback(async () => {
    setLoading(true);
    const { data } = await DataProvider.getProfile();
    setUser({ ...data });
    setEmail(data.email);
    setName(data.fullname);
    setPhone(data.phoneNumber);
    setDob(data.dateOfBirth);
    setHeight(data.height);
    setWeight(data.weight);
    setGender({ value: data.gender });
    setImage(data.picture);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.tab === "1" && innerTab === null) {
      getProfile();
    }
  }, [props.tab, getProfile, innerTab]);

  const updateProfile = async () => {
    setLoading(true);
    messageApi
      .open({
        type: "loading",
        content: "Profile updating...",
        duration: 2.5,
      })
      .then(async () => {
        try {
          const user: any = Firebase.currentUser();
          if (user.email !== email) {
            await Firebase.updateUserEmail(email);
          }

          // if (user.displayName !== name) {
          //   await Firebase.updateUserProfile(user, {
          //     displayName: name,
          //   });
          // }

          const payload = { email, name, phoneNumber: phone, weight, height };
          const { data } = await DataProvider.updateProfile(payload);
          setUser({ ...data });
          setLoading(false);
          message.success("Profile updated successfully.");
          getProfile();
        } catch (error: any) {
          setLoading(false);
          message.error("Failed to update profile. Try again later..");
        }
      });
  };

  function changePassword() {
    setInnerTab(1);
  }

  return (
    <div className="bg-white w-full h-full rounded-lg border border-solid border-gray-200 py-4 md:px-4 px-2">
      {innerTab === null ? (
        <>
          <LoadWrapper loading={loading}>
            <div className="border-b pb-4">
              <div className="flex md:flex-row flex-col justify-start my-2">
                <div className="md:w-72 w-full font-medium text-sm md:mb-0 mb-2">
                  <h3>Your photo</h3>
                  <p>This will be displayed on your profile.</p>
                </div>
                <div className="flex flex-col justify-center items-center md:ml-20">
                  <div className="shadow-md h-28 w-28 flex justify-center items-center border border-solid border-gray-200 rounded-full">
                    <img
                      src={image}
                      alt=""
                      className="rounded-full h-24 w-24"
                    />
                  </div>
                  <div className="flex mt-5 ">
                    {/* <Button type="link" style={{ color: Colors.deepgray }}>
                      Delete
                    </Button> */}
                    <Button
                      onClick={() => setInnerTab(0)}
                      type="primary"
                      style={{ background: Colors.primary }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                <div className="md:w-72 w-full font-medium text-sm">
                  <h3 className="">Name</h3>
                </div>
                <div className="md:ml-20 md:w-[50%] w-full">
                  <CustomInput
                    style="py-2"
                    placeholder="Enter your full name"
                    value={name}
                    onChangeText={setName}
                  />
                </div>
              </div>

              <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                <div className="md:w-72 w-full font-medium text-sm">
                  <h3 className="">Email</h3>
                </div>
                <div className="md:ml-20 md:w-[50%] w-full">
                  <EmailInput
                    noTitle
                    email={email}
                    setEmail={setEmail}
                    placeholder="Enter your email address."
                  />
                </div>
              </div>

              <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                <div className="md:w-72 w-full font-medium text-sm">
                  <h3>Password</h3>
                </div>
                <div className="md:ml-20 md:w-[50%] w-full">
                  <PasswordInput
                    disable
                    required
                    noTitle
                    change
                    password={password}
                    setPassword={setPassword}
                    placeholder="*****************"
                    inner
                    innerFunc={changePassword}
                  />
                </div>
              </div>
            </div>
            <div className="border-b pb-4 mt-4">
              <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                <div className="md:w-72 w-full font-medium text-sm">
                  <h3>Weight (kg)</h3>
                </div>
                <div className="md:ml-20 md:w-[50%] w-full">
                  <CustomInput
                    style="py-2"
                    placeholder="75kg"
                    value={weight}
                    onChangeText={setWeight}
                  />
                </div>
              </div>
              <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                <div className="md:w-72 w-full font-medium text-sm">
                  <h3>Height (cm)</h3>
                </div>
                <div className="md:ml-20 md:w-[50%] w-full">
                  <CustomInput
                    style="py-2"
                    placeholder="265cm"
                    value={height}
                    onChangeText={setHeight}
                  />
                </div>
              </div>
              <div className="">
                <Button
                  onClick={updateProfile}
                  type="primary"
                  style={{ background: Colors.primary }}
                >
                  Update Information
                </Button>
              </div>
            </div>
          </LoadWrapper>
          {contextHolder}
        </>
      ) : innerTab === 0 ? (
        <UploadPicture innerTab={innerTab} setInnerTab={setInnerTab} />
      ) : innerTab === 1 ? (
        <Passwordoptions2
          main
          noBack
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          innerTab={innerTab}
          setInnerTab={setInnerTab}
        />
      ) : innerTab === 2 ? (
        <Passwordotp2
          otp={otp}
          setOtp={setOtp}
          innerTab={innerTab}
          setInnerTab={setInnerTab}
          setEmail={setEmail}
        />
      ) : innerTab === 3 ? (
        <ResetPassword2
          otp={otp}
          email={email}
          innerTab={innerTab}
          setInnerTab={setInnerTab}
        />
      ) : null}
    </div>
  );
}
