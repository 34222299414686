import React, { useEffect, useState } from "react";
import { Button, Result, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import DataProvider from "../../services";
import Colors from "../../constants/Colors";

export default function ConfirmPayment() {
  const [api, contextHolder] = notification.useNotification();
  const { plan, user, setUser } = useAppStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(0);
  const [time, setTime] = useState(120); // 2 minutes in seconds
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (!user) navigate("/sign-up");
  }, [user, navigate]);

  const checking = async () => {
    try {
      setLoading(true);

      const { data } = await DataProvider.getUserSubscription();

      if (data.message && data.message === "Not a subscriber!") {
        setLoading(false);
        return api["warning"]({
          message: "Warning",
          description:
            "Payment initiation failed. Kindly go back and try again!",
        });
      } else if (data && data.status !== "active") {
        setLoading(false);
        setStatus(2);
        return;
      }
      const result = await DataProvider.getProfile();
      setUser(result.data);
      setStatus(1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return api["error"]({
        message: "Error",
        description: "Payment confirmation failed. Kindly try again.",
      });
    }
  };

  const startCountdown = () => {
    setIsActive(true);
    setTime(120); // Reset time to 2 minutes
  };

  // Update the countdown every second
  useEffect(() => {
    if (!isActive) return;
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          setIsActive(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isActive]);

  // Automatically start countdown on component mount
  useEffect(() => {
    startCountdown();
  }, []);

  // Format time as MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex justify-center items-center w-full h-[85vh]">
      <div className="lg:w-[50%] md:w-[80%]">
        {status === 0 && (
          <>
            <Result
              status="info"
              title="Payment has been initiated!"
              subTitle={`A popup of the payment will appear on your device. Kindly confirm with your pin to make payment. If payment popup delays, dial *170# to approve payment.`}
              extra={[
                <Button
                  disabled={isActive}
                  onClick={checking}
                  style={{
                    backgroundColor: isActive ? "" : Colors.primaryBlue,
                  }}
                  type={isActive ? undefined : "primary"}
                  key="confirm"
                >
                  {isActive
                    ? ` Time Remaining: ${formatTime(time)}`
                    : loading
                    ? "Checking..."
                    : "Click Here To Confirm Payment"}
                </Button>,
                <Button
                  style={{ color: Colors.primaryBlue }}
                  onClick={() =>
                    plan && navigate(`/plan/billing/${plan.offercode}`)
                  }
                  key="back"
                >
                  Go Back
                </Button>,
                <div className="m-4">Time Remaining: {formatTime(time)}</div>,
              ]}
            />
          </>
        )}
        {status === 1 && (
          <Result
            status="success"
            title={`Successfully Subsccribed to Mcare ${plan?.description}`}
            subTitle="An email has been sent to kindly check and follow the steps to access benefits."
            extra={[
              <Button
                onClick={() => navigate("/main")}
                type="primary"
                key="home"
                style={{ backgroundColor: Colors.primaryBlue }}
              >
                Go Home
              </Button>,
              <Button onClick={() => navigate("/sign-in")} key="signin">
                Sign In
              </Button>,
            ]}
          />
        )}

        {status === 2 && (
          <Result
            status="info"
            title={`Your account is being processed for activation.`}
            subTitle="This may take up to an hour. A customer agent will contact you shortly for assistance."
            extra={[
              <Button
                onClick={() => navigate("/main")}
                type="primary"
                key="home"
                style={{ backgroundColor: Colors.primaryBlue }}
              >
                Go Home
              </Button>,
              // <Button onClick={() => navigate("/sign-in")} key="signin">
              //   Sign In
              // </Button>,
            ]}
          />
        )}
      </div>

      {contextHolder}
    </div>
  );
}
