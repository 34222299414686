import React, { ReactNode, useContext, useState } from "react";

type Props = {
  children: ReactNode;
};

type DataState = {
  tokenx: string;
  setTokenx: React.Dispatch<React.SetStateAction<string>>;
};

const initalState: DataState = {
  tokenx: "",
  setTokenx: () => {},
};

const DataContext = React.createContext<DataState>(initalState);

export function DataProvider({ children }: Props) {
  const [tokenx, setTokenx] = useState<string>("");

  const providerValue = {
    tokenx,
    setTokenx,
  };
  return (
    <DataContext.Provider value={providerValue}>
      {children}
    </DataContext.Provider>
  );
}

export const useDatContext = () => useContext(DataContext);
