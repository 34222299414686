import React from "react";
import MCareHaeder from "../components/sections/mcare-haeder";
// import LogoList from "../components/sections/logo-list";
import Nav from "../components/layout/nav";
// import Service from "../components/sections/service";
import Record from "../components/sections/record";
// import Plans from "../components/sections/plans";
import FAQ from "../components/sections/faq";
import Footer from "../components/sections/footer";
import Contactaddress from "../components/sections/contact-address";

export default function Home() {
  return (
    <div className="flex  w-full flex-col justify-start items-center">
      <Nav />
      <MCareHaeder />
      <Record />
      <FAQ />
      <Contactaddress />
      <Footer />
    </div>
  );
}
