/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

export default function EmailInput(props: any) {
  const { setEmail, email, required } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (required || (email && email.length > 1)) {
      const validation =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validation.test(props.email)) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [props, email]);

  return (
    <div className="w-full my-4">
      <div className="my-2">
        {props.noTitle ? null : (
          <p className="mb-1 md:text-base text-sm">
            {props.title ? props.title : "Email"}
          </p>
        )}
        <input
          className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={
            props.placeholder ? props.placeholder : "eg.abc@mail.com"
          }
        />
      </div>
      {error && <div className="text-red-500">Enter a valid email address</div>}
    </div>
  );
}
