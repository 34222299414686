/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../services";
import CardLoader from "../../components/elements/card-loader";
import Empty from "../../components/elements/empty";
import AppointmentCard from "../../components/cards/appointment-card";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";

export default function AllAppointments() {
  const [data, setData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const results = await DataProvider.getAllAppointment(
        currentPage,
        pageSize
      );
      setData(results.data.appointments);
      setTotalPages(results.data.totalPages);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData]);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className="pt-3">
      <div className="bg-white border border-solid border-gray-300 rounded-lg w-full lg:h-[85vh] min-h-[85vh] flex flex-col justify-start p-2">
        {/* <div className="border w-full h-10 mb-5"></div> */}
        <div className="">
          {loading && data === null ? (
            <div className="flex md:justify-start justify-center flex-wrap">
              {[1, 2, 3, 4, 5].map((v, idx) => (
                <CardLoader key={idx} />
              ))}
            </div>
          ) : data && data.length > 0 ? (
            <div>
              <div className="flex md:justify-start justify-center flex-wrap">
                {data.map((v, idx) => (
                  <AppointmentCard data={v} key={idx} />
                ))}
              </div>
              <div className="w-full flex justify-center my-4">
                <Pagination
                  current={currentPage}
                  onChange={onChange}
                  total={totalPages}
                />
              </div>
            </div>
          ) : (
            <div className="">
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Empty"
                buttons={[
                  {
                    name: "Book an Appointment",
                    path: "/main/appointments",
                  },
                  {
                    name: "Refresh",
                    event: () => fetchData(),
                  },
                ]}
                description="No appointments available."
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
