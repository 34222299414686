import React from "react";
import type { Dayjs } from "dayjs";
import { Calendar } from "antd";
import type { CalendarProps } from "antd";

export default function ApptCalendar(props: any) {
  const { setDate } = props;

  const wrapperStyle: React.CSSProperties = {
    width: "100%",
    // height: "100%"
    // border: `1px solid ${token.colorBorderSecondary}`,
    // borderRadius: token.borderRadiusLG,
  };

  const onPanelChange = (
    value: Dayjs,
    mode: CalendarProps<Dayjs>["mode"]
  ) => {};

  const handleChange = (date: Dayjs) => {
    setDate(new Date(date.toDate()));
  };

  return (
    <div className="lg:h-full border border-solid border-gray-200 rounded-lg bg-white">
      <div style={wrapperStyle}>
        <Calendar
          fullscreen={false}
          onChange={handleChange}
          onPanelChange={onPanelChange}
        />
      </div>
    </div>
  );
}
