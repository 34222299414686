// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

// import { Logger, POSTLogger } from 'amazon-chime-sdk-js';
// import { ChannelSummary } from '@aws-sdk/client-chime-sdk-messaging';
import { MeetingInviteStatus, Presence } from '../constants';

export type FormattedDeviceType = {
  deviceId: string;
  label: string;
};

export type NotificationType = "success" | "info" | "warning" | "error";

export type DeviceType = MediaDeviceInfo | FormattedDeviceType;

export type SelectedDeviceType = string | null;

export type DeviceTypeContext = {
  devices: DeviceType[];
  selectedDevice: SelectedDeviceType;
};

export type LocalVideoContextType = {
  isVideoEnabled: boolean;
  toggleVideo: () => Promise<void>;
};

export type DeviceConfig = {
  additionalDevices?: boolean;
};

export type LocalAudioOutputContextType = {
  isAudioOn: boolean;
  toggleAudio: () => void;
};

export type ContentShareControlContextType = {
  isContentSharePaused: boolean;
  toggleContentShare: () => Promise<void>;
  togglePauseContentShare: () => void;
};

export enum MeetingMode {
  Spectator,
  Attendee,
}

export enum Layout {
  Gallery,
  Featured,
}

// Different CPU Utilizations percentage options for initializing background blur and replacement processors
export const VideoFiltersCpuUtilization = {
  Disabled: '0',
  CPU10Percent: '10',
  CPU20Percent: '20',
  CPU40Percent: '40',
};

// Video Transform Options
export const VideoTransformOptions = {
  None: 'None',
  Blur: 'Background Blur',
  Replacement: 'Background Replacement',
};

export type VideoTransformDropdownOptionType = {
  label: string;
  value: string;
};

// export type MeetingConfig = {
//   simulcastEnabled: boolean;
//   logger: Logger;
//   postLogger?: POSTLogger; // Keep track of POSTLogger to update meeting metadata while joining a meeting.
// };


export interface MessageWrapper {
  content: string;
  createdTimestamp: Date;
  messageId: string;
  senderName: string;
  senderArn: string;
  local: boolean;
}

export interface ChannelMetadata {
  appointmentTimestamp: Date;
  doctor: {
    username: string;
    name: string;
    email: string;
    phone: string;
  };
  patient: {
    username: string;
    name: string;
    email: string;
    phone: string;
  };
  presenceMap: {
    [username: string]: {
      presence: Presence;
      modifiedTimestamp: Date;
    };
  };
  // When deleting this channel, use "sfnExecutionArn" to stop the state machine execution
  // scheduled for sending an SMS message.
  sfnExecutionArn?: string;
}

export interface UpdateChannelMetadata extends ChannelMetadata {
  // Suppose you open two or multiple browser tabs signing in to the same user.
  // 1. In one browser tab, you send a channel message with a client ID to delete a channel and leave the chat UI.
  //    You ignore UPDATE_CHANNEL from the messaging session (WebSocket) in this tab.
  // 2. In the other browser tab, you must handle UPDATE_CHANNEL with a different client ID to leave the chat UI.
  clientId: string;
}

export interface MessageMetadata {
  // Suppose you open two or multiple browser tabs signing in to the same user.
  // 1. In one browser tab, you send a channel message with a client ID and instantly render a chat bubble UI.
  // 2. In the other browser tab, you must handle SEND_CHANNEL_MESSAGE from the messaging session (WebSocket)
  //    with a different client ID to render a chat bubble UI.
  clientId: string;
  isMeetingInvitation?: boolean;
  meetingId?: string;
  meetingInviteStatus?: MeetingInviteStatus;
  presence?: Presence;
  temporaryId?: string;
}

export interface PresenceContent {
  presence: Presence;
}

export interface ChannelMember {
  username: string;
  name: string;
  email: string;
  phone: string;
}

export interface PresenceContent {
  presence: Presence;
}

export interface ChannelMember {
  username: string;
  name: string;
  email: string;
  phone: string;
}

// export interface Channel {
//   appointmentTimestamp: Date;
//   doctor: ChannelMember;
//   patient: ChannelMember;
//   presenceMap: {
//     [username: string]: {
//       presence: Presence;
//       modifiedTimestamp: Date;
//     };
//   };
//   summary: ChannelSummary;
//   sfnExecutionArn?: string;
// }
