import React, { useState } from "react";
import { useGlobalModalContext } from "../../context/global-modal-context";
import { Form, Input, Modal } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import DataProvider from "../../services";
import { useAppState } from "../../context/app-state-provider";

type Geo = {
  lat: number | null;
  lng: number | null;
  town: string | null;
};

export default function AddressModal() {
  const { api, contextHolder } = useAppState();
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, fetch } = modalProps || {};
  const [form] = Form.useForm();

  const [value, setValue] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [geo, setGeo] = useState<Geo>({
    lat: null,
    lng: null,
    town: null,
  });

  const handleModalToggle = () => hideModal();

  async function handleChange(newValue: any, actionMeta: any) {
    setValue(newValue);
    if (newValue) {
      await geocodeByPlaceId(newValue.value.place_id)
        .then((results) => {
          // Extract the latitude and longitude from the results
          const { lat, lng } = results[0].geometry.location;
          setGeo({
            lat: lat(),
            lng: lng(),
            town: newValue.value.description,
          });
        })
        .catch((error) => console.error(error));
    }
  }

  async function onSubmit() {
    const values = await form.getFieldsValue();
    if (!values.label) {
      return api["warning"]({
        message: "Label",
        description: "Add a label, E.g. Home, Work, School, etc...",
      });
    }

    if (!values.landmark) {
      return api["warning"]({
        message: "Landmark",
        description: "Add a landmark, E.g. American House Building",
      });
    }

    if (!geo.lat || !geo.lng || !geo.town) {
      return api["warning"]({
        message: "Address",
        description: "Select your location",
      });
    }

    const payload = {
      label: values.label,
      landmark: values.landmark,
      town: geo.town,
      geoCode: `${geo.lat}, ${geo.lng}`,
    };

    try {
      setLoading(true);
      await DataProvider.addAddress(payload);
      setLoading(false);
      api["success"]({
        message: "Success",
        description: "Address created successfully!",
      });
      fetch();
      hideModal();
    } catch (error: any) {
      setLoading(false);
      api["error"]({
        message: "Error",
        description: error.message,
      });
    }
  }

  return (
    <div>
      <Modal
        title={title || "Add Delivery Address"}
        open={true}
        onCancel={handleModalToggle}
        okText={
          loading ? (
            <span className="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></span>
          ) : (
            "Save Address"
          )
        }
        onOk={onSubmit}
        // okButtonProps={{ className: "flex justify-center items-center" }}
      >
        <div className="pb-8 pt-4">
          <Form form={form} name="basic" layout="vertical" autoComplete="off">
            <Form.Item name="label" label="Label" className="">
              <Input
                size="large"
                placeholder="Label (E.g. Home, Work, School, etc...)"
              />
            </Form.Item>
            <Form.Item name="landmark" label="Landmark" className="-mt-2">
              <Input
                size="large"
                placeholder="Landmark (E.g. American House Building)"
              />
            </Form.Item>
          </Form>
          <div>
            <p className="mb-1">Location</p>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyAhgtfJxTiH2FHISEj_u7EI_FDKQY4yuag"
              apiOptions={{
                apiKey: "AIzaSyAhgtfJxTiH2FHISEj_u7EI_FDKQY4yuag",
                region: "gh",
                language: "en",
                libraries: ["geocoding", "places", "geometry", "maps"],
              }}
              selectProps={{
                value,
                onChange: (newValue: any, actionMeta: any) =>
                  handleChange(newValue, actionMeta),
                placeholder: "Enter your address",
                backspaceRemovesValue: true,
                isClearable: true,
              }}
              autocompletionRequest={{
                types: ["establishment", "geocode"],
                componentRestrictions: {
                  country: "gh",
                },
              }}
            />
          </div>
        </div>
        {contextHolder}
      </Modal>
    </div>
  );
}
