import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Button, Form, Input, notification } from "antd";
import Colors from "../../../constants/Colors";
import DataProvider from "../../../services";

type Geo = {
  lat: number | null;
  lng: number | null;
  town: string | null;
};
export default function AddAddress(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [value, setValue] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [geo, setGeo] = useState<Geo>({
    lat: null,
    lng: null,
    town: null,
  });

  async function handleChange(newValue: any, actionMeta: any) {
    setValue(newValue);
    if (newValue) {
      await geocodeByPlaceId(newValue.value.place_id)
        .then((results) => {
          // Extract the latitude and longitude from the results
          const { lat, lng } = results[0].geometry.location;
          setGeo({
            lat: lat(),
            lng: lng(),
            town: newValue.value.description,
          });
        })
        .catch((error) => console.error(error));
    }
  }

  async function onSubmit() {
    const values = await form.getFieldsValue();
    if (!values.label) {
      return api["warning"]({
        message: "Label",
        description: "Add a label, E.g. Home, Work, School, etc...",
      });
    }

    if (!values.landmark) {
      return api["warning"]({
        message: "Landmark",
        description: "Add a landmark, E.g. American House Building",
      });
    }

    if (!geo.lat || !geo.lng || !geo.town) {
      return api["warning"]({
        message: "Address",
        description: "Select your location",
      });
    }

    const payload = {
      label: values.label,
      landmark: values.landmark,
      town: geo.town,
      geoCode: `${geo.lat}, ${geo.lng}`,
    };
    try {
      setLoading(true);
      await DataProvider.addAddress(payload);
      setLoading(false);
      api["success"]({
        message: "Success",
        description: "Address created successfully!",
      });
      props.setInnerTab(null);
    } catch (error: any) {
      setLoading(false);
      api["error"]({
        message: "Error",
        description: error.message,
      });
    }
  }

  return (
    <div className="w-full flex justify-center">
      <div className="lg:w-[40%] md:w-[70%] w-[90%] rounded-lg">
        <div className="mb-4">
          <h3 className="font-semibold text-base">Add a new address</h3>
        </div>
        <Form form={form} name="basic" layout="vertical" autoComplete="off">
          <Form.Item name="label" label="Label" className="">
            <Input
              size="large"
              placeholder="Label (E.g. Home, Work, School, etc...)"
            />
          </Form.Item>
          <Form.Item name="landmark" label="Landmark" className="-mt-2">
            <Input
              size="large"
              placeholder="Landmark (E.g. American House Building)"
            />
          </Form.Item>
        </Form>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyAhgtfJxTiH2FHISEj_u7EI_FDKQY4yuag"
          apiOptions={{
            apiKey: "AIzaSyAhgtfJxTiH2FHISEj_u7EI_FDKQY4yuag",
            region: "gh",
            language: "en",
            libraries: ["geocoding", "places", "geometry", "maps"],
          }}
          selectProps={{
            value,
            onChange: (newValue: any, actionMeta: any) =>
              handleChange(newValue, actionMeta),
            placeholder: "Enter your address",
            backspaceRemovesValue: true,
            isClearable: true,
          }}
          autocompletionRequest={{
            types: ["establishment", "geocode"],
            componentRestrictions: {
              country: "gh",
            },
          }}
        />
        <div className="my-4 w-full flex flex-col justify-center items-center">
          <Button
            onClick={onSubmit}
            type="primary"
            size="large"
            style={{ backgroundColor: Colors.primary }}
            className="flex justify-center items-center w-full"
          >
            {loading ? (
              <span className="w-5 h-5 border-2 border-white rounded-full animate-spin border-t-transparent"></span>
            ) : (
              "Save Address"
            )}
          </Button>
          <Button
            onClick={() => props.setInnerTab(null)}
            size="large"
            className="flex justify-center items-center w-full mt-4"
          >
            Cancel
          </Button>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
