/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { EyeInvisibleFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function PasswordInput(props: any) {
  const navigate = useNavigate();
  const [type, setType] = useState<"password" | "text">("password");
  const {
    password,
    setPassword,
    required,
    forgot,
    change,
    disable,
    inner,
    innerFunc,
    title,
  } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (required && password && password.length < 8) {
      setError(true);
    } else {
      setError(false);
    }
  }, [props, password]);
  return (
    <div className="my-4">
      {props.noTitle ? null : (
        <div className="flex justify-between mr-4 mb-1">
          <p className="mb-1 md:text-base text-sm">
            {title ? title : "Password"}
          </p>
          {type === "text" ? (
            <div onClick={() => setType("password")} className="cursor-pointer">
              <IoEye size={20} color="#00A2F8" />
            </div>
          ) : (
            <div onClick={() => setType("text")} className="cursor-pointer">
              <IoEyeOff size={20} color="gray" />
            </div>
          )}
        </div>
      )}

      <div>
        <input
          disabled={disable}
          name="password"
          // id="password"
          type={type}
          placeholder={props.placeholder ? props.placeholder : "************"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
        />
      </div>
      {error && (
        <div className="text-red-500">
          Password must be 8 characters or more
        </div>
      )}
      {forgot && (
        <div
          className="bg-transparent w-48 my-3 cursor-pointer"
          onClick={() => navigate("/forgot-password")}
        >
          <div className="font-semibold text-[#1A94EC] md:text-base text-sm">
            Forgot Password?
          </div>
        </div>
      )}
      {change && (
        <div
          className="bg-transparent w-48 my-2 cursor-pointer"
          onClick={() => (inner ? innerFunc() : navigate("/forgot-password"))}
        >
          <div className="font-semibold text-[#1A94EC] text-sm">
            Change Password?
          </div>
        </div>
      )}
    </div>
  );
}
