import React from "react";
// import { Link } from "react-router-dom";
// import medicalcheck from "../../assets/images/medical-check.png";
// import { Button } from "antd";
import Empty from "../elements/empty";

export default function Notsubscribedcarddesktop(props: any) {
  return (
    <div className="bg-white md:h-80 h-68 shadow rounded-xl p-4 border-1 bg-cover grid grid-cols-1 gap-4 items-center ">
      <Empty
        imageStyle="md:h-32 h-20"
        titleStyle="md:text-lg text-sm"
        desStyle="md:text-base"
        title="Not on Mcare"
        buttons={[
          {
            name: "Subscribe",
            path: "/main/plans",
          },
          {
            name: "Refresh",
            event: () => props.action(),
          },
        ]}
        description="You are not subscribed to any package. Kindly click on the button below to subscribe to Mcare."
      />
    </div>
  );
}
