import React, { useCallback, useEffect, useState } from "react";
import SelectPlan from "../../components/sections/select-plan";
// import { packages } from "../../utils/data";
import Steps from "../../components/elements/steps";
import { useAppStore } from "../../store";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";
import { Stepx } from "../../utils/data";

export default function PickAPlan() {
  const { user, setPackage, packages } = useAppStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<any>();
  const [plan, setPlan] = useState<any>();

  useEffect(() => {
    if (!user) navigate("/sign-up");
  }, [user, navigate]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getAllPackages();
      setPackage(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [setPackage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="min-h-screen w-full flex flex-col justify-start items-center py-12">
      <div className="mt-4 mb-5 md:w-[50%] w-full flex flex-col justify-center items-center">
        <Steps
          // size="small"
          // setCurrent={setCurrent}
          current={1}
          items={Stepx}
        />
        <SelectPlan
          plan={plan}
          setPlan={setPlan}
          packages={packages}
          setLoading={setLoading}
          loading={loading}
        />
      </div>
    </div>
  );
}
