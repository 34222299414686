// /* eslint-disable @typescript-eslint/no-unused-vars */
// import { useCallback, useEffect, useRef, useState } from "react";
// import { Message, MessagingSessionObserver } from "amazon-chime-sdk-js";
// import {
//   ChannelMessagePersistenceType,
//   ChannelMessageType,
//   SendChannelMessageCommand,
// } from "@aws-sdk/client-chime-sdk-messaging";
// import {
//   PopOver,
//   PopOverHeader,
//   PopOverItem,
// } from "amazon-chime-sdk-component-library-react";
// import localizedFormat from "dayjs/plugin/localizedFormat";
// import dayjs from "dayjs";
// import calendar from "dayjs/plugin/calendar";

// dayjs.extend(calendar);
// dayjs.extend(localizedFormat);
// const PRESENCE_CHECK_THRESHOLD = 10000; // 10 seconds.
// const REMOTE_ATTENDEE_PRESENCE_CHECK_INTERVAL = 5000; // 5 seconds.
// const PING_INTERVAL = 3000; // 3 seconds.

// export default function Chat() {
//   // For patient view
//   const [meetingId, setMeetingId] = useState<string>();

//   // When a doctor chooses "Call," the MeetingDoctorView component repeats sending meeting invitation messages
//   // until the MeetingPatientView component accepts or denies it. Use the following Set to avoid handling
//   // the already-denied meeting invitation. (e.g., an old invitation can arrive late in slow internet connection.)
//   // const cleanedUpMeetingIdsRef = useRef<Set<string>>(new Set());

//   // For remote attendee presence
//   // const presenceMap = useRef<{ [key: string]: number }>({});
//   const [isRemoteAttendeePresent, setIsRemoteAttendeePresent] = useState(false);

//   // useEffect(() => {
//   //   let observer: MessagingSessionObserver;
//   //   if (messagingSession) {
//   //     observer = {
//   //       messagingSessionDidReceiveMessage: (message: Message) => {
//   //         if (message.type === "CREATE_CHANNEL_MESSAGE") {
//   //           const payload = JSON.parse(message.payload);
//   //           if (payload.ChannelArn !== channelArn) {
//   //             return;
//   //           }
//   //           const senderUsername = payload.Sender.Arn.split("/user/")[1];
//   //           if (message.headers["x-amz-chime-message-type"] === "CONTROL") {
//   //             let content = decodeURIComponent(payload.Content);
//   //             if (content === "ping") {
//   //               presenceMap.current[senderUsername] = Date.now();
//   //             }
//   //             return;
//   //           }
//   //           try {
//   //             const metadata = JSON.parse(
//   //               payload.Metadata
//   //             ) as MessageMetadata;
//   //             if (
//   //               accountType === AccountType.Patient &&
//   //               metadata.isMeetingInvitation &&
//   //               senderUsername === channel.doctor.username &&
//   //               metadata.meetingId &&
//   //               !cleanedUpMeetingIdsRef.current.has(metadata.meetingId)
//   //             ) {
//   //               if (
//   //                 metadata.meetingInviteStatus === MeetingInviteStatus.Unknown
//   //               ) {
//   //                 setMeetingId(metadata.meetingId);
//   //               } else if (
//   //                 metadata.meetingInviteStatus === MeetingInviteStatus.Cancel
//   //               ) {
//   //                 setMeetingId(undefined);
//   //               }
//   //             }
//   //           } catch (error: any) {
//   //             console.warn(
//   //               `AppointmentView::messagingSessionDidReceiveMessage::Failed to decode the message`,
//   //               error
//   //             );
//   //           }
//   //         } else if (message.type === "DELETE_CHANNEL") {
//   //           const payload = JSON.parse(message.payload);
//   //           if (payload.ChannelArn !== channelArn) {
//   //             return;
//   //           }
//   //           setRoute("AppointmentList");
//   //         }
//   //       },
//   //     };
//   //     messagingSession.addObserver(observer);
//   //   }
//   //   return () => {
//   //     messagingSession?.removeObserver(observer);
//   //   };
//   // }, [
//   //   setRoute,
//   //   channelArn,
//   //   messagingSession,
//   //   clientId,
//   //   accountType,
//   //   user.username,
//   //   channel.patient.username,
//   //   channel.doctor.username,
//   // ]);

//   // useEffect(() => {
//   //   (async () => {
//   //     try {
//   //       await messagingClient.send(
//   //         new SendChannelMessageCommand({
//   //           ChannelArn: channelArn,
//   //           Content: encodeURIComponent(ReservedMessageContent.CheckedIn),
//   //           ChimeBearer: appInstanceUserArn,
//   //           Type: ChannelMessageType.STANDARD,
//   //           // The messaging processor Lambda function denies this message if the presence value
//   //           // has not changed. i.e., You will not see the same "checked in" message twice.
//   //           Persistence: ChannelMessagePersistenceType.PERSISTENT,
//   //           Metadata: JSON.stringify({
//   //             presence: Presence.CheckedIn,
//   //             clientId,
//   //           } as MessageMetadata),
//   //         })
//   //       );
//   //     } catch (error: any) {
//   //       console.error(error);
//   //     } finally {
//   //       setCheckedIn(true);
//   //     }
//   //   })();
//   // }, [
//   //   appInstanceUserArn,
//   //   clientId,
//   //   messagingClient,
//   //   channelArn,
//   //   setCheckedIn,
//   // ]);
//   return (
//     <div className="pt-3">
//       <div className="bg-white rounded-lg w-full lg:h-[85vh] min-h-[85vh] flex justify-center py-3 border">
//         chat
//       </div>
//     </div>
//   );
// }

import React from "react";

export default function chat() {
  return <div></div>;
}
