import React, { useState } from "react";
import Nav from "../../components/layout/nav";
// import ForgotPassword2 from "../../components/sections/forgot-password2";
import Passwordotp2 from "../../components/sections/passwordotp2";
import ResetPassword2 from "../../components/sections/reset-password2";
import Passwordoptions from "../../components/sections/passwordoptions";

export default function ForgotPassword() {
  const [innerTab, setInnerTab] = useState<number>(0);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [uid, setUID] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  return (
    <div>
      <Nav />
      <hr />
      <div className="h-screen flex justify-center items-center ">
        {innerTab === 0 && (
          <Passwordoptions
            main
            noBack
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            innerTab={innerTab}
            setInnerTab={setInnerTab}
          />
        )}
        {/* {innerTab === 1 && (
          <ForgotPassword2
            main
            noBack
            email={email}
            setEmail={setEmail}
            innerTab={innerTab}
            setInnerTab={setInnerTab}
          />
        )} */}

        {innerTab === 1 && (
          <Passwordotp2
            main
            uid={uid}
            setUID={setUID}
            noBack
            otp={otp}
            setEmail={setEmail}
            setOtp={setOtp}
            innerTab={innerTab}
            setInnerTab={setInnerTab}
          />
        )}
        {innerTab === 2 && (
          <ResetPassword2
            main
            uid={uid}
            setUID={setUID}
            noBack
            otp={otp}
            email={email}
            innerTab={innerTab}
            setInnerTab={setInnerTab}
          />
        )}
      </div>
    </div>
  );
}
