import React from "react";
import ServiceCard from "../cards/service-card";
import { services } from "../../utils/data";

export default function ServiceList() {
  return (
    <div className="">
      <div>
        <h1 className="font-semibold text-base text-gray-600">Services</h1>
      </div>
      <div className="my-2 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-5 gap-4">
        {/* <div className="my-2 grid grid-cols-2 lg:gap-5 gap-4"> */}
        {[...services].map((v, i) => (
          <ServiceCard data={v} key={i} />
        ))}
      </div>
    </div>
  );
}
