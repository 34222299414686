/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, PathRouteProps, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppStore } from "../store";

interface AuthRoutesProps extends PathRouteProps {
  component?: React.ComponentType<any>;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const { user, token, rehydrated } = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!rehydrated) {
      return;
    }

    if (user && token) {
      return;
    } else {
      navigate("/sign-in");
    }
  }, [user, token, rehydrated]);

  return <Outlet />;
};

export default AuthRoutes;

// const AuthRoutes: React.FC<AuthRoutesProps> = () => {
//   const { user, token } = useAppStore();
//   const navigate = useNavigate();

//   // useEffect(() => {
//   //   if (user && token) {
//   //     return;
//   //   } else {
//   //     navigate("/sign-in");
//   //   }
//   // }, [user, token]);

//   return user && token ? <Outlet /> : navigate("/sign-in");
// };

// export default AuthRoutes;
