/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import Axios, { AxiosError } from "axios";
import { auth } from "./firebase.config";
import { notification } from "antd";

type Props = {
  children: any;
};

export const base = "https://api-v2.medpharma.care";
export const stage = "https://www.api-v2-staging.medpharma.care";
export const tip = "http://localhost:9090";
export const tip2 =
  "https://Api-medpharma-prod-env.eba-rfgyzjma.us-east-1.elasticbeanstalk.com";

const instance = Axios.create({
  baseURL: base,
  headers: { "Content-Type": "application/json" },
});

const AxiosInterceptor = ({ children }: Props) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    instance.interceptors.request.use(
      async function (config) {
        let user = auth.currentUser;

        if (!user) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          user = auth.currentUser;
        }

        if (user) {
          const token = await user.getIdToken();
          config.headers["firebase-token"] = token;
        }

        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    instance.defaults.headers.get["Content-Type"] = "application/json";
    instance.defaults.headers.post["Content-Type"] = "application/json";
    instance.defaults.headers.patch["Content-Type"] = "application/json";

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      {contextHolder}
    </>
  );
};

export default instance;
export { AxiosInterceptor };
