/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "antd";
import React, { useState } from "react";
import DeathCertificate from "../../components/sections/deathCertificate";
import PoliceReport from "../../components/sections/policeReport";
import Affidavits from "../../components/sections/affidavits";
import NationalId from "../../components/sections/nationalId";
import { useAppState } from "../../context/app-state-provider";
import Dischargeslip from "../../components/sections/dischargeslip";
import HospitalInvoice from "../../components/sections/hospitalInvoice";

export default function Uploads(props: any) {
  const { api, contextHolder } = useAppState();
  const [deathCertificate, setDeathCertificate] = useState<any>(null);
  const [policeReport, setPoliceReport] = useState<any>(null);
  const [affidavits, setAffidavits] = useState<any>(null);
  const [nationalId, setNationalId] = useState<any>(null);
  const [done, setDone] = useState<boolean>(false);

  const [hospitalInvoice, setHospitalInvoice] = useState<any>(null);
  // const [excuseDuty, setExcuseDuty] = useState<any>(null);
  const [dischargeslip, setDischargeslip] = useState<any>(null);

  function _onProceed() {
    if (props.persist.type === "Death" && !deathCertificate) {
      return api["warning"]({
        message: "Death Certificate",
        description: "Upload a Death Certificate",
      });
    }

    if (!nationalId) {
      return api["warning"]({
        message: "National ID",
        description: "Upload a National ID",
      });
    }

    if (props.persist.type === "Hospitalized" && !hospitalInvoice) {
      return api["warning"]({
        message: "Hospital Invoice",
        description: "Upload a Hospital Invoice",
      });
    }

    if (props.persist.type === "Hospitalized" && !dischargeslip) {
      return api["warning"]({
        message: "Discharge Slip",
        description: "Upload a Discharge Slip",
      });
    }

    props.setPersist({
      ...props.persist,
      deathCertificate,
      policeReport,
      affidavits,
      nationalId,
      hospitalInvoice,
      dischargeslip,
    });
    props.setTab(3);
  }

  const goBackHandler = () => {
    props.setTab(0); // Navigate back one step in history
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="w-full">
        {props.persist.type === "Death" ? (
          <div className="flex justify-evenly flex-wrap w-full">
            <DeathCertificate setDeathCertificate={setDeathCertificate} />
            <PoliceReport setPoliceReport={setPoliceReport} />
            <Affidavits setAffidavits={setAffidavits} />
            <NationalId setNationalId={setNationalId} />
          </div>
        ) : (
          <div className="flex justify-start flex-wrap w-full">
            <Dischargeslip setDischargeslip={setDischargeslip} />
            <HospitalInvoice setHospitalInvoice={setHospitalInvoice} />
            <NationalId setNationalId={setNationalId} />
          </div>
        )}

        {(props.persist.type === "Death" && deathCertificate) ||
        (hospitalInvoice && dischargeslip) ? (
          <div className="w-full flex justify-center ">
            <Button
              onClick={_onProceed}
              type="primary"
              size="large"
              className="bg-[#00A2F8] sm:w-[30rem] w-full"
            >
              <div className="text-base text-white">Proceed</div>
            </Button>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center my-5 w-full">
            <Button
              onClick={goBackHandler}
              size="large"
              className="w-full sm:w-[30rem]"
            >
              Back
            </Button>
          </div>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
