import React from "react";
import pharm from "../../assets/images/pharm.jpg";
import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function PresViewCard() {
  const navigate = useNavigate();
  return (
    <div className="bg-[#06509E] lg:h-60 md:h-52 h-40 w-full flex rounded-xl shadow md:mt-0 mt-4">
      <img
        src={pharm}
        alt="care"
        className="lg:h-60 md:h-52 h-40 w-[50%] rounded-l-lg"
      />
      <div className="text-white p-2">
        <h4 className="lg:text-2xl sm:text-xl text-base font-semibold mb-2">
          Prescriptions
        </h4>
        <p className="font-semibold lg:text-base sm:text-xs text-[11px]">
          Get a discount on all care products: Medication, Tests, Deliveries,
          Prescriptions, etc.
        </p>
        <div className="hidden lg:block">
          <Button
            onClick={() => navigate("/main/prescriptions/ordering")}
            size="large"
            className="bg-white mt-4 text-[#06509E] w-full font-semibold flex justify-evenly items-center md:text-xs text-[10px]"
          >
            <UploadOutlined /> Upload Prescription
          </Button>
        </div>
        <div className="lg:hidden block mt-2 w-full">
          <Button
            onClick={() => navigate("/main/prescriptions/ordering")}
            size="small"
            className="bg-white text-[#06509E] font-semibold flex justify-evenly items-center md:text-xs text-[10px] w-[60%]"
          >
            <UploadOutlined /> Upload Prescription
          </Button>
        </div>
      </div>
    </div>
  );
}
