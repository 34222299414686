import { RightOutlined } from "@ant-design/icons";
// import React from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/nr1.jpg";
import Footer from "./footer";

export default function Contact() {
  return (
    <div>
      <div
        className="w-full flex justify-center items-center h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-10 flex flex-col justify-center items-center mt-20">
          <h1 className="lg:text-6xl md:text-4xl text-3xl text-white font-bold">
            Contact Medpharma
          </h1>
          <div className="mt-8 flex flex-row items-center ">
            <Link
              to="/"
              className="text-[#ffffff]  hover:text-gray-300 text-xl font-semibold"
            >
              Home
            </Link>
            <div className=" mx-3 ">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="" className="text-[#ffffff] text-xl font-semibold">
              Contact-Us
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
