import React from "react";
import { Checkbox } from "antd";

export default function CustomPickerList(props: any) {
  const { title, value, onValueChange, options } = props;

  return (
    <div className="my-4">
      <h2 className="mb-1 md:text-base text-sm">{title}</h2>
      <div className="flex">
        {(options as Array<string>).map((i, idx) => (
          <div key={idx} className="flex items-center mr-4">
            <Checkbox
              className="h-3 w-3"
              checked={value === i}
              onChange={() => onValueChange(i)}
              style={{ color: value ? "#00A2F8" : undefined }}
            />
            <span className="mx-2 capitalize">{i}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
