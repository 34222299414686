import { DatePicker } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

export default function DobInput(props: any) {
  const { setDob, dob } = props;
  const [error, setError] = useState<boolean>(false);

  const onChange = (date: any, dateString: string | string[]) => {
    setDob(dateString);
  };

  const formatDate = useCallback(
    (validate = null) => {
      let date = dob;
      const reg =
        /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

      if (reg.test(dob)) {
        date = moment(dob, "YYYY-MM-DD").format("YYYY-MM-DD[T]HH:mm:ss");
      }

      if (validate) date = moment(date).format("DD/MM/YYYY");

      return date;
    },
    [dob]
  );

  useEffect(() => {
    const selectedDate = formatDate();
    const age = moment().diff(selectedDate, "years", true);

    if (!age || age < 18) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formatDate, dob]);

  return (
    <div className="w-full">
      <div className="my-2">
        {props.noTitle ? null : (
          <p className="mb-1 md:text-base text-sm">
            {props.title ? props.title : "Date of birth"}
          </p>
        )}
        <DatePicker
          size="large"
          className="w-full"
          onChange={onChange}
          mode="date"
          // value={dob ? new Date(dob) : new Date()}
        />
      </div>
      {error && <div className="text-red-500">Age must be 18 or more</div>}
    </div>
  );
}
