/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import DataProvider from "../../services";
import {
  Button,
  GetRef,
  Image,
  Input,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  TableProps,
} from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import Highlighter from "react-highlight-words";
import moment from "moment";

interface RecordType {
  id: number;
  type: string;
  deathCertificate: string;
  nationalId: string;
  policeReport: string;
  affidavits: string;
  excuseDuty: string;
  dischargeslip: string;
  hospitalInvoice: string;
  notes: string;
  status: string;
  reason: string;
  user: any;
  createdAt: Date;
}

type InputRef = GetRef<typeof Input>;

type DataIndex = keyof RecordType;

export default function DeathClaimsT(props: any) {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function getClaims() {
    try {
      setLoading(true);
      const { data } = await DataProvider.getClaims();
      if (data) {
        setData(data.reverse());
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getClaims();
  }, []);

  const onChange: TableProps<RecordType>["onChange"] = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    // console.log("params", pagination, filters, sorter, extra);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput: any = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<RecordType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          size="small"
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space className="w-full flex justify-between">
          <Button
            style={{ backgroundColor: Colors.primary, width: 70, fontSize: 11 }}
            type="primary"
            className="bg-[#06509E]"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 70, fontSize: 11 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<RecordType> = [
    {
      title: "Request Type",
      dataIndex: "type",
      key: "type",
      filters: [
        {
          text: "Death",
          value: "death",
        },
        {
          text: "Hospitalized",
          value: "hospitalized",
        },
      ],
      //   filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.type.startsWith(value as string),
      className: "md:text-sm text-xs capitalize",
    },
    {
      title: "Uploads",
      dataIndex: "deathCertificate",
      key: "uploads",
      render(value, record, index) {
        return (
          <div className="flex justify-start items-center">
            {record.affidavits && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.affidavits}
              />
            )}
            {record.deathCertificate && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.deathCertificate}
              />
            )}
            {record.dischargeslip && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.dischargeslip}
              />
            )}
            {record.excuseDuty && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.excuseDuty}
              />
            )}
            {record.nationalId && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.nationalId}
              />
            )}
            {record.policeReport && (
              <Image
                className="mx-1"
                style={{ height: 60, width: 60 }}
                src={record.policeReport}
              />
            )}
          </div>
        );
      },
      className: "md:text-sm text-xs",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Rejected",
          value: "rejected",
        },
      ],
      //   filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.status.startsWith(value as string),
      render(value, record, index) {
        return value === "pending" ? (
          <Button
            size="small"
            className="text-xs"
            type="primary"
            style={{ backgroundColor: Colors.deepgray }}
          >
            Pending
          </Button>
        ) : value === "approved" ? (
          <Button
            className="text-xs"
            size="small"
            type="primary"
            style={{ backgroundColor: Colors.deepgray }}
          >
            Approved
          </Button>
        ) : value === "rejected" ? (
          <Button
            className="text-xs"
            size="small"
            type="primary"
            style={{ backgroundColor: Colors.deepgray }}
          >
            Rejected
          </Button>
        ) : (
          <Button
            className="text-xs"
            size="small"
            type="primary"
            style={{ backgroundColor: "black", color: "white" }}
          >
            Other
          </Button>
        );
      },
      className: "md:text-sm text-xs",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render(value, record, index) {
        return (
          <div className="text-xs flex justify-start items-center">
            {moment(value).format("Do MMMM YYYY")}
          </div>
        );
      },
      className: "md:text-sm text-xs",
    },
  ];

  return (
    <div className="flex flex-col justify-start items-center h-full">
      <Table
        scroll={{ x: 800 }}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        loading={loading}
        className="w-full"
        size="small"
      />
    </div>
  );
}
