import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import SubscriberHome from "../pages/subscriber-home";
import Register from "../pages/auth/register";
import Login from "../pages/auth/login";
import Main from "../pages/main";
import Overview from "../pages/overview";
import Appointments from "../pages/appointments";
import Prescription from "../pages/prescriptions";
import Diagnostics from "../pages/diagnostics";
import Profile from "../pages/profile";
import PickAPlan from "../pages/plans/pick-a-plan";
import AuthRoutes from "./auth.routes";
import Ordering from "../pages/prescriptions/ordering";
import Booking from "../pages/appointments/booking";
import Chat from "../pages/chime/chat";
import Details from "../pages/appointments/details";
import Claims from "../pages/claims";
import Request from "../pages/claims/request";
import Cancel from "../pages/appointments/cancel";
import Reschedule from "../pages/appointments/reschedule";
import AllAppointments from "../pages/appointments/all-appointments";
import Billing from "../pages/plans/billing";
import Unsubscribe from "../pages/plans/unsubscribe";
import ConfirmPayment from "../pages/plans/confirm-payment";
import DoctorReport from "../pages/appointments/doctor-report";
import NotFound from "../pages/not-found";
import FAQ from "../pages/faq";
import Termsandconditions from "../pages/terms-and-conditions/termsandconditions";
import ForgotPassword from "../pages/auth/forgot-password";
import Contact from "../components/sections/contact";
import Selectmcareplan from "../pages/plans/select-mcare-plan";
import Mcarebilling from "../pages/plans/mcare-billing";
import ConfirmMcarePayment from "../pages/plans/confirm-mcarepayment";
// import Infomedic from "../pages/infomedic";
// import InfoRequest from "../pages/infomedic/request";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    element: <Home />,
    path: "/",
  },
  {
    element: <SubscriberHome />,
    path: "/sub",
  },
  {
    element: <FAQ />,
    path: "/faq",
  },
  {
    element: <Contact />,
    path: "/contact-us",
  },
  {
    element: <Termsandconditions />,
    path: "/terms",
  },
  {
    element: <ForgotPassword />,
    path: "/forgot-password",
  },
  {
    path: "/sign-in",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Register />,
  },
  {
    element: <AuthRoutes />,
    children: [
      {
        path: "/pick-plan",
        element: <PickAPlan />,
      },
      {
        path: "/plan/billing/:id",
        element: <Billing />,
      },

      {
        path: "/plan/comfirm-pay-00725",
        element: <ConfirmPayment />,
      },
      {
        path: "/main",
        element: <Main />,
        children: [
          {
            element: <Overview />,
            index: true,
          },
          {
            path: "/main/appointments",
            element: <Appointments />,
          },
          {
            path: "/main/claims",
            element: <Claims />,
          },
          {
            path: "/main/claims/request",
            element: <Request />,
          },
          {
            path: "/main/appointments/booking",
            element: <Booking />,
          },
          {
            path: "/main/appointments/all",
            element: <AllAppointments />,
          },
          {
            path: "/main/appointments/:id",
            element: <Details />,
          },
          {
            path: "/main/appointments/:id/report",
            element: <DoctorReport />,
          },
          {
            path: "/main/plans",
            element: <Selectmcareplan />,
          },
          {
            path: "/main/billing/:id",
            element: <Mcarebilling />,
          },
          {
            path: "/main/plan/mcare-comfirm-pay-00725",
            element: <ConfirmMcarePayment />,
          },
          {
            path: "/main/appointments/reschedule/:id",
            element: <Reschedule />,
          },
          {
            path: "/main/appointments/cancel/:id",
            element: <Cancel />,
          },
          {
            path: "/main/appointments/chat",
            element: <Chat />,
          },
          {
            path: "/main/prescriptions",
            element: <Prescription />,
          },
          {
            path: "/main/prescriptions/ordering",
            element: <Ordering />,
          },
          {
            path: "/main/diagnostics",
            element: <Diagnostics />,
          },
          {
            path: "/main/profile/:id",
            element: <Profile />,
          },
          {
            path: "/main/unsubscribe",
            element: <Unsubscribe />,
          },
          // {
          //   path: "/main/infomedic",
          //   element: <Infomedic />,
          // },
          // {
          //   path: "/main/infomedic/:id",
          //   element: <InfoRequest />,
          // },
        ],
      },
    ],
  },
]);
