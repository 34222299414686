import { Button, notification } from "antd";
import React, { useState } from "react";
import DataProvider from "../../services";

export default function Passwordotp2(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length > 6) {
      event.target.value = inputValue.slice(0, 6);
    }
  };

  const handleOTP = async () => {
    if (props.otp.length !== 6) {
      return api["warning"]({
        message: "OTP",
        description: "OTP must be six (6) numbers.",
      });
    }

    setLoading(true);
    try {
      const { data } = await DataProvider.verifypasswordotp(props.otp);
      setLoading(false);
      if (data.valid) {
        api["warning"]({
          message: "Account",
          description: "Email address verified.",
        });
        props.setEmail(data.email);
        setLoading(false);
        props.main ? props.setInnerTab(2) : props.setInnerTab(3);
      } else {
        setLoading(false);
        api["error"]({
          message: "Failed",
          description:
            data.message === "Invalid"
              ? "The short code you typed is invalid, try again."
              : data.message === "Expired"
              ? "The short code you typed is expired, try again."
              : "",
        });
      }
    } catch (error: any) {
      // let rr = AxiosError;
      // rr.ERR_NETWORK;
      // rr.message === ""
      setLoading(false);
      api["error"]({
        message: "Failed",
        description:
          error.message === "ERR_NETWORK"
            ? "Couldn't verify, check your internet connection and try again."
            : error.message,
      });

      //"Something went wrong, kindly try again."
    }
  };

  return (
    <div className="w-full md:h-[70vh] h-[50vh] flex flex-col justify-start items-start">
      {props.noBack ? null : (
        <Button onClick={() => props.setInnerTab(1)} className="my-2">
          Back
        </Button>
      )}

      <div className="w-full my-2 flex flex-col justify-center items-center p-4">
        <div className="md:w-[40%] w-full">
          <h3 className="font-semibold text-lg">Verify Account</h3>
          <p className="py-2">
            A short code has been sent to you, please input it below to reset
            your password.
          </p>
          <input
            max={99999}
            type="number"
            value={props.otp}
            onChange={(e) => props.setOtp(e.target.value)}
            placeholder="000000"
            className="rounded-lg bg-white h-12 text-base px-2 outline-none border border-solid border-gray-200 w-full mb-4"
            onInput={handleInput}
          />
          <Button type="primary" onClick={handleOTP}>
            {loading ? "Ckecking..." : "Verify OTP"}
          </Button>
          {props.noBack ? (
            <Button onClick={() => props.setInnerTab(0)} className="mx-2">
              Go Back
            </Button>
          ) : null}
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
