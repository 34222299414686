import React, { useCallback, useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
// import { packageDetails, useAppStore } from "../../store";
import DataProvider from "../../services";
// import Colors from "../../constants/Colors";
import Loader from "../../components/cards/loader";
import { useNavigate } from "react-router-dom";
import { packageDetails, useAppStore } from "../../store";
import img from "../../assets/images/poweredbymtn.png";

export default function Selectmcareplan(props: any) {
  const navigate = useNavigate();
  const { setPackage, packages, setPlan } = useAppStore();
  // const { screen } = props;
  const [loading, setLoading] = useState<any>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getAllPackages();
      setPackage(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [setPackage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  async function onBuy(planx: packageDetails) {
    setPlan(planx);
    navigate(`/main/billing/${planx.id}`);
  }

  return (
    <div className="min-h-[75vh] w-full bg-white mt-4 rounded-lg p-5 border">
      <Button onClick={() => navigate("/main")} className="my-2">
        Back
      </Button>
      <div className=" mb-4 flex justify-center w-full px-4">
        <h1 className="text-2xl font-bold">
          Choose a <span className="text-[#1A94EC]">Plan</span> that works for
          you
        </h1>
      </div>
      {loading && packages === null ? (
        <div className="border border-solid border-gray-200 flex justify-center items-center h-full">
          <Loader num={1} />
        </div>
      ) : packages && packages.length > 0 ? (
        <div className="mb-6 place-content-center w-full flex-col justify-center">
          <div className="w-full my-4 px-4">
            <label>
              <input
                className="peer/showLabel absolute scale-0"
                type="checkbox"
              />
              <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                  <div className="flex justify-between w-full">
                    <h3 className="text-3xl font-bold">
                      {packages[1].description}
                    </h3>
                    <PlusCircleOutlined size={62} />
                  </div>
                  <div className="text-gray-500 font-medium mt-1 flex">
                    <p>GH¢{packages[1].price}/month</p>
                    <p className="flex mx-1 flex-wrap">
                      (
                      {packages[1].offers.map((v: any, idx: any) => (
                        <span key={idx}>
                          {v.name}
                          {v.name === "Telemedicine" ? (
                            <span className="mx-1">+</span>
                          ) : null}
                        </span>
                      ))}
                      )
                    </p>
                  </div>
                </div>
                <div className="mb-2 w-full">
                  <Button
                    onClick={() => onBuy(packages[1])}
                    size="large"
                    style={{ backgroundColor: "#1A94EC", color: "white" }}
                    className="w-full text-white"
                  >
                    Buy now
                  </Button>
                  {packages[1].offers.map((itx: any, idx: any) => (
                    <div className="my-4" key={idx}>
                      <h4 className="font-semibold mb-1">{itx.name}</h4>
                      <ul>
                        {itx.list.map((i: any, idxx: any) => (
                          <li key={idxx} className="flex items-center">
                            <GiCheckMark
                              size={14}
                              className="text-[#1A94EC] mr-2"
                            />
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </span>
            </label>
          </div>
          <div className="w-full my-4 px-4">
            <label>
              <input
                className="peer/showLabel absolute scale-0"
                type="checkbox"
              />
              <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                  <div className="flex justify-between w-full">
                    <h3 className="text-3xl font-bold">
                      {packages[0].description}
                    </h3>
                    <PlusCircleOutlined size={62} />
                  </div>
                  <div className="text-gray-500 font-medium mt-1 flex">
                    <p>GH¢{packages[0].price}/month</p>
                    <p className="flex mx-2 ">
                      (Telemedicine + Health Insurance)
                    </p>
                  </div>
                </div>
                <div className="mb-2 w-full">
                  <Button
                    onClick={() => onBuy(packages[0])}
                    size="large"
                    style={{ backgroundColor: "#1A94EC", color: "white" }}
                    className="w-full text-white"
                  >
                    Buy now
                  </Button>

                  {packages[0].offers.map((itx: any, idx: any) => (
                    <div className="my-4" key={idx}>
                      <h4 className="font-semibold mb-1">{itx.name}</h4>
                      <ul>
                        {itx.list.map((i: any, idxx: any) => (
                          <li key={idxx} className="flex items-center">
                            <GiCheckMark
                              size={14}
                              className="text-[#1A94EC] mr-2"
                            />
                            {i}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </span>
            </label>
          </div>
          {/* {packages.map((v: any, idx: any) => (
            <div className="w-full my-4 px-4" key={idx}>
              <label>
                <input
                  className="peer/showLabel absolute scale-0"
                  type="checkbox"
                />
                <span className="block max-h-24 w-full overflow-hidden rounded-lg bg-gray-100 px-4 py-0 shadow transition-transform duration-1000 peer-checked/showLabel:max-h-[150vh]">
                  <div className="flex flex-col h-24 justify-end py-2  cursor-pointer items-start">
                    <div className="flex justify-between w-full">
                      <h3 className="text-3xl font-bold">{packages[1].description}</h3>
                      <PlusCircleOutlined size={62} />
                    </div>
                    <div className="text-gray-500 font-medium mt-1">
                      <p>GH¢{packages[1].price}/month</p>
                    </div>
                  </div>
                  <div className="mb-2 w-full">
                    {packages[1].name === "Gold" ? (
                      <Button
                        // onClick={() => onBuy(v)}
                        size="large"
                        style={{
                          backgroundColor: Colors.deepgray,
                          color: "white",
                        }}
                        className="w-full text-white font-semibold"
                      >
                        Coming Soon
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onBuy(v)}
                        size="large"
                        style={{ backgroundColor: "#1A94EC", color: "white" }}
                        className="w-full text-white"
                      >
                        Buy now
                      </Button>
                    )}
                    {packages[1].offers.map((itx: any, idx: any) => (
                      <div className="my-4" key={idx}>
                        <h4 className="font-semibold mb-1">{itx.name}</h4>
                        <ul>
                          {itx.list.map((i: any, idxx: any) => (
                            <li key={idxx} className="flex items-center">
                              <GiCheckMark
                                size={14}
                                className="text-[#1A94EC] mr-2"
                              />
                              {i}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </span>
              </label>
            </div>
          ))} */}
        </div>
      ) : (
        <div className=""></div>
      )}
      <div className="flex justify-center">
        <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
      </div>
    </div>
  );
}
