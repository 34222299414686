import React, { useState } from "react";
import { useAppStore } from "../../../store";
import { Button, notification } from "antd";
import { convertToInternationalFormat } from "../../../utils";
import DataProvider from "../../../services";
import PhoneNumberInput from "../../../components/form/phone-number";
import Colors from "../../../constants/Colors";

export default function Pay(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const { plan, state, user } = useAppStore();
  const [number, setNumber] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function onRequestSub() {
    if (!number || number.length > 10 || number.length < 10) {
      return api["warning"]({
        message: "Warning",
        description: "Phone number is required.",
      });
    }

    if (!number.startsWith("0")) {
      return api["warning"]({
        message: "Warning",
        description: "Phone number must start with 0.",
      });
    }

    const payload = {
      mcareId: plan?.id,
      msisdn: convertToInternationalFormat(number),
    };

    const payloadx = {
      subscriptionId: user?.subscription.id,
      newMcareId: plan?.id,
      msisdn: convertToInternationalFormat(number),
    };

    try {
      setLoading(true);
      // console.log(state);
      if (state === "SUB") {
        await DataProvider.requestSubscription(payload);
      }

      if (state === "RENEW") {
        await DataProvider.renewSubscription(payload);
      }

      if (state === "CHANGE") {
        await DataProvider.changeSubscription(payloadx);
      }

      setLoading(false);
      props.setScreen(3);
    } catch (error: any) {
      setLoading(false);
      return api["error"]({
        message: "Failed",
        description:
          error.message === "ERR_NETWORK"
            ? "Couldn't verify, check your internet connection and try again."
            : error.message,
      });
    }
  }
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="mt-4 mb-5 lg:w-[60%] md:w-[80%] w-full flex flex-col justify-center items-center">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 w-full my-5 p-4">
          <div className="border border-solid border-gray-200 p-4">
            <div className="bg-black w-full h-20 flex justify-center mb-4 items-center">
              <h1 className="text-white text-2xl font-semibold">
                {plan?.description}
              </h1>
            </div>
            <div className="py-2 border-b flex justify-between items-center">
              <p className="md:text-base text-sm">Mcare {plan?.description}</p>
              <p className="md:text-base text-sm">GH¢{plan?.price}/mo</p>
            </div>
            <div className="py-2 border-b flex justify-between items-center">
              <p className="md:text-base text-sm">Period</p>
              <p className="md:text-base text-sm">1 month</p>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="md:text-base text-sm">Total</p>
              <p className="md:text-base text-sm">GH¢{plan?.price}</p>
            </div>
          </div>
          <div className="border border-solid border-gray-200 p-4">
            <div className="bg-gray-100 w-full h-16 flex justify-center items-center">
              <h3 className="font-medium text-base">Mobile Money</h3>
            </div>
            <div className="">
              <PhoneNumberInput
                required
                title="Enter Phone Number"
                value={number}
                onChangeText={setNumber}
                placeholder="eg:0240005678"
              />

              <div>
                <select
                  name="network"
                  id=""
                  value="mtn"
                  onChange={() => {}}
                  className="rounded-lg bg-white px-2 outline-none border border-solid border-gray-200 w-full h-12 text-base"
                >
                  <option value="mtn" className="flex items-center">
                    MTN
                  </option>
                </select>
                <p className="text-gray-500 md:text-sm text-xs my-2">
                  By Clicking “Proceed with payment” you agree to the terms of
                  service of the company
                </p>
              </div>

              <div className="w-full mt-4">
                <Button
                  onClick={onRequestSub}
                  size="large"
                  style={{ backgroundColor: Colors.primary, color: "white" }}
                  className="w-full"
                >
                  {loading ? (
                    <span className="w-5 h-5 border-2 border-solid border-white rounded-full animate-spin border-t-transparent"></span>
                  ) : (
                    "Proceed with payment"
                  )}
                </Button>
                <Button
                  onClick={() => props.setScreen(0)}
                  size="large"
                  className="w-full mt-4"
                >
                  Exit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
