import React, { useCallback, useEffect, useState } from "react";
import ApptCalendar from "../../components/cards/appt-calendar";
import DataProvider from "../../services";
import apdc from "../../assets/images/rd.png";
import { Button } from "antd";
import Colors from "../../constants/Colors";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import CardLoader from "../../components/elements/card-loader";
import AppointmentCard from "../../components/cards/appointment-card";
import Empty from "../../components/elements/empty";
import moment from "moment";
import img from "../../assets/images/poweredbymtn.png";

export default function Appointments() {
  const navigate = useNavigate();
  const [date, setDate] = useState<Date>(new Date());
  const [filterDate, setFilterDate] = useState<Date>(new Date());
  const [data, setData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  // const [totalPages, setTotalPages] = useState<number>(1);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState<number>(200);

  const getAllAppt = useCallback(
    async (params?: Date) => {
      setLoading(true);
      try {
        const results = await DataProvider.getAllAppointment(
          currentPage,
          pageSize
        );
        const filter = results.data.appointments.filter(
          (item: any) => item.date === moment(params).format("DD-MM-YYYY")
        );
        setData(filter);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    },
    [pageSize, currentPage]
  );

  useEffect(() => {
    if (date) {
      getAllAppt(date);
    }
  }, [date, getAllAppt]);

  return (
    <div className="md:p-4 p-2 ">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 justify-center py-4 mb-4">
        <div className="bg-[#06509E] w-full grid grid-cols-2 gap-0 justify-center rounded-lg shadow">
          <img src={apdc} alt="care" className="rounded-l-lg w-[100%] h-full" />
          <div className="text-white lg:pt-20 md:pt20 pt-10 lg:pl-3 md:pl-4 pl-3 items-center justify-center">
            <h4 className="md:text-xl text-sm font-semibold mb-2">
              Appointment | E-Consultation
            </h4>
            <p className="font-semibold md:text-sm text-xs">
              Book an econsultation with a Specialist or a General Practitioner.
            </p>
            <div className="md:hidden block my-4">
              <Button
                size="small"
                className="text-xs"
                onClick={() => navigate("/main/appointments/booking")}
                style={{ backgroundColor: Colors.white }}
              >
                Book Appointment
              </Button>
            </div>
            <div className="my-4 md:block hidden">
              <Button
                onClick={() => navigate("/main/appointments/booking")}
                style={{ backgroundColor: Colors.white }}
              >
                Book Appointment
              </Button>
            </div>
          </div>
        </div>
        <ApptCalendar
          setDate={setDate}
          date={date}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      </div>
      <div className="w-full my-4">
        <div className="bg-white border border-solid border-gray-200 rounded-lg min-h-[65vh] w-full flex flex-col justify-start p-2">
          <div className="w-full flex justify-between items-center py-2 px-2">
            <h4>Appointments By Date</h4>
            <Link
              to="/main/appointments/all"
              className="flex text-sm items-center text-[#06509E]"
            >
              <h4>View all</h4> <FaChevronRight className="ml-1" />
            </Link>
          </div>
          <div className="w-full">
            {loading ? (
              <div className="flex md:justify-start justify-center flex-wrap">
                {[1, 2, 3, 4, 5].map((v, idx) => (
                  <CardLoader key={idx} />
                ))}
              </div>
            ) : !loading && data && data.length > 0 ? (
              <div>
                <div className="flex md:justify-start justify-center flex-wrap">
                  {data.reverse().map((v: any, idx: any) => (
                    <AppointmentCard data={v} key={idx} />
                  ))}
                </div>
              </div>
            ) : (
              <div className="">
                <Empty
                  imageStyle="md:h-44 h-24"
                  titleStyle="md:text-lg text-sm"
                  desStyle="md:text-base"
                  title="Empty"
                  buttons={[
                    {
                      name: "Refresh",
                      event: () => getAllAppt(date),
                    },
                  ]}
                  description={`No appointments available on ${moment(
                    date
                  ).format("Do MMMM YYYY")}`}
                />
              </div>
            )}
          </div>
          <div className="flex justify-center">
            <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}
